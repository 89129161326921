import React from 'react';
import PropTypes from 'prop-types';
import { BananaMultiswitcher } from 'banana-framework';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';

const switcherContent = [
  { value: '1YearAgo', text: <Translate value="allRentals.oneYearAgo" /> },
  { value: 'all', text: <Translate value="allRentals.all" /> },
  { value: 'today', text: `${moment().calendar().split(' ')[0]}` },
  { value: 'yesterday', text: `${moment().subtract(1, 'days').calendar().split(' ')[0]}` },
  {
    value: '2DaysAgo',
    text: `${moment().subtract(2, 'days').calendar().split(' ')[1]} ${moment().subtract(2, 'days').format('DD.MM')}`,
  },
  {
    value: '3DaysAgo',
    text: `${moment().subtract(3, 'days').calendar().split(' ')[1]} ${moment().subtract(3, 'days').format('DD.MM')}`,
  },
  {
    value: '4DaysAgo',
    text: `${moment().subtract(4, 'days').calendar().split(' ')[1]} ${moment().subtract(4, 'days').format('DD.MM')}`,
  },
  {
    value: '5DaysAgo',
    text: `${moment().subtract(5, 'days').calendar().split(' ')[1]} ${moment().subtract(5, 'days').format('DD.MM')}`,
  },
  {
    value: '6DaysAgo',
    text: `${moment().subtract(6, 'days').calendar().split(' ')[1]} ${moment().subtract(6, 'days').format('DD.MM')}`,
  },
  { value: 'thisWeek', text: <Translate value="allRentals.thisWeek" /> },
  { value: 'lastWeek', text: <Translate value="allRentals.lastWeek" /> },
  { value: 'thisMonth', text: <Translate value="allRentals.thisMonth" /> },
  { value: 'lastMonth', text: <Translate value="allRentals.lastMonth" /> },
];

export default function DateSwitcher(props) {
  return (
    <BananaMultiswitcher
      selectedValue={props.dateRange}
      onChange={props.changeDateRange}
      title={props.title}
      name="allRentalsDateRange"
      switcherContent={switcherContent}
    />
  );
}

DateSwitcher.propTypes = {
  changeDateRange: PropTypes.func.isRequired,
  dateRange: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
};
