import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GoodsOrderForm from 'components/presentational/goods-order/goods-order-form';
import * as goodsOrderActions from './goods-order-actions';

class NewGoodsOrder extends Component {
  componentDidMount() {
    this.props.actions.sharedResetAppState();
    this.props.actions.getNewGoodsOrder();
  }

  render() {
    return (
      <div>
        <GoodsOrderForm
          editMode={false}
          partners={this.props.partners}
          statuses={this.props.statuses}
          units={this.props.units}
          partnerID={this.props.partnerID}
          partnerOrderID={this.props.partnerOrderID}
          deliveryDateTime={this.props.deliveryDateTime}
          orderDate={this.props.orderDate}
          status={this.props.status}
          quantity={this.props.quantity}
          chosenUnit={this.props.chosenUnit}
          updateGoodsOrderPartner={this.props.actions.updateGoodsOrderPartner}
          updateGoodsOrderPartnerOrderID={this.props.actions.updateGoodsOrderPartnerOrderID}
          updateGoodsOrderDeliveryDateTime={this.props.actions.updateGoodsOrderDeliveryDateTime}
          updateGoodsOrderDate={this.props.actions.updateGoodsOrderDate}
          updateGoodsOrderStatus={this.props.actions.updateGoodsOrderStatus}
          updateGoodsOrderQuantity={this.props.actions.updateGoodsOrderQuantity}
          updateGoodsOrderUnit={this.props.actions.updateGoodsOrderUnit}
          saveGoodsOrder={this.props.actions.saveNewGoodsOrder}
        />
      </div>
    );
  }
}

NewGoodsOrder.propTypes = {
  partners: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  partnerID: PropTypes.number,
  partnerOrderID: PropTypes.string,
  deliveryDateTime: PropTypes.object,
  orderDate: PropTypes.object,
  status: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chosenUnit: PropTypes.number,
  actions: PropTypes.object.isRequired,
};

NewGoodsOrder.defaultProps = {
  partnerID: null,
  partnerOrderID: null,
  deliveryDateTime: null,
  orderDate: null,
  chosenUnit: null,
  quantity: null,
};

function mapStateToProps({ goodsOrderData }) {
  return {
    partners: goodsOrderData.partners,
    statuses: goodsOrderData.statuses,
    units: goodsOrderData.units,
    partnerID: goodsOrderData.partnerID,
    partnerOrderID: goodsOrderData.partnerOrderID,
    deliveryDateTime: goodsOrderData.deliveryDateTime,
    orderDate: goodsOrderData.orderDate,
    status: goodsOrderData.status,
    quantity: goodsOrderData.quantity,
    chosenUnit: goodsOrderData.chosenUnit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(goodsOrderActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewGoodsOrder);
