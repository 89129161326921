import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BananaInput from './banana-input';

export default class BananaInputButton extends Component {
  constructor(props) {
    super(props);
    if (this.props.initialValue) {
      this.state = {
        value: this.props.value,
      };
    } else {
      this.state = {
        value: '',
      };
    }
    this.changeValue = this.changeValue.bind(this);
    this.saveFunc = this.saveFunc.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.error && !!nextProps.error && this.props.clearOnError) {
      this.setState(() => ({ value: '' }));
    }
    if (nextProps.successfulResponse) {
      this.setState({ value: '' });
    } else if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  changeValue(value) {
    this.setState({ value });
  }

  saveFunc() {
    this.props.saveFunc(this.state.value);
  }

  keyPressHandler(evt) {
    if (evt.key === 'Enter') {
      this.props.saveFunc(this.state.value);
    }
  }

  renderError() {
    if (this.props.error) {
      return <div className="banana-input-button__error">{this.props.error}</div>;
    }
    return null;
  }

  render() {
    return (
      <div className="banana-input-button">
        <div role="button" aria-hidden className="banana-input-button__input" onKeyDown={this.keyPressHandler}>
          <BananaInput
            value={this.state.value}
            text={this.props.text}
            onChangeAction={this.changeValue}
            setFocus={this.props.setFocus}
            onBlur={this.props.onBlur}
            filter={this.props.filter}
            initialValue
            onRefAdd={this.props.onRefAdd}
          />
        </div>
        <button onClick={this.saveFunc} className="banana-input-button__button">
          {this.props.saveButtonText}
        </button>
        {this.renderError()}
      </div>
    );
  }
}

BananaInputButton.propTypes = {
  saveFunc: PropTypes.func.isRequired,
  saveButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFocus: PropTypes.bool,
  text: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValue: PropTypes.bool,
  filter: PropTypes.string,
  clearOnError: PropTypes.bool,
  onRefAdd: PropTypes.func,
};

BananaInputButton.defaultProps = {
  text: null,
  saveButtonText: 'Save',
  error: null,
  clearOnError: false,
  initialValue: false,
  setFocus: false,
  onBlur: () => {},
  value: '',
  filter: null,
  onRefAdd: () => {},
};
