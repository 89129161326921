/* eslint jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

export default class BananaCheckbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (!this.props.checked) {
      this.props.checkedFunc(this.props.value);
    } else {
      this.props.uncheckedFunc(this.props.value);
    }
  }

  renderCheckMark() {
    if (this.props.checked) {
      return <i className="fa fa-check-square-o banana-checkbox__mark" aria-hidden="true" />;
    }
    return <i className="fa fa-square-o banana-checkbox__mark" aria-hidden="true" />;
  }

  render() {
    if (this.props.toggleSwitch) {
      return (
        <label className="banana-checkbox-switch" htmlFor="normal-switch">
          <span>{this.props.value}</span>
          <Switch
            onChange={this.onChange}
            checked={this.props.checked}
            id="normal-switch"
            onHandleColor="#8BC34A"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={30}
          />
        </label>
      );
    }
    return (
      <label className="banana-checkbox">
        {this.renderCheckMark()}
        <input checked={this.props.checked} onChange={this.onChange} disabled={this.props.disabled} type="checkbox" />
        <span>{this.props.value}</span>
      </label>
    );
  }
}

BananaCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  checkedFunc: PropTypes.func.isRequired,
  uncheckedFunc: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  toggleSwitch: PropTypes.bool,
};

BananaCheckbox.defaultProps = {
  value: '',
  disabled: false,
  toggleSwitch: false,
};
