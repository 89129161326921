import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

export default function NewRecordsInvoices({ generateValoraInvoices, paidBool }) {
  return (
    <div className="block block--padded">
      <h2 className="title title--medium">
        <Translate value="invoices.generateNewRecords" />
        <div>
          <button
            className="invoice__generate-button"
            onClick={() => {
              generateValoraInvoices(paidBool);
            }}
          >
            <Translate value="invoice.generateInvoicesAndReports" />
          </button>
        </div>
      </h2>
    </div>
  );
}

NewRecordsInvoices.propTypes = {
  generateValoraInvoices: PropTypes.func.isRequired,
  paidBool: PropTypes.bool,
};

NewRecordsInvoices.defaultProps = {
  paidBool: null,
};
