import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { Connect } from 'redux-auto-actions';

import { ShipmentDetails, BoxesManipulator } from 'components/presentational/shipment-box';
import { BoxDetails } from 'components/presentational/shipment-box/components/box-details/box-details';
import { ToolTip } from 'components/presentational/shared/tool-tip';
import { APP_SHORTCUTS } from 'constants/shortcuts';
import { Shortcuts, useShortcuts } from 'helpers/shortcuts-util';
import { BananaTipButton, BananaInputButton } from 'banana-framework';

import { actions as shipmentActions, selectors as shipmentSelectors } from './shipment-actions';
import { AssignmentBoxType, ShipmentType } from './shipment-data';
import { GlobalState } from 'app-redux/reducers/initial-state';
import { Utils } from 'components/presentational/shipment-box/components/utils';
import { TabFocus } from 'banana-framework-v2';
import { usePrevious } from 'helpers/hooks/use-previous';

type EditShipmentParams = { id: string };

interface Props {}

class PrevValue<T> {
  private value: T;
  constructor(newVal: T) {
    this.value = newVal;
  }
  public set = (val: T) => {
    this.value = val;
  };
  public get = () => {
    return this.value;
  };
}

export const EditShipment = Connect<GlobalState, Props>()
  .stateAndDispatch(
    (state) => ({
      shipment: shipmentSelectors(state) as ShipmentType,
    }),
    {
      // shipment actions
      getShipment: shipmentActions.getShipment,
      updateLocation: shipmentActions.updateLocation,
      getSingleBoxInfo: shipmentActions.getSingleBoxInfo,
      updateShipmentDate: shipmentActions.updateShipmentDate,
      removeSingleBoxInfo: shipmentActions.removeSingleBoxInfo,
      sharedResetAppState: shipmentActions.sharedResetAppState,
      updateExpirationDate: shipmentActions.updateExpirationDate,
      getAvailableLocations: shipmentActions.getAvailableLocations,
      removeAllShipmentBoxes: shipmentActions.removeAllShipmentBoxes,
      requestAvailableLocations: shipmentActions.requestAvailableLocations,
      // box actions
      deleteBox: shipmentActions.deleteBox,
      updateBoxId: shipmentActions.updateBoxId,
      addNewBattere: shipmentActions.addNewBattere,
      deleteBattery: shipmentActions.deleteBattery,
      updateCablesData: shipmentActions.updateCablesData,
      updateBoxExpirationDate: shipmentActions.updateBoxExpirationDate,
      updateLogistaBoxIdentifier: shipmentActions.updateLogistaBoxIdentifier,
      sharedToggleDialogWindow: shipmentActions.sharedToggleDialogWindow,
      // return type actions on shipment level
      updateLogisticsSlipNrBoxes: shipmentActions.updateLogisticsSlipNrBoxes,
      updateLogisticsSlipCode: shipmentActions.updateLogisticsSlipCode,
      // return type actions on box level
      createNewBox: shipmentActions.createNewBox,
      setExternalBoxCode: shipmentActions.setExternalBoxCode,
      setBeforeHQShipmentDetailId: shipmentActions.setBeforeHQShipmentDetailId,
      // CSV
      getCSVEntity: shipmentActions.getCSVEntity,
    }
  )
  .withComp(
    ({
      shipment,
      sharedResetAppState,
      removeAllShipmentBoxes,
      getShipment,
      createNewBox,
      getSingleBoxInfo,
      updateLogisticsSlipNrBoxes,
      updateLogisticsSlipCode,
      getCSVEntity,
      updateShipmentDate,
      updateExpirationDate,
      getAvailableLocations,
      updateLocation,
      removeSingleBoxInfo,
      setBeforeHQShipmentDetailId,
      setExternalBoxCode,
      updateBoxId,
      updateBoxExpirationDate,
      updateLogistaBoxIdentifier,
      updateCablesData,
      addNewBattere,
      sharedToggleDialogWindow,
      deleteBattery,
      deleteBox,
      requestAvailableLocations,
    }) => {
      const history = useHistory();
      const { id } = useParams<EditShipmentParams>();
      const [shipmentId, setShipmentId] = useState(parseInt(id, 10));

      useEffect(() => {
        if (id) {
          sharedResetAppState();
          setShipmentId(parseInt(id, 10));
        }
      }, [id]);

      const [createNewLocalBox, setCreateNewLocalBox] = useState(false);
      const [focusedBoxId, setFocusedBoxId] = useState<number | null>(null);
      const [newBoxError, setNewBoxError] = useState<string>();
      const [newBoxResponse, setNewBoxResponse] = useState(false);
      const [assignedBoxes, setAssignedBoxes] = useState<AssignmentBoxType[]>([]);

      // TODO: Do we still need this?
      const resetFocus = () => {
        // console.log('Will reset focus');
      };

      const openNewBoxBlock = () => {
        removeAllShipmentBoxes();
        setCreateNewLocalBox(true);
        setIsNewBox(true);
        window.scrollTo(0, document.body.scrollHeight);
      };

      const closeNewBoxView = () => {
        setNewBoxError('');
        setNewBoxResponse(false);
        setCreateNewLocalBox(false);
        setIsNewBox(false);
      };

      /**
       * Shortcuts
       */
      const boxLevelUIShortcuts = APP_SHORTCUTS.boxLevelUI({
        toggleNewBox: createNewLocalBox ? closeNewBoxView : openNewBoxBlock,
      });
      useShortcuts(Shortcuts.create(boxLevelUIShortcuts), [createNewLocalBox]);

      // on mount
      useEffect(() => {
        // nothing to do on mount

        // on unmount
        return () => {
          removeAllShipmentBoxes();
        };
      }, []);

      useEffect(() => {
        if (shipment.assignedBoxes) setAssignedBoxes([...shipment.assignedBoxes]);
        else setAssignedBoxes([]);
      }, [shipment]);

      const getShipmentHandler = async () => {
        try {
          await getShipment(shipmentId);
        } catch (err) {
          setTimeout(() => history.replace(`/shipments`));
        }
      };
      useEffect(() => {
        if (shipmentId) {
          sharedResetAppState();
          getShipmentHandler();
        }
      }, [shipmentId]);

      const createNewBoxFunc = async (newBoxId: number) => {
        const res = await createNewBox(shipmentId, newBoxId, 'return');
        const response = res as unknown as { error: string; data: { errors: string } };
        if (response.error) setNewBoxError(response.data.errors);
        else if (!response.error) closeNewBoxView();
      };

      const boxWithoutBarcode = () => {
        createNewBox(shipmentId, null, 'return');
        closeNewBoxView();
      };

      const getSingleBoxInfoHandler = (boxId: number) => {
        getSingleBoxInfo(boxId, shipmentId);
        // ON BOX CHECK TOGGLE true
        setFocusedBoxId(boxId);
      };

      const removeSingleBoxInfoHandler = (boxId: number) => {
        removeSingleBoxInfo(boxId);

        if (focusedBoxId === boxId) setFocusedBoxId(null);
      };

      const deleteBoxHandler = (boxId: number, shipmentId: number, externalId: number): Promise<boolean> => {
        setFocusedBoxId(null);
        return deleteBox(boxId, shipmentId, externalId);
      };

      /**
       * FOCUS MANAGEMENT START
       */
      // state
      const [isNewBox, setIsNewBox] = useState(false);
      const prevAssignedBoxes = usePrevious(shipment.assignedBoxes);
      const prevIsNew = React.useRef<PrevValue<boolean>>(new PrevValue(isNewBox));

      // get box from focusedBoxId and add either logista or box input fields
      const getFocusName = (_boxId: number): string => {
        if (Utils.isLogista(shipment.shipmentSummary)) {
          // check if current box ID has box.logista_box_identifier with content
          const box = shipment.boxes.find((b) => b.box_external_id === _boxId);
          if (box) {
            // an existing box was selected
            if (box.logista_box_identifier === null || box.logista_box_identifier.length === 0) {
              return `addLogista_${focusedBoxId}`;
            }
          }
        }
        return `addBox_${focusedBoxId}`;
      };

      useEffect(() => {
        // Focus the desired input.
        if (focusedBoxId) {
          setTimeout(() => {
            TabFocus.getInstance().focus(getFocusName(focusedBoxId));
            prevIsNew.current.set(false);
          }, 500);
        } else if (isNewBox || (focusedBoxId === null && isNewBox === false)) {
          // isNewBox might not be true right after shortcut pressed
          setTimeout(() => {
            TabFocus.getInstance().focus('addBox');
            prevIsNew.current.set(true);
          }, 225);
        } else {
          setTimeout(() => {
            TabFocus.getInstance().focus('filterBoxes');
          }, 100);
        }
      }, [shipment.boxes, focusedBoxId]);

      useEffect(() => {
        // check the diff between prevAssignedBoxes and assignedBoxes
        // it should be only one
        let currentActiveBoxes = shipment.assignedBoxes.filter((b) => b.checked);
        // no current active boxes, so it might be null
        if (currentActiveBoxes.length === 0) setFocusedBoxId(null);
        else {
          // check if currentActive is different than prevActive
          let prevActiveBoxes = prevAssignedBoxes?.filter((b) => b.checked) || [];
          if (prevActiveBoxes.length !== currentActiveBoxes.length) {
            // new box activated or deactivated
            setFocusedBoxId(currentActiveBoxes[currentActiveBoxes.length - 1].id);
          }
        }
      }, [shipment.assignedBoxes]);
      /**
       * FOCUS MANAGEMENT END
       */

      const setLogisticsSlipNrBoxes = (nrBoxesLogisticsSlip: string) =>
        updateLogisticsSlipNrBoxes(shipmentId, Number.parseInt(nrBoxesLogisticsSlip, 10));
      const setLogisticsSlipCode = (logisticsSlipCode: string) => updateLogisticsSlipCode(shipmentId, logisticsSlipCode);
      const getBoxesCSVHandler = () => getCSVEntity(shipmentId, 'Box');
      const getBatteriesCSVHandler = () => getCSVEntity(shipmentId, 'Battery');

      if (shipmentId && shipment)
        return (
          <div>
            <ShipmentDetails
              id={shipmentId}
              shipment={shipment}
              availableLocationsTo={shipment.availableLocationsTo}
              availableLocationsFrom={shipment.availableLocationsFrom}
              boxesNum={shipment.boxes.length}
              shipmentType={shipment.shipmentType}
              updateShipmentDate={updateShipmentDate}
              updateExpirationDate={updateExpirationDate}
              getAvailableLocations={getAvailableLocations}
              updateLocationFunction={updateLocation}
              setLogisticsSlipNrBoxes={setLogisticsSlipNrBoxes}
              setLogisticsSlipCode={setLogisticsSlipCode}
              getBoxesCSV={getBoxesCSVHandler}
              getBatteriesCSV={getBatteriesCSVHandler}
            />
            {!!assignedBoxes.length && (
              <BoxesManipulator
                openBoxFunc={getSingleBoxInfoHandler}
                closeBoxFunc={removeSingleBoxInfoHandler}
                assignedBoxes={assignedBoxes}
                activeBoxes={shipment.boxes}
              />
            )}
            {shipment.boxes.map((box) => {
              const setBeforeHQShipmentDetailIdHandler = (newLocationId: string) =>
                setBeforeHQShipmentDetailId(box, newLocationId);
              const setExternalBoxCodeHandler = (newVal: string) => setExternalBoxCode(box, shipmentId, newVal);
              return (
                <BoxDetails
                  key={box.box_external_id}
                  box={box}
                  updateBoxId={updateBoxId}
                  shipment={shipment}
                  updateBoxExpirationDate={updateBoxExpirationDate}
                  updateLogistaBoxIdentifier={updateLogistaBoxIdentifier}
                  updateCableDataRequest={updateCablesData}
                  addNewBattereRequest={addNewBattere}
                  toggleDialogWindow={sharedToggleDialogWindow}
                  deleteBatteryRequest={deleteBattery}
                  deleteBoxRequest={deleteBoxHandler}
                  resetFocus={resetFocus}
                  setBeforeHQShipmentDetailId={setBeforeHQShipmentDetailIdHandler}
                  setExternalBoxCode={setExternalBoxCodeHandler}
                  getAvailableLocations={requestAvailableLocations}
                />
              );
            })}
            {createNewLocalBox && (
              <div className="box-details">
                <div className="box-details__title">
                  <Translate value="newBox.addNewBox" />
                </div>
                <div className="box-batteries__input">
                  <BananaInputButton
                    error={newBoxError}
                    onBlur={resetFocus}
                    saveFunc={createNewBoxFunc}
                    successfulResponse={newBoxResponse}
                    onRefAdd={(ref: HTMLInputElement) => {
                      TabFocus.getInstance().add('addBox', ref);
                      return () => {
                        TabFocus.getInstance().remove('addBox');
                      };
                    }}
                  />
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  onClick={boxWithoutBarcode}
                >
                  This box doesn&apos;t have a Chimpy barcode
                </a>
              </div>
            )}
            <ToolTip active placement="top" text={I18n.t('shortcuts.newBox')} style={{ position: 'absolute', right: 150 }}>
              <BananaTipButton firstAction={openNewBoxBlock} />
            </ToolTip>
          </div>
        );
      return <div>loading...</div>;
    }
  );
