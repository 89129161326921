import React from 'react';
import PropTypes from 'prop-types';
import { BananaInput, BananaButton } from 'banana-framework';
import { I18n } from 'react-redux-i18n';

import '../styles/login-content.scss';

import logo from '../../../../assets/logo.png';

const LoginContent = (props) => {
  const signIn = () => {
    props.loginActionSignIn(props.email, props.password);
  };

  return (
    <div className="login-content">
      <img src={logo} alt="logo" className="login-content__image" />
      <div>
        <BananaInput
          text={I18n.t('loginContainer.email')}
          inputClass="login-content__input"
          textClass="login-content__input-text"
          onChangeAction={props.loginActionChangeEmail}
          value={props.email}
          textEnabledByDefault
          onSubmitEditing={signIn}
        />
      </div>
      <div className="login-content__input-box">
        <BananaInput
          text={I18n.t('loginContainer.password')}
          inputClass="login-content__input"
          textClass="login-content__input-text"
          type="password"
          onChangeAction={props.loginActionChangePassword}
          value={props.password}
          textEnabledByDefault
          onSubmitEditing={signIn}
        />
      </div>
      <BananaButton buttonClass="login-content__button" onClick={signIn}>
        {I18n.t('loginContainer.signIn')}
      </BananaButton>
    </div>
  );
};

LoginContent.propTypes = {
  loginActionChangeEmail: PropTypes.func.isRequired,
  loginActionChangePassword: PropTypes.func.isRequired,
  loginActionSignIn: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default LoginContent;
