import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

export default function ShipmentTableHeader({ setSortingType, currentSorting }) {
  return (
    <div className="table__header">
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.id')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('id', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.to')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('to_location', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.from')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('from_location', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.shippingDate')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('shipping_date', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.boxes')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('boxes', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.batteries')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('batteries', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.cables')}</div>
          <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('cables', currentSorting);
            }}
          />
        </div>
      </div>
      <div className="table__header-element">{I18n.t('bananaTableHeader.actions')}</div>
    </div>
  );
}

ShipmentTableHeader.propTypes = {
  setSortingType: PropTypes.func.isRequired,
  currentSorting: PropTypes.string.isRequired,
};
