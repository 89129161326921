import standardRequest from 'helpers/backend-requests';
import { I18n } from 'react-redux-i18n';

import {
  setErrorMessage,
  resetAppState,
  toggleLoading,
  toggleDialogWindow,
  renderNotification,
} from 'app-redux/actions/app-actions';

export const sharedResetAppState = resetAppState;
export const sharedToggleDialogWindow = toggleDialogWindow;

function resetOrderOverviewState() {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_RESET_STATE',
  };
}

function resetCheckedShipments() {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_RESET_CHECKED_SHIPMENTS',
  };
}

function saveAllGoodsOrders(mockData) {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_SAVE_ORDERS',
    orders: mockData.orders,
  };
}

function saveShipments(mockData) {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_SAVE_SHIPMENTS',
    shipments: mockData.shipments,
  };
}

function saveSelectedOrder(orderId) {
  return (dispatch) => {
    dispatch({
      type: 'GOODS_ORDERS_OVERVIEW_SAVE_SELECTED_ORDER',
      selectedOrder: orderId,
    });
    dispatch(resetCheckedShipments());
  };
}

function saveExecutedOrdersEnabled(includeExecutedOrders) {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_SAVE_EXECUTED_ORDERS_ENABLED',
    includeExecutedOrders,
  };
}

export function getAllGoodsOrders({ includeExecutedOrders = false } = {}) {
  const pathParam = includeExecutedOrders ? '' : 'not_executed';
  const path = `/goods_orders/${pathParam}`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveAllGoodsOrders(response));
          dispatch(saveExecutedOrdersEnabled(includeExecutedOrders));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getShipmentsAssignedToOrder(orderID) {
  const path = `/goods_orders/${orderID}/assigned_shipments`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveShipments(response));
          dispatch(saveSelectedOrder(orderID));
          dispatch(resetCheckedShipments());
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
export function deleteOrder(id) {
  const path = `/goods_orders/${id}`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'DELETE')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch({
            type: 'GOODS_ORDERS_OVERVIEW_DELETE_ORDER',
            id,
          });
        }
        dispatch(toggleDialogWindow({ isVisibleDialogWindow: false }));
        dispatch(resetCheckedShipments());
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function checkShipmentForConsolidation(id) {
  const path = `/shipment_details/${id}/check_for_consolidation`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error === 422) {
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              dialogTitle: 'This shipment is not ready for consolidation.',
              customContent: response.data.errors,
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch({
            type: 'GOODS_ORDERS_OVERVIEW_ADD_CHECKED_SHIPMENT',
            id,
          });
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function uncheckConsolidatedShipment(id) {
  return {
    type: 'GOODS_ORDERS_OVERVIEW_REMOVE_CHECKED_SHIPMENT',
    id,
  };
}

export function setOrderStatus(orderId, status) {
  const path = `/goods_orders/${orderId}`;
  const body = {
    status,
  };
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'PATCH', {}, body)
      .then((response) => {
        if (response.error === 422) {
          var message = 'Something went wrong.'
          if (response.data && response.data.errors) {
            message = response.data.errors.join(' & ');
          }
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              dialogTitle: message,
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(renderNotification('Order status was successfully updated'));
          if (status === 3) {
            dispatch(resetOrderOverviewState());
          }
          dispatch(getAllGoodsOrders());
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}