import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function ShipmentTableHeader() {
  return (
    <div className="table__header">
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.id')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('id', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.partner')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('partner', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.orderId')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('partner_order_id', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.deliveryDateTime')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('delivery_datetime', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.status')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('status', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.quantity')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('quantity', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element table__header-element--center">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.progress')}</div>
          {/* <i
            className="fa fa-caret-down table__header-filter"
            aria-hidden="true"
            onClick={() => {
              setSortingType('progress', currentSorting);
            }}
          /> */}
        </div>
      </div>
      <div className="table__header-element">{I18n.t('bananaTableHeader.actions')}</div>
    </div>
  );
}
