import AllRentalsTotalComponent from './components/all-rentals-total';
import AllRentalsFilterComponent from './components/all-rentals-filter';
import AllRentalsContentComponent from './components/all-rentals-content';
import AllRentalsListComponent from './components/all-rentals-list/all-rentals-list';

import './styles/all-rentals-content.scss';
import './styles/date-switcher.scss';
import './styles/all-rentals-checkbox.scss';
import './styles/all-rentals-rental.scss';

export const AllRentalsTotal = AllRentalsTotalComponent;
export const AllRentalsFilter = AllRentalsFilterComponent;
export const AllRentalsContent = AllRentalsContentComponent;
export const AllRentalsList = AllRentalsListComponent;
