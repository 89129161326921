import React from 'react';
import PropTypes from 'prop-types';
import { BananaInput } from 'banana-framework';

export default function CableElement(props) {
  return (
    <div className="box-details__cable__row">
      <div className="box-details__cable__cell box-details__cable__cell--title">{props.title}</div>
      <div className="box-details__cable__cell">
        <BananaInput
          value={props.children}
          updatedOuside={!props.isActive}
          disabled={!props.isActive}
          filter="numeric"
          onChangeAction={props.onChangeAction}
          initialValue
        />
      </div>
    </div>
  );
}

CableElement.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  children: PropTypes.number,
};

CableElement.defaultProps = {
  children: null,
};
