import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShipmentTableBody from './body-partials/shipment-table-body';
import ShipmentTableHeader from './header-partials/shipment-table-header';

import ShipmentCompactTableBody from './body-partials/shipment-compact-table-body';
import { ShipmentCompactTableHeader } from './header-partials/shipment-compact-table-header';

import GoodsOrderTableBody from './body-partials/goods-order-table-body';
import GoodsOrderTableHeader from './header-partials/goods-order-table-header';

export default class BananaTable extends Component {
  returnTableClass() {
    switch (this.props.type) {
      case 'shipment':
        return 'table table--with-header';
      case 'shipment-compact':
        return 'table table--with-header table--compact';
      default:
        return 'table';
    }
  }

  returnTableBody() {
    switch (this.props.type) {
      case 'shipment':
        return <ShipmentTableBody data={this.props.data} showDeletePopup={this.props.showDeletePopup} />;

      case 'shipment-compact':
        return (
          <ShipmentCompactTableBody
            data={this.props.data}
            showDeletePopup={this.props.showDeletePopup}
            shipmentChecked={this.props.shipmentChecked}
            shipmentUnchecked={this.props.shipmentUnchecked}
            checkedShipments={this.props.checkedShipments}
            isOrderConsolidated={this.props.isOrderConsolidated}
          />
        );

      case 'order':
        return (
          <GoodsOrderTableBody
            data={this.props.data}
            showDeletePopup={this.props.showDeletePopup}
            viewAction={this.props.viewAction}
          />
        );
      default:
        return null;
    }
  }

  returnTableHeader() {
    switch (this.props.type) {
      case 'shipment':
        return <ShipmentTableHeader setSortingType={this.props.setSortingType} currentSorting={this.props.currentSortingType} />;
      case 'shipment-compact':
        return <ShipmentCompactTableHeader isOrderConsolidated={this.props.isOrderConsolidated} />;
      case 'order':
        return (
          <GoodsOrderTableHeader setSortingType={this.props.setSortingType} currentSorting={this.props.currentSortingType} />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={this.returnTableClass()}>
        {this.returnTableHeader()}
        {this.returnTableBody()}
      </div>
    );
  }
}

BananaTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string.isRequired,
  currentSortingType: PropTypes.string.isRequired,
  setSortingType: PropTypes.func.isRequired,
  showDeletePopup: PropTypes.func.isRequired,
  viewAction: PropTypes.func,
  shipmentChecked: PropTypes.func,
  shipmentUnchecked: PropTypes.func,
  checkedShipments: PropTypes.object,
  isOrderConsolidated: PropTypes.bool,
};

BananaTable.defaultProps = {
  data: [],
  viewAction: () => {},
  shipmentChecked: () => {},
  shipmentUnchecked: () => {},
  checkedShipments: {},
  isOrderConsolidated: false,
};
