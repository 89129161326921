export function compareObjects(sortingParam) {
  return function compareObjectsInside(a, b) {
    if (a[sortingParam] < b[sortingParam]) {
      return -1;
    }
    if (a[sortingParam] > b[sortingParam]) {
      return 1;
    }
    return 0;
  };
}

export function objectifyDate(jsonDate) {
  const date = new Date(jsonDate);

  return {
    longStr: date.toString(),
    mmddyy: date.toLocaleString('de-CH', { month: '2-digit', day: '2-digit', year: '2-digit' }).split(',')[0],
    weekDay: date.toLocaleString('de-CH', { weekday: 'long' }),
    month: date.toLocaleString('de-CH', { month: 'long' }),
    monthDay: date.getDate(),
    time: date.toLocaleTimeString('de-CH', { hour: '2-digit' }),
    dateTime: date.toLocaleString('de-CH', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }),
  };
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const Utility = {
  capitalize,
};
