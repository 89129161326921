import React from 'react';
import PropTypes from 'prop-types';

export default function BananaButton(props) {
  return (
    <button className={`banana-button ${props.buttonClass}`} disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </button>
  );
}

BananaButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string,
};

BananaButton.defaultProps = {
  children: 'Save',
  disabled: false,
  buttonClass: '',
};
