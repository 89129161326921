/* eslint-disable camelcase */
import standardRequest from 'helpers/backend-requests';
import {
  toggleDialogWindow,
  setErrorMessage,
  renderNotification,
  resetAppState,
  createShipment,
  toggleLoading,
} from 'app-redux/actions/app-actions';
import { I18n } from 'react-redux-i18n';

function saveShipments(shipments) {
  return {
    type: 'SHIPMENTS_ACTION_SAVE_SHIPMENTS',
    shipments,
  };
}

export const sharedToggleDialogWindow = toggleDialogWindow;
export const sharedResetAppState = resetAppState;
export const sharedCreateShipment = createShipment;

/**
 * Add a URL parameter (or changing it if it already exists)
 * @param {key}    string  the key to set
 * @param {val}    string  value
 * @param {urlstring} string this is typically the url (defaults to false)
 */
const addUrlParam = (key, val, urlstring = false) =>
  `${urlstring || ''}${!urlstring || (urlstring && urlstring.match(/[?]/g)) ? '&' : '?'}${key}=${val}`;

export const getAllShipments = (requestData) => (dispatch) => {
  let path;
  if (requestData === undefined) {
    path = '/shipment_details';
  } else if (requestData.to_date && requestData.from_date) {
    path = '/shipment_details/';
    const {
      page,
      sorting,
      location_pattern,
      shipment_type,
      from_date,
      to_date,
      box_external_id,
      battery_external_id,
    } = requestData;
    path = addUrlParam('page', page, path);
    path += addUrlParam('sorting', sorting);
    path += addUrlParam('location_pattern', location_pattern);
    path += addUrlParam('shipment_type', shipment_type);
    path += addUrlParam('from_date', from_date.format('YYYY-MM-DD'));
    path += addUrlParam('to_date', to_date.format('YYYY-MM-DD'));
    path += addUrlParam('box_external_id', box_external_id);
    path += addUrlParam('battery_external_id', battery_external_id);
  } else {
    return null;
  }
  dispatch(toggleLoading(true));
  return standardRequest(path, 'GET')
    .then((data) => {
      if (data.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${data.error}`)));
      } else {
        dispatch(saveShipments(data));
      }
    })
    .finally(() => {
      dispatch(toggleLoading(false));
    });
};

export function getAllReturnShipments() {
  return function getAllReturnShipmentsRequest(dispatch, getState) {
    dispatch(toggleLoading(true));
    const requestData = getState().shipmentsData;
    const path = `/shipment_details/?page=${requestData.pageReturn}&shipment_type=return&sorting=${requestData.sortingReturn}`;
    dispatch(setErrorMessage());
    return standardRequest(path, 'GET')
      .then((data) => {
        if (data.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${data.error}`)));
        } else {
          dispatch(saveShipments(data));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function changeLocationPattern(location) {
  return function changeLocationPatternRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_CHANGE_LOCATION_PATTERN',
      location_pattern: location,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function changeShipmentType(shipmentType) {
  return function changeShipmentTypeRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_CHANGE_SHIPMENT_TYPE',
      shipment_type: shipmentType,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function changeToDate(date) {
  return function changeToDateRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_CHANGE_TO_DATE',
      to_date: date,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function changeFromDate(date) {
  return function changeFromDateRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_CHANGE_FROM_DATE',
      from_date: date,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function resetRequestState() {
  return function resetRequestStateRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_RESET_REQUEST_STATE',
    });
    dispatch(getAllShipments());
  };
}

export function setActiveFilterPaginatorElement(page) {
  return function setActiveFilterPaginatorElementRequest(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_SET_ACTIVE_FILTER_PAGINATOR_ELEMENT',
      page,
    });
    return Promise.resolve();
  };
}

export function setActiveFilterPaginatorElementReturn(pageReturn) {
  return function setActiveFilterPaginatorElementRequestReturn(dispatch) {
    dispatch({
      type: 'SHIPMENTS_ACTION_SET_ACTIVE_FILTER_PAGINATOR_ELEMENT_RETURN',
      page: pageReturn,
    });
    return Promise.resolve();
  };
}

function returnSortingType(sortType, sortDirection, sorting) {
  if (sortType === sorting) {
    if (sortDirection === 'desc') {
      return `${sorting}+asc`;
    }
    return `${sorting}+desc`;
  }
  return `${sorting}+asc`;
}

export function setSortingType(currentSorting, sorting, requestData) {
  return function setSortingTypeRequest(dispatch) {
    const currentSortingSplited = currentSorting.split('+');
    const sortingType = returnSortingType(currentSortingSplited[0], currentSortingSplited[1], sorting);
    dispatch({
      type: 'SHIPMENTS_ACTION_SET_SORTING_TYPE',
      sorting: sortingType,
      page: 1,
    });
    dispatch(
      getAllShipments({
        ...requestData,
        sorting: sortingType,
      })
    );
    return Promise.resolve();
  };
}

export function setReturnSortingType(currentSorting, sorting, requestData) {
  return function setSortingTypeRequest(dispatch) {
    const currentSortingSplited = currentSorting.split('+');
    const sortingType = returnSortingType(currentSortingSplited[0], currentSortingSplited[1], sorting);
    dispatch({
      type: 'SHIPMENTS_ACTION_SET_RETURN_SORTING_TYPE',
      sorting: sortingType,
      page: 1,
    });
    dispatch(
      getAllReturnShipments({
        ...requestData,
        sorting: sortingType,
      })
    );
    return Promise.resolve();
  };
}

export const createNewReturnBox = (boxId = '') => (dispatch) =>
  new Promise((resolve) => {
    dispatch(setErrorMessage());
    dispatch(renderNotification(''));
    dispatch(toggleLoading(true));
    const path = '/shipments/create_return_shipment';
    const body = {
      external_identifier: boxId,
    };
    standardRequest(path, 'POST', {}, body).then((response) => {
      dispatch(toggleLoading(false));
      if (response.error && response.error !== 404 && response.error !== 422) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      }
      resolve(response);
    });
  });

export function updateBoxExternalId(id) {
  return (dispatch) => {
    dispatch({
      type: 'SHIPMENTS_ACTIONS_UPDATE_BOX_EXTERNAL_ID',
      box_external_id: id,
    });
    return Promise.resolve();
  };
}

export function updateBatteryExternalId(id) {
  return (dispatch) => {
    dispatch({
      type: 'SHIPMENTS_ACTIONS_UPDATE_BATTERY_EXTERNAL_ID',
      box_external_id: id,
    });
    return Promise.resolve();
  };
}
