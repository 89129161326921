import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import locales from 'constants/translations';
import rootReducer from './reducers/root-reducer';

let middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true });
  // @ts-ignore
  middleware = [...middleware, reduxImmutableStateInvariant(), logger];
}

// @ts-ignore
export default function configureStore(initialState?) {
  const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(locales));
  store.dispatch(setLocale('en'));

  return store;
}
