import React from 'react';
import PropTypes from 'prop-types';

export default function AllRentalsContent(props) {
  return (
    <div className="all-rentals-content">
      <div className="all-rentals-content__rentals">{props.children[0]}</div>
      <div className="all-rentals-content__filter">{props.children[1]}</div>
    </div>
  );
}
AllRentalsContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
