import React from 'react';
import PropTypes from 'prop-types';

export default function BananaTag({ children, deleteAction }) {
  return (
    <div className="banana-tag">
      {children}
      <button className="banana-tag__button" onClick={deleteAction}>
        <i className="fa fa-times" aria-hidden="true" />
      </button>
    </div>
  );
}

BananaTag.propTypes = {
  children: PropTypes.string.isRequired,
  deleteAction: PropTypes.func.isRequired,
};
