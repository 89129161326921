import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { compareObjects } from 'helpers/global-methods';
import Invoice from './invoice';

export default class InvoicesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingParam: 'id',
    };
    this.togglePaidStatus = this.togglePaidStatus.bind(this);
    this.toggleSortingType = this.toggleSortingType.bind(this);
  }

  togglePaidStatus() {
    this.props.togglePaidStatus(!this.props.paidBool);
  }

  toggleSortingType(sortingParam) {
    this.setState({ sortingParam });
  }

  renderPaidStatus() {
    if (this.props.paidBool === null) {
      return '(toggle_paid_records)';
    } else if (this.props.paidBool === true) {
      return 'Not paid';
    } else if (this.props.paidBool === false) {
      return 'All';
    }
    return null;
  }

  render() {
    return (
      <div className="block block--padded">
        <span className="title title--medium">All records </span>
        <button className="invoice__button" onClick={this.togglePaidStatus}>
          {this.renderPaidStatus()}
        </button>
        <div className="invoice__table">
          <div className="invoice__header">
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('yearly_number');
                }}
              >
                <Translate value="invoice.number" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('invoice_type');
                }}
              >
                <Translate value="invoice.invoiceType" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('start_billing_period');
                }}
              >
                <Translate value="invoice.billingPeriod" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('amount');
                }}
              >
                <Translate value="invoice.amount" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('invoice_date');
                }}
              >
                <Translate value="invoice.invoiceDate" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('due_date');
                }}
              >
                <Translate value="invoice.dueDate" />
              </button>
            </div>
            <div className="invoice__header__cell">
              <button
                onClick={() => {
                  this.toggleSortingType('status');
                }}
              >
                <Translate value="invoice.status" />
              </button>
            </div>
          </div>
          {[...this.props.invoices].sort(compareObjects(this.state.sortingParam)).map((invoice) => (
            <Invoice
              key={invoice.id}
              id={invoice.id}
              yearlyNumber={invoice.yearly_number}
              year={invoice.year}
              type={invoice.invoice_type}
              tage={invoice.tage}
              amount={invoice.amount}
              startBillingPeriod={invoice.start_billing_period}
              endBillingPeriod={invoice.end_billing_period}
              invoiceDate={invoice.invoice_date}
              dueDate={invoice.due_date}
              status={invoice.status}
              getValoraInvoicePdf={this.props.getValoraInvoicePdf}
              getValoraSalesReport={this.props.getValoraSalesReport}
              getValoraTransactionReport={this.props.getValoraTransactionReport}
              paidBool={this.props.paidBool}
              updateValoraInvoice={this.props.updateValoraInvoice}
              removePaidInvoices={this.props.removePaidInvoices}
            />
          ))}
        </div>
      </div>
    );
  }
}

InvoicesTable.propTypes = {
  invoices: PropTypes.array.isRequired,
  togglePaidStatus: PropTypes.func.isRequired,
  paidBool: PropTypes.bool,
  getValoraInvoicePdf: PropTypes.func.isRequired,
  getValoraSalesReport: PropTypes.func.isRequired,
  getValoraTransactionReport: PropTypes.func.isRequired,
  updateValoraInvoice: PropTypes.func.isRequired,
  removePaidInvoices: PropTypes.func.isRequired,
};

InvoicesTable.defaultProps = {
  paidBool: null,
};
