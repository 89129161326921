import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BananaTable, BananaButton, BananaCheckbox } from 'banana-framework';
import { I18n } from 'react-redux-i18n';

import * as goodsOrdersOverviewActions from './goods-orders-overview-actions';

class GoodsOrdersOverview extends Component {
  constructor(props) {
    super(props);
    this.showDeletePopup = this.showDeletePopup.bind(this);
    this.getShipmentsForOrder = this.getShipmentsForOrder.bind(this);
    this.allShipmentsChecked = this.allShipmentsChecked.bind(this);
    this.selectedOrderQuantities = this.selectedOrderQuantities.bind(this);
    this.showExecutedOrders = this.showExecutedOrders.bind(this);
    this.hideExecutedOrders = this.hideExecutedOrders.bind(this);
  }

  componentDidMount() {
    this.props.actions.sharedResetAppState();
    if (!this.props.orders.length) {
      this.props.actions.getAllGoodsOrders({ includeExecutedOrders: false });
    }
  }

  getShipmentsForOrder(orderId) {
    this.props.actions.getShipmentsAssignedToOrder(orderId);
  }

  showDeletePopup(orderId) {
    const applyFunc = (id) => {
      this.props.actions.deleteOrder(id);
    };
    this.props.actions.sharedToggleDialogWindow({
      isVisibleDialogWindow: true,
      applyFunc: applyFunc.bind(this, orderId),
    });
  }

  allShipmentsChecked() {
    const allShipmentsId = this.props.shipments.map((s) => s.id);

    // check that shipment ids are present in the checked shipment
    const allShipmentsChecked = allShipmentsId.map((id) => {
      return this.props.checkedShipments.includes(id);
    });

    // check if they're all checked (array is [true, true, ...])
    return allShipmentsChecked.every(Boolean);
  }

  selectedOrderQuantities() {
    const selectedOrderObj = this.props.orders.find((o) => o.orderId === this.props.selectedOrder);
    const { progress } = selectedOrderObj;

    const orderedMainUnits = selectedOrderObj.mainQuantity;
    const { mainUnit } = selectedOrderObj;
    let orderedBatteries = orderedMainUnits;

    // If mainUnit not batteries, convert it to it batteries (boxes have 16 batteries, pallets have 160 boxes)
    if (mainUnit === 'pallets') {
      orderedBatteries *= 2560;
    } else if (mainUnit === 'boxes') {
      orderedBatteries *= 16;
    }

    const preparedBatteries = this.props.shipments.map((s) => s.number_of_batteries).reduce((tot, n) => tot + n);
    const preparedEnough = progress === 1;

    return {
      orderedBatteries,
      preparedBatteries,
      preparedEnough,
      progress,
    };
  }

  showShipments() {
    if (this.props.selectedOrder === '') {
      return '';
    } else if (!this.props.shipments.length) {
      return <span>No shipments assigned to this order</span>;
    }

    const { orderedBatteries, preparedBatteries, preparedEnough } = this.selectedOrderQuantities();

    const selectedOrderStatus = this.props.orders.find((o) => o.orderId === this.props.selectedOrder).status;
    const isOrderConsolidated = selectedOrderStatus === 'ready' || selectedOrderStatus === 'executed';

    return (
      <div>
        <BananaTable
          type="shipment-compact"
          data={this.props.shipments}
          isOrderConsolidated={isOrderConsolidated}
          shipmentChecked={this.props.actions.checkShipmentForConsolidation}
          shipmentUnchecked={this.props.actions.uncheckConsolidatedShipment}
          checkedShipments={this.props.checkedShipments}
        />
        <ul>
          <li>
            <b>Ordered batteries:</b> {orderedBatteries}
          </li>
          <li>
            <b>Prepared batteries:</b> {preparedBatteries}
          </li>
        </ul>
        <br />

        {!isOrderConsolidated && (
          <BananaButton
            onClick={() => this.props.actions.setOrderStatus(this.props.selectedOrder, 2)}
            disabled={!this.allShipmentsChecked() || !preparedEnough}
          >
            {I18n.t('goodsOrder.consolidateOrder')}
          </BananaButton>
        )}
        {isOrderConsolidated && (
          <React.Fragment>
            <BananaButton onClick={() => this.props.actions.setOrderStatus(this.props.selectedOrder, 1)}>
              {I18n.t('goodsOrder.deconsolidateOrder')}
            </BananaButton>
            <br />
            <br />
            <BananaButton onClick={() => this.props.actions.setOrderStatus(this.props.selectedOrder, 3)}>
              {I18n.t('goodsOrder.deliverOrder')}
            </BananaButton>
          </React.Fragment>
        )}
      </div>
    );
  }

  showExecutedOrders() {
    this.props.actions.getAllGoodsOrders({ includeExecutedOrders: true });
  }

  hideExecutedOrders() {
    this.props.actions.getAllGoodsOrders({ includeExecutedOrders: false });
  }

  render() {
    const h2Style = {
      marginTop: '40px',
      marginBottom: '20px',
      fontSize: '18px',
    };

    const linkStyle = {
      fontSize: '12px',
      color: 'black',
    };

    const checkBoxStyle = {
      lineHeight: '5em',
      color: 'red',
    };

    return (
      <div>
        <div className="align-right">
          <BananaCheckbox
            style={checkBoxStyle}
            checkedFunc={this.showExecutedOrders}
            uncheckedFunc={this.hideExecutedOrders}
            checked={this.props.executedOrdersEnabled}
            value="Toggle executed orders"
            toggleSwitch
          />
        </div>

        <BananaTable
          type="order"
          data={this.props.orders}
          showDeletePopup={this.showDeletePopup}
          viewAction={this.getShipmentsForOrder}
          currentSortingType="order"
          setSortingType={() => {}}
        />

        {this.props.selectedOrder !== '' && (
          <h2 style={h2Style}>
            {`Shipments assigned to order #${this.props.selectedOrder} `}
            <Link to={`/goods_orders/${this.props.selectedOrder}/assign_shipments`} style={linkStyle}>
              (assign shipments)
            </Link>
          </h2>
        )}
        {this.showShipments()}
      </div>
    );
  }
}

GoodsOrdersOverview.propTypes = {
  orders: PropTypes.array,
  selectedOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shipments: PropTypes.array,
  executedOrdersEnabled: PropTypes.bool,
  checkedShipments: PropTypes.array,
  actions: PropTypes.object.isRequired,
};

GoodsOrdersOverview.defaultProps = {
  orders: [],
  selectedOrder: '',
  shipments: [],
  executedOrdersEnabled: false,
  checkedShipments: [],
};

function mapStateToProps({ goodsOrdersOverviewData }) {
  return {
    orders: goodsOrdersOverviewData.orders,
    selectedOrder: goodsOrdersOverviewData.selectedOrder,
    shipments: goodsOrdersOverviewData.shipments,
    executedOrdersEnabled: goodsOrdersOverviewData.executedOrdersEnabled,
    checkedShipments: goodsOrdersOverviewData.checkedShipments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(goodsOrdersOverviewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodsOrdersOverview);
