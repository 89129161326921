import { I18n } from 'react-redux-i18n';
import download from 'downloadjs';

import standardRequest from 'helpers/backend-requests';
import { toggleDialogWindow, setErrorMessage, resetAppState, toggleLoading } from 'app-redux/actions/app-actions';
import { ThunkAction } from 'redux-thunk';
import { GlobalState } from 'app-redux/reducers/initial-state';
import { AnyAction } from 'redux';
import {
  ActionType,
  BoxType,
  CableDataType,
  DirectionType,
  LocationType,
  ShipmentLabel,
  shipmentStore,
  ShipmentSummaryType,
  ShipmentType,
  ShipmentTypeType,
} from './shipment-data';

export const sharedToggleDialogWindow = toggleDialogWindow;

/**
 * Utilities
 */
const validateNrBoxesVal = (val: number) => (!Number.isNaN(val) && val !== null && val !== undefined ? val.toString() : '');

/**
 * Simple actions
 */
const resetShipment = shipmentStore.setSimpleAction(ActionType.RESET_SHIPMENT, (state, action) => ({
  ...shipmentStore.initialState,
})).action;

const saveToAvailableLocations = shipmentStore.setPayloadAction<LocationType[]>(
  ActionType.SAVE_TO_AVAILABLE_LOCATIONS,
  (locations) => locations,
  (state, action) => ({ ...state, availableLocationsTo: action.payload })
).action;

const saveFromAvailableLocations = shipmentStore.setPayloadAction<LocationType[]>(
  ActionType.SAVE_FROM_AVAILABLE_LOCATIONS,
  (locations) => locations,
  (state, action) => ({ ...state, availableLocationsFrom: action.payload })
).action;

const saveShipment = shipmentStore.setPayloadAction<ShipmentType>(
  ActionType.SAVE_SHIPMENT,
  (shipment) => shipment,
  (state, action) => ({ ...state, ...action.payload })
).action;

const updateShipmentType = shipmentStore.setPayloadAction<ShipmentTypeType | null>(
  ActionType.SET_SHIPMENT_TYPE,
  (shipmentType) => shipmentType,
  (state, action) => ({ ...state, shipmentType: action.payload })
).action;

const pushBox = shipmentStore.setPayloadAction<BoxType>(
  ActionType.PUSH_SHIPMENT_BOX,
  (box) => box,
  (state, action) => ({ ...state, boxes: [{ ...action.payload, created: false }, ...state.boxes] })
).action;

const setShipmentSummary = shipmentStore.setPayloadAction<ShipmentSummaryType | null>(
  ActionType.UPDATE_SHIPMENT_SUMMARY,
  (shipmentSummary) => shipmentSummary,
  (state, action) => ({
    ...state,
    shipmentSummary: action.payload,
  })
).action;

const setAssignedBoxState = shipmentStore.setPayloadAction<{ checked: boolean; shipmentId: number }>(
  ActionType.TOGGLE_ASSIGNED_BOX_STATE,
  (payload) => payload,
  (state, action) => ({
    ...state,
    assignedBoxes: [...(state.assignedBoxes || [])].map((box) =>
      box.id === action.payload.shipmentId
        ? {
            ...box,
            id: action.payload.shipmentId,
            checked: action.payload.checked,
          }
        : box
    ),
  })
).action;

const removeShipmentBox = shipmentStore.setPayloadAction<number>(
  ActionType.REMOVE_SHIPMENT_BOX,
  (boxId) => boxId,
  (state, action) => ({
    ...state,
    boxes: [...(state.boxes || [])].filter((box) => box.box_external_id !== action.payload),
  })
).action;

const removeAssignedBox = shipmentStore.setPayloadAction<number>(
  ActionType.REMOVE_ASSIGNED_BOX,
  (boxId) => boxId,
  (state, action) => ({
    ...state,
    assignedBoxes: [...(state.assignedBoxes || [])].filter((box) => box.id !== action.payload),
  })
).action;

const saveBox = shipmentStore.setPayloadAction<BoxType>(
  ActionType.SAVE_BOX,
  (box) => box,
  (state, action) => ({
    ...state,
    boxes: [...(state.boxes || [])].map((box) =>
      box.box_external_id === action.payload.box_external_id ? { ...action.payload, created: false } : box
    ),
  })
).action;

const addNewBox = shipmentStore.setPayloadAction<BoxType>(
  ActionType.CREATE_NEW_BOX,
  (box) => box,
  (state, action) => ({
    ...state,
    assignedBoxes: [{ id: action.payload.box_external_id, checked: true }, ...(state.assignedBoxes || [])],
    boxes: [{ ...action.payload, created: true }, ...state.boxes],
  })
).action;

const updateShipmentSummaryBoxes = shipmentStore.setSimpleAction(ActionType.UPDATE_SHIPMENT_SUMMARY_BOXES, (state) => ({
  ...state,
  shipmentSummary: state.shipmentSummary && {
    ...state.shipmentSummary,
    number_of_boxes: state.assignedBoxes.length,
  },
})).action;

const setLogisticsSlipNrBoxes = shipmentStore.setPayloadAction<string>(
  ActionType.SET_LOGISTICS_SLIP_NR_BOXES,
  (logisticsSlipNrBoxes) => logisticsSlipNrBoxes,
  (state, action) => ({
    ...state,
    shipmentSummary: state.shipmentSummary && {
      ...state.shipmentSummary,
      logistics_slip_number_of_boxes: action.payload,
    },
  })
).action;

const setLogisticsSlipCode = shipmentStore.setPayloadAction<string>(
  ActionType.SET_LOGISTICS_SLIP_CODE,
  (logisticsSlipCode) => logisticsSlipCode,
  (state, action) => ({
    ...state,
    shipmentSummary: state.shipmentSummary && {
      ...state.shipmentSummary,
      logistics_slip_code: action.payload,
    },
  })
).action;

// TODO: remove this
// const removeAssignedBoxInfo = (externalId) => ({ type: 'SHIPMENT_ACTION_REMOVE_ASSIGNED_BOX_INFO', externalId });
const removeAssignedBoxInfo = shipmentStore.setPayloadAction(
  ActionType.REMOVE_ASSIGNED_BOX_INFO,
  (externalId) => externalId,
  (state, action) => ({ ...state })
).action;

const removeAllShipmentBoxes = shipmentStore.setSimpleAction(ActionType.REMOVE_ALL_SHIPMENT_BOXES, (state) => ({
  ...state,
  boxes: [],
  assignedBoxes: [...(state.assignedBoxes || [])].map((box) => ({ ...box, checked: false })),
})).action;

/**
 * Thunks
 */
type ShipmentThunks<R> = ThunkAction<R, GlobalState, null, AnyAction>;

type SharedResetAppStateThunk = () => ShipmentThunks<void>;
export const sharedResetAppState: SharedResetAppStateThunk = () => (dispatch) => {
  dispatch(resetShipment());
  dispatch(resetAppState());
};

type ToggleAssignedBoxThunk = (checked: boolean, shipmentId: number) => ShipmentThunks<void>;
const toggleAssignedBoxState: ToggleAssignedBoxThunk = (checked, shipmentId) => (dispatch) =>
  dispatch(setAssignedBoxState({ checked, shipmentId }));

// Typing hinted at from https://mariusschulz.com/blog/keyof-and-lookup-types-in-typescript
type UpdateShipmentSummaryThunk = <K extends keyof ShipmentSummaryType>(
  key: K,
  value: ShipmentSummaryType[K]
) => ShipmentThunks<void>;
const updateShipmentSummary: UpdateShipmentSummaryThunk = (key, value) => (dispatch, getState) => {
  const { shipmentSummary } = getState()[ShipmentLabel.STATE];
  let newShipmentSummary: ShipmentSummaryType | null = shipmentSummary ? { ...shipmentSummary } : null;

  if (newShipmentSummary) newShipmentSummary[key] = value; // TS rules!
  dispatch(setShipmentSummary(newShipmentSummary));
};

type SaveAvailableLocationsThunk = (locations: LocationType[], direction: DirectionType) => ShipmentThunks<void>;
const saveAvailableLocations: SaveAvailableLocationsThunk = (locations, direction) => (dispatch) => {
  switch (direction) {
    case 'from':
      dispatch(saveFromAvailableLocations(locations));
      break;
    case 'to':
      dispatch(saveToAvailableLocations(locations));
      break;
    default:
      console.warn("IllegalState::direction > Should be 'from' or 'to', it was: ", direction);
  }
};

type GetShipmentThunk = (id: number) => ShipmentThunks<Promise<boolean>>;
export const getShipment: GetShipmentThunk = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(toggleLoading(true));
    const path = `/shipment_details/${id}/edit`;
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
          reject(response.error);
        } else {
          const assignedBoxes = response.assigned_boxes.map((box: BoxType) => ({
            id: box,
            checked: false,
          }));
          const newShipment: ShipmentType = {
            id: response.shipment_detail_id,
            shipmentType: response.shipment_type,
            shipmentSummary: response.summary_card,
            assignedBoxes,
            boxes: [],
            availableLocationsFrom: [],
            availableLocationsTo: [],
          };
          dispatch(saveShipment(newShipment));
          resolve(true);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  });

type GetAvailableLocationsThunk = (direction: DirectionType, location?: string) => ShipmentThunks<void>;
export const getAvailableLocations: GetAvailableLocationsThunk =
  (direction, location = '') =>
  (dispatch) => {
    let path;
    if (location.split('|')[1]) {
      path = `/locations/?shipment_direction=from&name_or_pos_pattern=${location.split('|')[1].replace(/\s/g, '')}`;
    } else {
      path = `/locations/?shipment_direction=from&name_or_pos_pattern=${location.split('|')[0]}`;
    }
    dispatch(setErrorMessage());
    standardRequest(path, 'GET').then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(saveAvailableLocations(response.locations, direction));
      }
    });
  };

type RequestAvailableLocationsThunk = (location?: string) => ShipmentThunks<Promise<LocationType[]>>;
export const requestAvailableLocations: RequestAvailableLocationsThunk =
  (location = '') =>
  async (dispatch) => {
    // clean location
    let nameOrPosPattern;
    if (location === null || location === undefined) nameOrPosPattern = '';
    else nameOrPosPattern = location.split('|')[1] ? location.split('|')[1].replace(/\s/g, '') : location.split('|')[0];
    const path = `/locations/?shipment_direction=from&name_or_pos_pattern=${nameOrPosPattern}`;

    dispatch(setErrorMessage());
    const response = await standardRequest(path, 'GET');
    if (response.error) {
      dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      return [];
    }
    return response.locations as LocationType[];
  };

type UpdateLocationThunk = (
  locationType: DirectionType,
  id: string,
  location: LocationType,
  locationName: string
) => ShipmentThunks<Promise<boolean>>;
export const updateLocation: UpdateLocationThunk = (locationType, id, location, locationName) => (dispatch) =>
  new Promise((resolve) => {
    const path = `/shipment_details/${id}/update_location`;
    const body = {
      location_type: locationType,
      location_id: location,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(updateShipmentType(response.shipment_type));
        dispatch(updateShipmentSummary(`${locationType}_location_name`, locationName.split('|')[0].trim()));
        // TODO: potential bug! shipmentSummary only has `from_location_name` and `to_location_name`
        dispatch(updateShipmentSummary(`${locationType}_location_pos_number`, locationName.split('|')[1].trim())); // TODO: TS error on _location_pos_number so changed to _location_name, check if it works
      }
      resolve(true);
    });
  });

type UpdateShipmentDateThunk = (id: string, date: string) => ShipmentThunks<Promise<boolean>>;
export const updateShipmentDate: UpdateShipmentDateThunk = (id, date) => (dispatch) =>
  new Promise((resolve) => {
    const path = `/shipment_details/${id}/update_shipment_date`;
    const body = {
      shipment_date: date,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(updateShipmentSummary('shipment_date', date));
        dispatch(updateShipmentSummary('expiration_date', response.expiration_date));
      }
      resolve(true);
    });
  });

type UpdateExpirationDateThunk = (id: string, date: string) => ShipmentThunks<Promise<boolean>>;
export const updateExpirationDate: UpdateExpirationDateThunk = (id, date) => (dispatch) =>
  new Promise((resolve) => {
    const path = `/shipment_details/${id}/update_expiration_date`;
    const body = {
      expiration_date: date,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(updateShipmentSummary('expiration_date', date));
      }
      resolve(true);
    });
  });

type UpdateBoxIdThunk = <T>(
  id: number,
  shipmentId: number,
  newBoxIdentifier: string,
  externalId: number
) => ShipmentThunks<Promise<T>>;
export const updateBoxId: UpdateBoxIdThunk = (boxId, shipmentId, newBoxIdentifier, externalId) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    const path = '/shipments/edit_box_id/';
    const body = {
      new_box_identifier: newBoxIdentifier,
      id: boxId,
      shipment_detail_id: shipmentId,
    };
    standardRequest(path, 'POST', {}, body)
      .then((response) => {
        if (!response.error) {
          dispatch(removeAssignedBox(externalId));
          dispatch(removeShipmentBox(externalId));
          dispatch(addNewBox(response));
        } else if (response.error !== 404 && response.error !== 422) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        }
        resolve(response);
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  });

type UpdateBoxExpirationDateThunk = (
  id: number,
  shipmentId: number,
  newExpirationDate: string
) => ShipmentThunks<Promise<boolean>>;
export const updateBoxExpirationDate: UpdateBoxExpirationDateThunk = (boxId, shipmentId, newExpirationDate) => (dispatch) =>
  new Promise((resolve) => {
    const path = '/shipments/update_expiration_date';
    const body = {
      expiration_date: newExpirationDate,
      box_id: boxId,
      shipment_detail_id: shipmentId,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((res) => {
      if (res.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
      } else {
        dispatch(
          saveBox({
            id: res.id,
            box_external_id: res.box_external_id,
            expiration_date: res.expiration_date,
            cable_data: res.cable_data,
            battery_ids: res.battery_ids,
            logistics_partner_thingable_identifier: res.logistics_partner_thingable_identifier,
            before_hq_location: res.before_hq_location,
            before_hq_shipment_detail_id: res.before_hq_shipment_detail_id,
            logista_box_identifier: res.logista_box_identifier,
          })
        );
      }
      resolve(true);
    });
  });

type UpdateLogistaBoxIdentifierThunk = <T>(boxId: number, shipmentId: number, identifier: string) => ShipmentThunks<Promise<T>>;
export const updateLogistaBoxIdentifier: UpdateLogistaBoxIdentifierThunk = (boxId, shipmentId, identifier) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    const path = '/shipments/update_logista_box_identifier';
    const body = {
      logista_box_identifier: identifier,
      box_id: boxId,
      shipment_detail_id: shipmentId,
    };

    standardRequest(path, 'POST', {}, body)
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        }

        resolve(response);
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  });

type UpdateCablesDataThunk = (box: BoxType, shipmentId: number, cableData: CableDataType) => ShipmentThunks<Promise<boolean>>;
export const updateCablesData: UpdateCablesDataThunk = (box, shipmentId, cableData) => (dispatch) =>
  new Promise((resolve) => {
    const newCableData: CableDataType = {};
    for (let i = 0; i < Object.keys(cableData).length; i += 1) {
      newCableData[i.toString()] = +cableData[i];
    }
    const path = '/shipments/update_cable_numbers';
    const body = {
      shipment_detail_id: shipmentId,
      box_id: box.id,
      cable_numbers: newCableData,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((res) => {
      if (res.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
      } else {
        dispatch(updateShipmentSummary('number_of_cables', res.cables_count));
        dispatch(
          saveBox({
            ...box,
            id: res.id,
            box_external_id: res.box_external_id,
            cable_data: res.cable_data,
            battery_ids: res.battery_ids,
          })
        );
      }
      resolve(res);
    });
  });

type UpdateLogisticsSlipNrBoxesThunk = <T>(shipmentId: number, logisticsSlipNrBoxes: number) => ShipmentThunks<Promise<T>>;
export const updateLogisticsSlipNrBoxes: UpdateLogisticsSlipNrBoxesThunk = (shipmentId, logisticsSlipNrBoxes) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(setErrorMessage());
    const body = { logistics_slip_number_of_boxes: validateNrBoxesVal(logisticsSlipNrBoxes) };
    const path = `/shipment_details/${shipmentId}/update_logistics_slip_number_of_boxes`;
    standardRequest(path, 'POST', {}, body)
      .then(async (res) => {
        if (res.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
        } else {
          dispatch(setLogisticsSlipNrBoxes(body.logistics_slip_number_of_boxes));
          resolve(res);
        }
      })
      .catch((reason) => reject(reason));
  });

type UpdateLogisticsSlipCodeThunk = <T>(shipmentId: number, logisticsSlipCode: string) => ShipmentThunks<Promise<T>>;
export const updateLogisticsSlipCode: UpdateLogisticsSlipCodeThunk = (shipmentId, logisticsSlipCode) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(setErrorMessage());
    const path = `/shipment_details/${shipmentId}/update_logistics_slip_code`;
    const body = { logistics_slip_code: logisticsSlipCode };
    standardRequest(path, 'POST', {}, body)
      .then(async (res) => {
        if (res.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
        } else {
          dispatch(setLogisticsSlipCode(logisticsSlipCode));
          resolve(res);
        }
      })
      .catch((reason) => reject(reason));
  });

type SetBeforeHQShipmentDetailIdThunk = <T>(box: BoxType, locationId: string) => ShipmentThunks<Promise<T>>;
export const setBeforeHQShipmentDetailId: SetBeforeHQShipmentDetailIdThunk = (box, locationId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const path = `/shipment_details/${box.before_hq_shipment_detail_id}/update_location`;
    const body = {
      location_type: 'from',
      location_id: locationId,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body)
      .then((res) => {
        if (!res.error)
          dispatch(
            saveBox({
              ...box,
              before_hq_location: locationId,
            })
          );
        else if (res.error !== 404 && res.error !== 422) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
          reject(res.error);
        }
        resolve(res);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        reject(err);
      });
  });

type SetExternalBoxCodeThunk = <T>(
  box: BoxType,
  shipmentId: number,
  logisticsPartnerThingableIdentifier: string
) => ShipmentThunks<Promise<T>>;
export const setExternalBoxCode: SetExternalBoxCodeThunk = (box, shipmentId, logisticsPartnerThingableIdentifier) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!!logisticsPartnerThingableIdentifier && logisticsPartnerThingableIdentifier === '') {
      reject('logisticsPartnerThingableIdentifier not found');
      return;
    }
    const path = `/shipments/update_logistics_partner_thingable_identifier`;
    const body = {
      shipment_detail_id: shipmentId,
      box_id: box.id,
      logistics_partner_thingable_identifier: logisticsPartnerThingableIdentifier,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body)
      .then((res) => {
        if (!res.error)
          dispatch(
            saveBox({
              ...box,
              logistics_partner_thingable_identifier: res.logistics_partner_thingable_identifier,
            })
          );
        else if (res.error !== 404 && res.error !== 422) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
          reject(res.error);
        }
        resolve(res);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        reject(err);
      });
  });

type AddNewBattereThunk = <T>(boxId: number, shipmentId: number, battereId: string) => ShipmentThunks<Promise<T>>;
export const addNewBattere: AddNewBattereThunk = (boxId, shipmentId, battereId) => (dispatch) =>
  new Promise((resolve) => {
    const path = '/shipments/';
    const body = {
      external_identifier: battereId,
      thingable_type: 'Battery',
      shipment_detail_id: shipmentId,
      box_id: boxId,
      cable_numbers: 'false',
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, body).then((res) => {
      if (!res.error) {
        // TODO: logic battery_review_case_message
        if (res.battery_review_case_message) {
          // alert(`Hey: ${res.battery_review_case_message}`);
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              dialogTitle: res.battery_review_case_message,
              applyText: 'OK',
            })
          );
        }
        dispatch(updateShipmentSummary('number_of_batteries', res.batteries_count));
        dispatch(
          saveBox({
            id: res.id,
            box_external_id: res.box_external_id,
            expiration_date: res.expiration_date,
            cable_data: res.cable_data,
            battery_ids: res.battery_ids,
            logistics_partner_thingable_identifier: res.logistics_partner_thingable_identifier,
            before_hq_location: res.before_hq_location,
            before_hq_shipment_detail_id: res.before_hq_shipment_detail_id,
            logista_box_identifier: res.logista_box_identifier,
          })
        );
      } else if (res.error !== 404 && res.error !== 422) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
      }
      resolve(res);
    });
  });

type DeleteBatteryThunk = <T>(boxId: number, shipmentId: number, battereId: string) => ShipmentThunks<Promise<T>>;
export const deleteBattery: DeleteBatteryThunk = (boxId, shipmentId, batteryId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const path = '/shipments/delete_battery';
    const body = {
      external_identifier: batteryId.toString(),
      shipment_detail_id: shipmentId.toString(),
      box_id: boxId.toString(),
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'DELETE', {}, body)
      .then((res) => {
        dispatch(toggleDialogWindow({ isVisibleDialogWindow: false }));
        if (res.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
        } else {
          dispatch(updateShipmentSummary('number_of_batteries', res.batteries_count));
          dispatch(
            saveBox({
              id: res.id,
              box_external_id: res.box_external_id,
              expiration_date: res.expiration_date,
              cable_data: res.cable_data,
              battery_ids: res.battery_ids,
              logistics_partner_thingable_identifier: res.logistics_partner_thingable_identifier,
              before_hq_location: res.before_hq_location,
              before_hq_shipment_detail_id: res.before_hq_shipment_detail_id,
              logista_box_identifier: res.logista_box_identifier,
            })
          );
        }
        resolve(res);
      })
      .catch((err) => {
        setErrorMessage(I18n.t(err));
        reject(err);
      });
  });

type DeleteBoxThunk = (boxId: number, shipmentId: number, externalId: number) => ShipmentThunks<Promise<boolean>>;
export const deleteBox: DeleteBoxThunk = (boxId, shipmentId, externalId) => (dispatch) =>
  new Promise((resolve) => {
    const path = '/shipments/delete_box';
    const body = {
      id: boxId,
      shipment_detail_id: shipmentId,
    };
    dispatch(setErrorMessage());
    standardRequest(path, 'DELETE', {}, body).then((response) => {
      dispatch(toggleDialogWindow({ isVisibleDialogWindow: false }));
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(removeAssignedBoxInfo(externalId));
        dispatch(removeAssignedBox(externalId));
        dispatch(removeShipmentBox(externalId));
        dispatch(updateShipmentSummaryBoxes());
        dispatch(updateShipmentSummary('number_of_cables', response.cables_count));
        dispatch(updateShipmentSummary('number_of_batteries', response.batteries_count));
      }
    });
    resolve(true);
  });

type CreateNewBoxThunk = <T>(
  shipmentId: number,
  boxId: number | null,
  shipmentType: ShipmentTypeType
) => ShipmentThunks<Promise<T>>;
export const createNewBox: CreateNewBoxThunk =
  (shipmentId, boxId, shipmentType = 'delivery') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const path = '/shipments';
      const body = {
        external_identifier: boxId || false,
        shipment_detail_id: shipmentId,
        thingable_type: 'Box',
        box_id: false,
        cable_numbers: false,
        shipment_type: shipmentType,
        no_chimpy_box_identifier: boxId === null || boxId === undefined,
      };
      dispatch(toggleLoading(true));
      standardRequest(path, 'POST', {}, body)
        .then((res) => {
          dispatch(setErrorMessage());
          if (!res.error) {
            dispatch(addNewBox(res));
            dispatch(updateShipmentSummaryBoxes());
            dispatch(updateShipmentSummary('number_of_cables', res.cables_count));
            dispatch(updateShipmentSummary('number_of_batteries', res.batteries_count));
          } else if (res.error !== 404 && res.error !== 422) {
            dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
          reject(err);
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    });

type GetSingleBoxInfoThunk = (shipmentId: number, boxId: number) => ShipmentThunks<Promise<true>>;
export const getSingleBoxInfo: GetSingleBoxInfoThunk = (shipmentId, boxId) => (dispatch) =>
  new Promise((resolve) => {
    const path = `/shipments/box_info?id=${boxId}&box_external_identifier=${shipmentId}`;
    dispatch(setErrorMessage());
    standardRequest(path, 'GET').then((response) => {
      if (response.error) dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      else {
        dispatch(toggleAssignedBoxState(true, shipmentId));
        dispatch(pushBox(response as BoxType));
      }
      resolve(true);
    });
  });

type RemoveSingleBoxInfoThunk = (boxId: number) => ShipmentThunks<void>;
export const removeSingleBoxInfo: RemoveSingleBoxInfoThunk = (boxId) => (dispatch) => {
  dispatch(toggleAssignedBoxState(false, boxId));
  dispatch(removeShipmentBox(boxId));
};

type GetCSVEntityThunk = (shipmentId: number, entity: string) => ShipmentThunks<void>;
export const getCSVEntity: GetCSVEntityThunk = (shipmentId, entity) => (dispatch) => {
  dispatch(setErrorMessage());
  dispatch(toggleLoading(true));
  const path = `/shipment_details/${shipmentId}/all_goods?thingable_type=${entity}`;
  standardRequest(path, 'GET', {}, null, 'blob')
    .then(async (res) => {
      if (!res.error) {
        const filename = `${entity}_${shipmentId}.csv`;
        try {
          // TODO: see if this fails and why
          const content = await res.text();
          download(content, filename, 'text/csv');
        } catch (e) {
          throw e;
        }
      } else {
        dispatch(setErrorMessage(I18n.t(`errorCode.${res.error}`)));
      }
    })
    .finally(() => dispatch(toggleLoading(false)));
};

/**
 * Actions
 */
export const actions = {
  getShipment,
  updateLocation,
  getSingleBoxInfo,
  updateShipmentDate,
  removeSingleBoxInfo,
  sharedResetAppState,
  updateExpirationDate,
  getAvailableLocations,
  removeAllShipmentBoxes,
  requestAvailableLocations,
  deleteBox,
  updateBoxId,
  addNewBattere,
  deleteBattery,
  updateCablesData,
  updateBoxExpirationDate,
  updateLogistaBoxIdentifier,
  sharedToggleDialogWindow,
  updateLogisticsSlipNrBoxes,
  updateLogisticsSlipCode,
  createNewBox,
  setExternalBoxCode,
  setBeforeHQShipmentDetailId,
  getCSVEntity,
};

/**
 * Exportable Selectors
 */
export const selectors = (state: GlobalState) => shipmentStore.helper(state);
