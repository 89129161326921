import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

export default class DialogWindow extends Component {
  constructor(props) {
    super(props);
    this.hidePopup = this.hidePopup.bind(this);
    this.callApplyFunc = this.callApplyFunc.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  getProperDialogTitle() {
    if (this.props.dialogTitle === null) return null;
    if (this.props.dialogTitle) return <h3 className="banana-popup__title">{this.props.dialogTitle}</h3>;
    return <h3 className="banana-popup__title">{I18n.t('dialog.dialogTitle')}</h3>;
  }

  getProperDialogContent() {
    if (Array.isArray(this.props.customContent))
      return (
        <ul>
          {this.props.customContent.reduce((result, item) => (
            <React.Fragment>
              {result}
              <li>{item}</li>
            </React.Fragment>
          ))}
        </ul>
      );
    return <div>{this.props.customContent}</div>;
  }

  getProperApplyText() {
    if (this.props.applyText) return this.props.applyText;
    return I18n.t('dialog.applyText');
  }

  getProperCancelText() {
    if (this.props.cancelText) return this.props.cancelText;
    return I18n.t('dialog.cancelText');
  }

  hidePopup(event, hide = false) {
    if (
      this.props.dissmissablePopup &&
      (hide === true ||
        event.target.className === 'banana-popup' ||
        event.target.className === 'banana-popup__button banana-popup__button--cancel')
    ) {
      this.props.toggleDialogWindow({
        isVisibleDialogWindow: false,
      });
    }
  }

  callApplyFunc(event) {
    if (this.props.applyFunc) {
      this.props.applyFunc();
    } else {
      this.hidePopup(event, true);
    }
  }

  handleOnKeyDown() {
    if (this.props.handleOnKeyDown) this.props.handleOnKeyDown();
  }

  renderPopupContent() {
    if (this.props.customContent) {
      return this.getProperDialogContent();
    }

    return (
      <div className="banana-popup__actions">
        <button className="banana-popup__button" onClick={this.callApplyFunc} ref={(input) => input && input.focus()}>
          {this.getProperApplyText()}
        </button>
        {this.props.applyFunc && (
          <button className="banana-popup__button banana-popup__button--cancel" onClick={this.hidePopup}>
            {this.getProperCancelText()}
          </button>
        )}
      </div>
    );
  }

  render() {
    return (
      <div role="dialog">
        <div tabIndex={-1} role="button" className="banana-popup" onClick={this.hidePopup} onKeyDown={this.handleOnKeyDown}>
          <div className={`banana-popup__content ${this.props.popupClass}`}>
            {this.getProperDialogTitle()}
            {this.renderPopupContent()}
          </div>
        </div>
      </div>
    );
  }
}

DialogWindow.propTypes = {
  dialogTitle: PropTypes.string,
  applyText: PropTypes.string,
  cancelText: PropTypes.string,
  toggleDialogWindow: PropTypes.func,
  applyFunc: PropTypes.func,
  handleOnKeyDown: PropTypes.func,
  dissmissablePopup: PropTypes.bool,
  customContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
  popupClass: PropTypes.string,
};

DialogWindow.defaultProps = {
  dialogTitle: null,
  handleOnKeyDown: null,
  applyText: null,
  cancelText: null,
  dissmissablePopup: true,
  applyFunc: null,
  customContent: null,
  toggleDialogWindow: () => {},
  popupClass: '',
};
