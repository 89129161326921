import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getLoginState,
  setLoginState,
  setActiveHeaderComponent,
  toggleRouteMenuState,
  setErrorMessage,
  toggleDialogWindow,
  createShipment,
  changeLocale,
  removeLoginState,
  renderNotification,
  resetAppState,
  toggleLoading,
} from 'app-redux/actions/app-actions';

export default function WrapperCreator(ChildComponent, requestedActions, resetStateBool = false) {
  const sharedActionsList = {};
  const sharedPropsList = {};
  if (resetStateBool) {
    sharedActionsList.resetAppState = resetAppState;
  }
  requestedActions.map((actionType) => {
    if (actionType === 'login') {
      sharedActionsList.getLoginState = getLoginState;
      sharedActionsList.setLoginState = setLoginState;
      sharedActionsList.removeLoginState = removeLoginState;
    } else if (actionType === 'header') {
      sharedActionsList.setActiveHeaderComponent = setActiveHeaderComponent;
    } else if (actionType === 'route-menu') {
      sharedActionsList.toggleRouteMenuState = toggleRouteMenuState;
    } else if (actionType === 'error') {
      sharedActionsList.setErrorMessage = setErrorMessage;
    } else if (actionType === 'dialog') {
      sharedActionsList.toggleDialogWindow = toggleDialogWindow;
    } else if (actionType === 'shipment_creator') {
      sharedActionsList.createShipment = createShipment;
    } else if (actionType === 'locale') {
      sharedActionsList.changeLocale = changeLocale;
    } else if (actionType === 'notification') {
      sharedActionsList.renderNotification = renderNotification;
    } else if (actionType === 'loading') {
      sharedActionsList.toggleLoading = toggleLoading;
    }
    return null;
  });

  class SuperClass extends Component {
    componentDidMount() {
      if (resetStateBool) {
        this.props.sharedActions.resetAppState();
      }
    }

    render() {
      return (
        <ChildComponent
          location={this.props.location}
          sharedProps={this.props.sharedProps}
          sharedActions={this.props.sharedActions}
        />
      );
    }
  }

  SuperClass.propTypes = {
    sharedActions: PropTypes.object.isRequired,
    sharedProps: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

  SuperClass.defaultProps = {
    location: {},
  };

  function mapStateToProps({ appData, i18n }) {
    requestedActions.map((actionType) => {
      if (actionType === 'login') {
        sharedPropsList.appState = appData.appState;
      } else if (actionType === 'header') {
        sharedPropsList.activeHeaderComponent = appData.activeHeaderComponent;
      } else if (actionType === 'route-menu') {
        sharedPropsList.isRouteMenuOpened = appData.isRouteMenuOpened;
      } else if (actionType === 'error') {
        sharedPropsList.error = appData.error;
      } else if (actionType === 'dialog') {
        sharedPropsList.isVisibleDialogWindow = appData.isVisibleDialogWindow;
        sharedPropsList.dialogTitle = appData.dialogTitle;
        sharedPropsList.applyText = appData.applyText;
        sharedPropsList.applyFunc = appData.applyFunc;
        sharedPropsList.cancelText = appData.cancelText;
        sharedPropsList.customContent = appData.customContent;
      } else if (actionType === 'locale') {
        sharedPropsList.locale = i18n.locale;
      } else if (actionType === 'notification') {
        sharedPropsList.notification = appData.notification;
      } else if (actionType === 'loading') {
        sharedPropsList.isLoading = appData.isLoading;
      }
      return null;
    });
    return {
      sharedProps: { ...sharedPropsList },
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sharedActions: bindActionCreators(sharedActionsList, dispatch),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(SuperClass);
}
