import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaInputButton } from 'banana-framework';
import { Translate } from 'react-redux-i18n';

export default class NewReturnBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newBoxError: '',
      successfulResponse: false,
      setFocus: true,
    };
    this.setNewBoxError = this.setNewBoxError.bind(this);
    this.createNewReturnBox = this.createNewReturnBox.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onBlur() {
    this.setState(() => ({ setFocus: false }));
  }

  setNewBoxError(error, boxId = '') {
    this.setState({
      newBoxError: <Translate value={error} barcode={boxId} />,
      successfulResponse: true,
    });
  }

  createNewReturnBox(boxId) {
    this.setNewBoxError('');
    this.props.succFunc(false);
    this.setState({ successfulResponse: false }, () => {
      this.props.saveFunc(boxId).then((response) => {
        if (response.status === 404) {
          this.setNewBoxError('boxErrors.boxDoesntExist', boxId);
        } else if (response.status === 422) {
          this.setNewBoxError('boxErrors.boxExists', boxId);
        } else if (!response.error) {
          this.props.succFunc(boxId, response.location);
        }
      });
    });
  }

  render() {
    return (
      <div className="block">
        <div className="new-return-box">
          <h2 className="title title--medium">
            <Translate value="newBox.addNewBox" />
          </h2>
          <BananaInputButton
            saveFunc={this.createNewReturnBox}
            error={this.state.newBoxError}
            successfulResponse={this.state.successfulResponse}
            setFocus={this.state.setFocus}
            onBlur={this.onBlur}
          />
        </div>
      </div>
    );
  }
}

NewReturnBox.propTypes = {
  succFunc: PropTypes.func.isRequired,
  saveFunc: PropTypes.func.isRequired,
};
