import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaDatepicker, BananaInput, BananaButton, BananaRadiobutton } from 'banana-framework';
import { BananaV2InputOptions, TabFocus } from 'banana-framework-v2';
import { I18n } from 'react-redux-i18n';
import { Translate } from 'react-redux-i18n';
import './goods-order-form.scss';

export default class GoodsOrderForm extends Component {
  // state = { partnerID: null };
  constructor(props) {
    super(props);
    this.saveGoodsOrder = this.saveGoodsOrder.bind(this);
  }

  componentWillUnmount() {
    this.props.updateGoodsOrderPartner('');
  }

  saveGoodsOrder() {
    this.props.saveGoodsOrder(
      {
        partnerID: this.props.partnerID,
        partnerOrderID: this.props.partnerOrderID,
        deliveryDateTime: this.props.deliveryDateTime,
        orderDate: this.props.orderDate,
        status: this.props.status,
        quantity: this.props.quantity,
        chosenUnit: this.props.chosenUnit,
      },
      this.props.goodsOrderID // not null only if edit mode
    );
  }

  getSelected() {
    if (this.props.partnerID && this.props.partners) {
      console.log(
        'getSelected: ',
        this.props.partners,
        this.props.partnerID,
        (this.props.partners || []).find((p) => p.id === this.props.partnerID)
      );
      const found = (this.props.partners || []).find((p) => p.id === this.props.partnerID);
      if (found) return { label: found.partner_name, id: found.id };
    }
    return null;
  }

  preparePartners(partnerName) {
    let result = this.props.partners || [];
    if (partnerName) {
      result = result.filter((partner) => {
        if (
          partnerName &&
          partnerName.toLowerCase &&
          partner &&
          partner.partner_name &&
          partner.partner_name.toLowerCase &&
          partner.partner_name.toLowerCase().includes(partnerName.toLowerCase())
        )
          return true;
        return false;
      });
    }
    return result.map((partner) => ({
      label: partner.partner_name,
      id: partner.id,
    }));
  }

  prepareUnits() {
    return this.props.units.map((unit) => ({
      text: unit.unit_name,
      variant: unit.id,
    }));
  }

  focusAddBox = () => {
    TabFocus.getInstance().focus('goodsOrder.partnerOrderID');
  };

  onChangePartnerName(arg) {
    // console.log('onChangePartnerName: ', arg);
  }
  onSubmitEditingPartnerName(partner) {
    // console.log('onSubmitEditingPartnerName', partner);
    if (partner) {
      this.props.updateGoodsOrderPartner(partner.id);
    }
  }
  onBlurPartnerName(arg) {
    // console.log('onBlurPartnerName: ', arg);
    TabFocus.getInstance().focus('goodsOrder.partnerOrderID');
  }

  render() {
    const radioButtons = this.props.statuses.map((status) => (
      <BananaRadiobutton
        key={status.status_name}
        value={status.id.toString()}
        onChange={() => this.props.updateGoodsOrderStatus(status.id.toString())}
        checked={this.props.status === status.id.toString()}
        name={status.status_name}
        className="-goods-order-form-radiobutton"
      >
        <Translate value={`goodsOrder.${status.status_name}`} />
      </BananaRadiobutton>
    ));
    const editModeEnabled = this.props.editMode;

    return (
      <div>
        <div className="goods-order-form block block--padded">
          <h2 className="title title--medium title--center">
            {!editModeEnabled && <Translate value="goodsOrder.addNewOrder" />}
            {editModeEnabled && <Translate value="goodsOrder.editOrder" />}
          </h2>

          <div className="goods-order-form__element-text goods-order-form__element-text--first">
            <BananaV2InputOptions
              label={I18n.t('goodsOrder.partnerName')}
              getOptions={this.preparePartners.bind(this)}
              selectedOptionID={this.props.partnerID}
              onSubmitEditing={this.onSubmitEditingPartnerName.bind(this)}
              onBlur={this.onBlurPartnerName.bind(this)}
              style={{ paddingVerical: 20 }}
              callback={this.focusAddBox.bind(this)}
              selected={this.getSelected(this.props.partnerID)}
            />
          </div>
          <div className="goods-order-form__element-text">
            <BananaInput
              value={this.props.partnerOrderID}
              onChangeAction={this.props.updateGoodsOrderPartnerOrderID}
              text={<Translate value="goodsOrder.partnerOrderID" />}
              initialValue
              onRefAdd={(ref) => {
                TabFocus.getInstance().add('goodsOrder.partnerOrderID', ref);
                return () => {
                  TabFocus.getInstance().remove('goodsOrder.partnerOrderID');
                };
              }}
            />
          </div>
          <div className="goods-order-form__element-text">
            <BananaDatepicker
              startDate={this.props.orderDate}
              dataChange={this.props.updateGoodsOrderDate}
              text={<Translate value="goodsOrder.orderDate" />}
              initialValue
            />
          </div>
          <div className="goods-order-form__element-text">
            <BananaDatepicker
              withTime
              startDate={this.props.deliveryDateTime}
              dataChange={this.props.updateGoodsOrderDeliveryDateTime}
              text={<Translate value="goodsOrder.deliveryDateTime" />}
              initialValue
            />
          </div>
          <div className="goods-order-form__element-text">Status: {radioButtons}</div>

          <div className="goods-order-form__element-text">
            <BananaInput
              filter="numeric"
              value={this.props.quantity}
              onChangeAction={this.props.updateGoodsOrderQuantity}
              text={<Translate value="goodsOrder.quantity" />}
              initialValue
            />
          </div>
          <div className="goods-order-form__element-text">
            <BananaInput
              value={this.props.chosenUnit}
              onChangeAction={this.props.updateGoodsOrderUnit}
              text={<Translate value="goodsOrder.unit" />}
              options={this.prepareUnits()}
              editable={false}
              initialValue
            />
          </div>

          {/*
            Missing:
              - amount (input numeric text - select for quantity)
              - comments (textarea)
          */}
          <div className="goods-order-form__save-button">
            <BananaButton onClick={this.saveGoodsOrder}>
              {editModeEnabled && 'Save changes'}
              {!editModeEnabled && 'Save'}
            </BananaButton>
          </div>
        </div>
      </div>
    );
  }
}

GoodsOrderForm.propTypes = {
  editMode: PropTypes.bool.isRequired,

  updateGoodsOrderPartner: PropTypes.func.isRequired,
  updateGoodsOrderPartnerOrderID: PropTypes.func.isRequired,
  updateGoodsOrderDeliveryDateTime: PropTypes.func.isRequired,
  updateGoodsOrderDate: PropTypes.func.isRequired,
  updateGoodsOrderStatus: PropTypes.func.isRequired,
  updateGoodsOrderQuantity: PropTypes.func.isRequired,
  updateGoodsOrderUnit: PropTypes.func.isRequired,

  partners: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,

  goodsOrderID: PropTypes.string,
  partnerID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  partnerOrderID: PropTypes.string,
  deliveryDateTime: PropTypes.object,
  orderDate: PropTypes.object,
  status: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chosenUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  saveGoodsOrder: PropTypes.func.isRequired,
};

GoodsOrderForm.defaultProps = {
  goodsOrderID: null,
  partnerID: null,
  partnerOrderID: null,
  deliveryDateTime: null,
  orderDate: null,
  chosenUnit: null,
  quantity: null,
};
