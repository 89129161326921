import React from 'react';
import { I18n } from 'react-redux-i18n';

import { BananaV2InputElement } from 'banana-framework-v2';
import { DirectionType, LocationType, ShipmentSummaryType, ShipmentTypeType } from 'components/containers/shipment/shipment-data';

import ShipmentCell from './shipment-cell';

const getLogisticsSlipNrBoxesVal = (val: string) => (val !== null && val !== undefined ? val : '');

interface Props {
  id?: number | null;
  shipmentDateLoading: boolean;
  shipmentType: ShipmentTypeType | null;
  shipment: ShipmentSummaryType;
  expirationDateLoading: boolean;
  toLocationLoading: boolean;
  fromLocationLoading: boolean;
  updateShipmentDate: (id: string, date: string) => void;
  updateToLocation: (id: string, location: LocationType, locationName: string) => void;
  updateFromLocation: (id: string, location: LocationType, locationName: string) => void;
  getAvailableLocations: (direction: DirectionType, location?: string | undefined) => void;
  prepareLocations: (direction: DirectionType) => {
    text: string;
    variant: string;
  }[];
  updateExpirationDate: (id: string, date: string) => void;
  prepareExpirationDate: () => JSX.Element;
  setLogisticsSlipNrBoxes?: (nrBoxesLogisticsSlip: string) => Promise<unknown>;
  setLogisticsSlipCode?: (logisticsSlipCode: string) => Promise<unknown>;
  getBoxesCSV?: VoidFunction;
  getBatteriesCSV?: VoidFunction;
}

export const ShipmentView: React.FC<Props> = ({
  id = null,
  shipmentDateLoading,
  shipmentType = null,
  shipment,
  expirationDateLoading,
  toLocationLoading,
  fromLocationLoading,
  updateShipmentDate,
  updateToLocation,
  updateFromLocation,
  getAvailableLocations,
  prepareLocations,
  updateExpirationDate,
  prepareExpirationDate,
  setLogisticsSlipNrBoxes = () => {},
  setLogisticsSlipCode = () => {},
  getBoxesCSV = () => {},
  getBatteriesCSV = () => {},
}) => (
  <div className="shipment-details">
    <div style={{ display: 'flex', marginBottom: '2rem', justifyContent: 'center', alignItems: 'center' }}>
      <h2 className="shipment-details__title" style={{ flex: 1, margin: 0 }}>
        {`${I18n.t('shipmentBox.shipment')} #${id} ${I18n.t('shipmentBox.summary')}`}
      </h2>

      <ShipmentCell>
        <button className="banana-button csv-button" onClick={getBoxesCSV}>
          {I18n.t('shipmentBox.getBoxesCSV')} <i className="fa fa-file-excel-o" aria-hidden="true" />
        </button>
      </ShipmentCell>
      <ShipmentCell>
        <button className="banana-button csv-button" onClick={getBatteriesCSV}>
          {I18n.t('shipmentBox.getBatteriesCSV')} <i className="fa fa-file-excel-o" aria-hidden="true" />
        </button>
      </ShipmentCell>
    </div>

    <div className="shipment_details__content">
      <ShipmentCell
        topText={I18n.t('shipmentBox.to')}
        id={id}
        isLoading={toLocationLoading}
        modificator="4"
        changeFunction={updateToLocation}
        startFunction={(location: string) => {
          getAvailableLocations('to', location);
        }}
        type="editableList"
        options={prepareLocations('to')}
      >
        {`${shipment.to_location_name ? `${shipment.to_location_name}` : ''} ${
          shipment.to_location_pos_number ? `| ${shipment.to_location_pos_number}` : ''
        }`}
      </ShipmentCell>
      <ShipmentCell
        topText={I18n.t('shipmentBox.from')}
        id={id}
        isLoading={fromLocationLoading}
        modificator="4"
        changeFunction={updateFromLocation}
        startFunction={(location: string) => {
          getAvailableLocations('from', location);
        }}
        type="editableList"
        options={prepareLocations('from')}
      >
        {`${shipment.from_location_name ? `${shipment.from_location_name}` : ''} ${
          shipment.from_location_pos_number ? `| ${shipment.from_location_pos_number}` : ''
        }`}
      </ShipmentCell>
      <ShipmentCell
        topText={I18n.t('shipmentBox.shipmentDate')}
        isLoading={shipmentDateLoading}
        id={id}
        modificator="4"
        changeFunction={updateShipmentDate}
        type="date"
      >
        {shipment.shipment_date}
      </ShipmentCell>
    </div>
    <div className="shipment_details__content">
      <ShipmentCell topText={I18n.t('shipmentBox.boxes')} id={id} modificator="2">
        {shipment.number_of_boxes}
      </ShipmentCell>
      <ShipmentCell topText={I18n.t('shipmentBox.batteries')} id={id} modificator="2">
        {shipment.number_of_batteries}
      </ShipmentCell>
      <ShipmentCell topText={I18n.t('shipmentBox.cables')} id={id} modificator="2">
        {shipment.number_of_cables}
      </ShipmentCell>
      <ShipmentCell id={id} modificator="2">
        {' '}
      </ShipmentCell>
      {shipmentType !== 'return' && !!shipment.number_of_batteries && (
        <ShipmentCell
          topText={I18n.t('shipmentBox.expirationDate')}
          id={id}
          modificator="4"
          changeFunction={updateExpirationDate}
          type="date"
          isLoading={expirationDateLoading}
        >
          {prepareExpirationDate()}
        </ShipmentCell>
      )}
    </div>
    <div className="shipment_details__content">
      {shipmentType === 'return' && (
        <div className="shipment-cell shipment-cell--width-4 ">
          <BananaV2InputElement
            label={`${I18n.t('shipmentBox.logisticsSlipNrBoxes')} ${I18n.t('global.ifAvailable')}`}
            value={getLogisticsSlipNrBoxesVal(shipment.logistics_slip_number_of_boxes)}
            type="text"
            onSubmitEditing={setLogisticsSlipNrBoxes}
            onBlur={setLogisticsSlipNrBoxes}
            pencil
          />
        </div>
      )}

      {shipmentType === 'return' && (
        <div className="shipment-cell shipment-cell--width-4 ">
          <BananaV2InputElement
            label={`${I18n.t('shipmentBox.logisticsSlipCode')} ${I18n.t('global.ifAvailable')}`}
            placeholder={I18n.t('shipmentBox.logisticsSlipCode')}
            value={shipment.logistics_slip_code || ''}
            type="text"
            onSubmitEditing={setLogisticsSlipCode}
            onBlur={setLogisticsSlipCode}
            pencil
          />
        </div>
      )}
    </div>
  </div>
);
