import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import CableElement from './cable-element';

export default class BoxCable extends Component {
  renderReturnField() {
    let returnValue;
    if (this.props.shipmentType !== 'delivery') {
      if (!this.props.cable['4']) {
        returnValue = 0;
      } else {
        returnValue = this.props.cable['4'];
      }
      return (
        <CableElement
          title={I18n.t('cables.return')}
          isActive={this.props.isActive}
          onChangeAction={(value) => {
            this.props.updateCableData(4, value);
          }}
        >
          {returnValue}
        </CableElement>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={`box-details__cable ${this.props.isLoading ? 'element--is-loading' : ''}`}>
        <CableElement
          title={I18n.t('cables.lightning')}
          isActive={this.props.isActive}
          onChangeAction={(value) => {
            this.props.updateCableData(0, value);
          }}
        >
          {this.props.cable['0']}
        </CableElement>
        <CableElement
          title={I18n.t('cables.pin')}
          isActive={this.props.isActive}
          onChangeAction={(value) => {
            this.props.updateCableData(1, value);
          }}
        >
          {this.props.cable['1']}
        </CableElement>
        <CableElement
          title={I18n.t('cables.microUsb')}
          isActive={this.props.isActive}
          onChangeAction={(value) => {
            this.props.updateCableData(2, value);
          }}
        >
          {this.props.cable['2']}
        </CableElement>
        <CableElement
          title={I18n.t('cables.usbC')}
          isActive={this.props.isActive}
          onChangeAction={(value) => {
            this.props.updateCableData(3, value);
          }}
        >
          {this.props.cable['3']}
        </CableElement>
        {this.renderReturnField()}
      </div>
    );
  }
}

BoxCable.propTypes = {
  cable: PropTypes.object.isRequired,
  updateCableData: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  shipmentType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
