import initialState from './initial-state';

const AppReducer = (state = initialState.appData, action) => {
  switch (action.type) {
    case 'CHANGE_APP_STATE':
      return {
        ...state,
        appState: action.appState,
      };
    case 'CHANGE_ACTIVE_HEADER_COMPONENT':
      return {
        ...state,
        activeHeaderComponent: action.activeHeaderComponent,
        isRouteMenuOpened: true,
      };
    case 'TOGGLE_ROUTE_MENU_STATE':
      return {
        ...state,
        isRouteMenuOpened: action.isRouteMenuOpened,
      };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        error: action.error,
      };
    case 'TOGGLE_DIALOG_WINDOW':
      return {
        ...state,
        dialogTitle: action.dialogTitle,
        isVisibleDialogWindow: action.isVisibleDialogWindow,
        applyText: action.applyText,
        cancelText: action.cancelText,
        applyFunc: action.applyFunc,
        customContent: action.customContent,
      };
    case 'RESET_APP_STATE':
      return {
        ...initialState.appData,
        appState: state.appState,
        error: state.error,
        activeHeaderComponent: state.activeHeaderComponent,
      };
    case 'RENDER_NOTIFICATION':
      return {
        ...state,
        notification: action.notification,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default AppReducer;
