import React from 'react';
import PropTypes from 'prop-types';
import './styles/error-message.scss';

export default function ErrorMessage({ sharedActions, sharedProps }) {
  const removeErrorMessage = () => {
    sharedActions.setErrorMessage();
  };
  return (
    <div className="error-message">
      <div className="error-message__content">
        <i className="fa fa-times error-message__close-button" aria-hidden="true" onClick={removeErrorMessage} />
        <div className="error-message__text">{sharedProps.error}</div>
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  sharedActions: PropTypes.object.isRequired,
  sharedProps: PropTypes.object.isRequired,
};
