const Translations = {
  en: {
    loginContainer: {
      loginTitle: 'Login',
      password: 'Password',
      signIn: 'Sign in',
      email: 'Email',
    },
    mainHeader: {
      operations: 'Operations',
      customerSupport: 'Customer Support',
      analytics: 'Analytics',
      finance: 'Finance',
    },
    routeMenu: {
      logout: 'Logout',
      shipmentsOverview: 'Shipments overview',
      addDeliveryShipment: 'Add delivery shipment',
      addReturnShipmnent: 'Add return shipment',
      goodsOrdersOverview: 'Orders overview',
      addGoodsOrder: 'Add order',
      assignShipmentsToGoodsOrder: 'Assign shipments to order',
      addMockRental: 'Add mock rental',
      allRentals: 'All Rentals',
      valoraInvoices: 'Valora Invoices',
    },
    bananaFilter: {
      findLocation: 'Find Location',
      findBatteryId: 'Sort by battery id',
      findBoxId: 'Sort by box id',
      shipmentType: 'Shipment Type',
      from: 'From',
      to: 'To',
      delivery: 'Delivery',
      returnType: 'Return',
      deliveryReturn: '2 directions',
    },
    bananaTableHeader: {
      id: 'Id',
      to: 'To',
      from: 'From',
      shippingDate: 'Shipping Date',
      expirationDate: 'Expiration Date',
      daysBeforeExpiration: 'Days before exp.',
      boxes: 'Boxes',
      batteries: 'Batteries',
      cables: 'Cables',
      actions: 'Actions',
      partner: 'Partner',
      orderId: 'Partner order ID',
      deliveryDateTime: 'Delivery date and time',
      status: 'Status',
      quantity: 'Quantity',
      progress: 'Progress',
      markForConsolidation: 'Mark for consolidation',
    },
    dialog: {
      dialogTitle: 'Are you sure?',
      applyText: 'Yes',
      cancelText: 'No',
    },
    errorCode: {
      500: 'Server error. Please try again later.',
      401: 'You are not authorized to do this action',
      404: 'Server is unreachable. Please try again later.',
      403: 'Access forbidden',
    },
    boxErrors: {
      boxDoesntExist: "Box with barcode %{barcode} doesn't exist.",
      boxExists: 'Box with barcode %{barcode} is already used.',
      batteryDoesntExist: "Battery with barcode %{barcode} doesn't exist",
      batteryExists: 'Battery with barcode %{barcode} is already used.',
    },
    boxes: {
      assignedBoxes: 'Assigned Boxes:',
      filterBox: 'Filter boxes',
      boxWasCreated: 'Box was created',
    },
    batteries: {
      batteries: 'Batteries',
      scanId: 'Scan Battery Id',
    },
    cables: {
      cables: 'Cables',
      lightning: 'Lightning',
      pin: '30-Pin',
      microUsb: 'Micro USB',
      usbC: 'USB-C',
      return: 'Return',
    },
    shipmentBox: {
      summary: 'Summary',
      shipment: 'Shipment',
      to: 'To:',
      from: 'From:',
      shipmentDate: 'Shipment Date:',
      boxes: 'Boxes:',
      batteries: 'Batteries:',
      cables: 'Cables:',
      box: 'Box:',
      expirationDate: 'Expiration Date:',
      differentExpirationDates: 'Boxes have different expiration dates',
      expirationDateNotSet: 'Not set',
      newBattereId: 'Edit box id',
      logisticsSlipNrBoxes: 'Nr. boxes on Logistics slip',
      logisticsSlipCode: 'Logistics slip code',
      getBoxesCSV: 'Export Boxes',
      getBatteriesCSV: 'Export Batteries',
    },
    newBox: {
      addNewBox: 'Add new box',
      boxWasSuccessfullyCreated: 'Box was successfully created',
      boxId: 'Box id: #',
      ofBatteries: ' of batteries',
      ofCables: ' # of cables ',
      location: 'Location',
      shipmentDate: 'Shipment Date',
      save: 'Save',
      cablesReturn: 'Cables return',
      '7days': '7Days / external id',
    },
    invoice: {
      number: 'Number',
      type: 'Invoice type',
      billingPeriod: 'Billing period',
      invoiceDate: 'Invoice date',
      dueDate: 'Due date',
      status: 'Status',
      without_deposit: 'Without deposit',
      from_deposit: 'From Deposit',
      withoutDeposit: 'Without deposit: CHF',
      fromDeposit: 'From deposit: CHF',
      generateNewRecords: 'Generate new records',
      generateInvoicesAndReports: 'Generate invoices & reports',
      amount: 'Amount',
      generationNotification: 'Your invoices/reports are being generated.' +
        ' Please reload this page in a few minutes.'
    },
    addNewMockRental: {
      rentalDate: 'Rental Date',
      location: 'Locations',
      type: 'Type',
      lateFee: 'No late fee?',
      addmockRental: 'Add a mock rental',
      battery: 'Battery',
    },
    goodsOrder: {
      addNewOrder: 'Add a new order',
      editOrder: 'Edit order',
      partnerName: 'Partner',
      partnerOrderID: 'Order ID',
      orderDate: 'Order date',
      deliveryDateTime: 'Delivery date and time',
      confirmed: 'Confirmed',
      planned: 'Planned',
      ready: 'Ready',
      executed: 'Executed',
      quantity: 'Quantity',
      unit: 'Unit',
      consolidateOrder: 'Consolidate order',
      deconsolidateOrder: 'Deconsolidate order',
      deliverOrder: 'Mark order as delivered',
    },
    assignShipmentsToGoodsOrder: {
      title: 'Assign shipments to order',
    },
    allRentals: {
      oneYearAgo: 'Last 12 months',
      thisWeek: 'This week',
      lastWeek: 'Last week',
      thisMonth: 'This month',
      lastMonth: 'Last month',
      all: 'All',
      pickup: 'Pickup',
      return: 'Return',
      anonymous: 'Anonymous',
      registered: 'Registered',
      succeeded: 'Succeeded',
      attempted: 'Attempted',
      canceled: 'Canceled',
      mock: 'Mock',
      totalPickups: 'Total pickups',
      totalReturns: 'Total returns',
      date: 'Date',
      type: 'Rental Type',
      user: 'User',
      status: 'Rental Status',
      valoraCH: 'Valora CH',
      festivalsCH: 'Chimpy Festivals',
      valoraDE: 'Valora DE',
      other_pos: 'Other PoS',
    },
    global: {
      searchButtonText: 'Search',
      ifAvailable: '(if available)',
    },
    shortcuts: {
      newBox: 'Add box (⌘ + B)',
    },
  },
};

export default Translations;
