import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

const ShipmentCompactTableHeader = ({ isOrderConsolidated }) => (
  <div className="table__header">
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.id')}</div>
      </div>
    </div>
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.shippingDate')}</div>
      </div>
    </div>
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.expirationDate')}</div>
      </div>
    </div>
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.daysBeforeExpiration')}</div>
      </div>
    </div>
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.boxes')}</div>
      </div>
    </div>
    <div className="table__header-element">
      <div className="table__header__content">
        <div className="table__header__content__text">{I18n.t('bananaTableHeader.batteries')}</div>
      </div>
    </div>
    {!isOrderConsolidated && <div className="table__header-element">{I18n.t('bananaTableHeader.actions')}</div>}
    {!isOrderConsolidated && (
      <div className="table__header-element">
        <div className="table__header__content">
          <div className="table__header__content__text">{I18n.t('bananaTableHeader.markForConsolidation')}</div>
        </div>
      </div>
    )}
  </div>
);
ShipmentCompactTableHeader.propTypes = {
  isOrderConsolidated: PropTypes.bool.isRequired,
};

export { ShipmentCompactTableHeader };
