import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

export default function TotalAmount({ withDeposit, fromDeposit }) {
  return (
    <div className="block block--padded">
      <h2 className="title title--medium">Total amount from sent, unpaid invoices</h2>
      <div>
        <Translate value="invoice.withoutDeposit" /> {withDeposit}
      </div>
      <div>
        <Translate value="invoice.fromDeposit" /> {fromDeposit}
      </div>
    </div>
  );
}

TotalAmount.propTypes = {
  withDeposit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fromDeposit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TotalAmount.defaultProps = {
  withDeposit: '',
  fromDeposit: '',
};
