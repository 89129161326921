import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewMockForm from 'components/presentational/new-mock-rental/new-mock-form';
import * as newMockRentalActions from './new-mock-rental-actions';

class NewMockRental extends Component {
  componentDidMount() {
    this.props.actions.sharedResetAppState();
    if (!this.props.types.length && !Object.keys(this.props.defaultLocation).length) {
      this.props.actions.getNewMockRental();
    }
  }

  render() {
    return (
      <div>
        <NewMockForm
          rentalDate={this.props.rentalDate}
          updateNewMockRentalDate={this.props.actions.updateNewMockRentalDate}
          updateNewMockType={this.props.actions.updateNewMockType}
          updateNewMockBattery={this.props.actions.updateNewMockBattery}
          types={this.props.types}
          choosenType={this.props.choosenType}
          battery={this.props.battery}
          defaultLocation={this.props.defaultLocation}
          lateFee={this.props.lateFee}
          changeNewMockLateFee={this.props.actions.changeNewMockLateFee}
          saveNewMock={this.props.actions.saveNewMockRental}
        />
      </div>
    );
  }
}

NewMockRental.propTypes = {
  rentalDate: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  choosenType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  battery: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultLocation: PropTypes.object.isRequired,
  lateFee: PropTypes.bool.isRequired,
};

NewMockRental.defaultProps = {
  battery: null,
};

function mapStateToProps({ newMockRentalData }) {
  return {
    rentalDate: newMockRentalData.rentalDate,
    battery: newMockRentalData.battery,
    types: newMockRentalData.types,
    choosenType: newMockRentalData.choosenType,
    defaultLocation: newMockRentalData.defaultLocation,
    lateFee: newMockRentalData.lateFee,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(newMockRentalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMockRental);
