import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { headerRoutes } from 'constants/routes';
import './styles/route-menu.scss';

const RouteMenu = (props) => {
  const showLogoutDialog = () =>
    props.sharedActions.toggleDialogWindow({
      isVisibleDialogWindow: true,
      applyFunc: props.sharedActions.removeLoginState,
      dialogTitle: I18n.t('dialog.dialogTitle'),
    });
  const moveRouteMenu = () => (props.sharedProps.isRouteMenuOpened ? 'route-menu--is-opened' : '');
  const showObscurer = () => (props.sharedProps.isRouteMenuOpened ? 'obscurer--visible' : '');
  const onToggleMenuState = () => props.sharedActions.toggleRouteMenuState(!props.sharedProps.isRouteMenuOpened);

  return (
    <div>
      <button className={`obscurer ${showObscurer()}`} onClick={onToggleMenuState} />
      <aside className={`route-menu ${moveRouteMenu()}`}>
        <div className="route-menu__link route-menu__link--banana">
          <span aria-label="banana" role="img">
            🍌
          </span>
        </div>
        <button className="route-menu__link" onClick={showLogoutDialog}>
          {I18n.t('routeMenu.logout')}
        </button>
        {headerRoutes[props.sharedProps.activeHeaderComponent].map((route) => (
          <Link className="route-menu__link" key={route.text} to={route.route}>
            {I18n.t(`routeMenu.${route.text}`)}
          </Link>
        ))}
      </aside>
    </div>
  );
};

RouteMenu.propTypes = {
  sharedActions: PropTypes.object.isRequired,
  sharedProps: PropTypes.object.isRequired,
};

export default RouteMenu;
