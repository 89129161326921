import standardRequest from 'helpers/backend-requests';
import { resetAppState, toggleLoading, setErrorMessage, renderNotification } from 'app-redux/actions/app-actions';
import download from 'downloadjs';
import { I18n } from 'react-redux-i18n';

export const sharedResetAppState = resetAppState;

function saveInvoices(invoices) {
  return {
    type: 'VALORA_INVOICES_ACTIONS_SAVE_INVOICES',
    invoices,
  };
}

function togglePaidStatus(paidBool) {
  return {
    type: 'VALORA_INVOICES_TOGGLE_PAID_STATUS',
    paidBool,
  };
}

function updateInvoice(id, status) {
  return {
    type: 'VALORA_INVOICES_UPDATE_INVOICE',
    id,
    status,
  };
}

export function getInvoices(paidBool) {
  let path;
  if (paidBool !== null && paidBool !== undefined) {
    path = `/valora_invoices?exclude_paid=${paidBool}`;
  } else {
    path = '/valora_invoices';
  }
  return function getInvoicesRequest(dispatch) {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveInvoices(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function togglePaidStatusRequest(paidBool) {
  return function togglePaid(dispatch) {
    dispatch(togglePaidStatus(paidBool));
    dispatch(getInvoices(paidBool));
  };
}

export function getValoraInvoicePdf(id, rmaBool, invoiceDate, invoiceYear, yearlyNumber) {
  let path;
  let filename;
  if (rmaBool !== undefined && rmaBool === true) {
    path = `/valora_invoices/${id}/download?rma=${rmaBool}`;
  } else {
    path = `/valora_invoices/${id}/download`;
  }
  return function getValoraInvoicePdfRequest(dispatch) {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    standardRequest(path, 'GET', null, null, 'blob')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          filename = `Chimpy Rechnung ${invoiceYear}-${yearlyNumber} (${invoiceDate})${rmaBool ? '_RunMyAccounts' : ''}.pdf`;
          download(response, filename, 'application/pdf');
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getValoraSalesReport(startDate, endDate, invoiceDate) {
  const path = `/valora_reporting/all_sales/?end_date=${endDate}&start_date=${startDate}`;
  return (dispatch) => {
    dispatch(setErrorMessage());
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET', null, null, 'blob')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          const filename = `VAL_CHIMPY_KD-RG_P_${invoiceDate}.csv`;
          download(response, filename, 'text/csv');
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getValoraTransactionReport(startDate, endDate, invoiceDate) {
  const path = `/valora_reporting/all_transactions/?end_date=${endDate}&start_date=${startDate}`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    standardRequest(path, 'GET', null, null, 'blob')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          const filename = `VAL_CHIMPY_PROV_P_${invoiceDate}.csv`;
          download(response, filename, 'text/csv');
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function updateValoraInvoice(id, status) {
  const path = `/valora_invoices/${id}`;
  const body = {
    status,
  };
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch(toggleLoading(true));
      dispatch(setErrorMessage());
      standardRequest(path, 'PATCH', null, body)
        .then((response) => {
          if (response.error) {
            dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
          } else {
            dispatch(updateInvoice(id, status));
            dispatch(renderNotification('Invoice Updated'));
          }
          resolve();
        })
        .catch(() => {
          dispatch(setErrorMessage(I18n.t('errorCode.404')));
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    });
}

export function removePaidInvoices() {
  return {
    type: 'VALORA_INVOICES_REMOVE_PAID_INVOICES',
  };
}

export function generateValoraInvoices(paidBool) {
  const path = '/valora_invoices';
  return (dispatch) => {
    dispatch(toggleLoading(true));
    dispatch(setErrorMessage());
    standardRequest(path, 'POST', {}, {})
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(renderNotification(I18n.t('invoice.generationNotification')))
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
