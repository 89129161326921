import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { TabFocus } from 'banana-framework-v2';
import { BananaCheckbox, BananaInput, BananaRadiobutton, BananaButton
} from 'banana-framework';

import './boxes-manipulator.css';

export class BoxesManipulator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      radio: 'both',
      isLoading: false,
    };

    this.filterBoxes = this.filterBoxes.bind(this);
    this.switchRadioState = this.switchRadioState.bind(this);
    this.openBoxFunc = this.openBoxFunc.bind(this);
    this.selectAllBoxes = this.selectAllBoxes.bind(this);
  }

  filterBoxes(filter) {
    this.setState({ filter });
  }

  selectAllBoxes() {
    this.props.assignedBoxes.forEach(b => {
      if (!b.checked) { this.openBoxFunc(b.id) }
    })
  }

  switchRadioState(radio) {
    this.setState({ radio });
  }

  openBoxFunc(boxId) {
    this.setState({ isLoading: true }, () => {
      try {
        this.props.openBoxFunc(boxId);
      } finally {
        this.setState({ isLoading: false });
      }
    });
  }

  renderAvailableBoxes() {
    if (this.state.radio === 'both') {
      return this.props.assignedBoxes.map((box) => (
        <div
          key={box.id}
          className={`box-manipulator__checkbox ${
            box.id.toString().indexOf(this.state.filter) < 0 ? 'box-manipulator__checkbox--not-visible' : ''
          }`}
        >
          <BananaCheckbox
            checked={box.checked}
            value={box.id}
            checkedFunc={this.openBoxFunc}
            uncheckedFunc={this.props.closeBoxFunc}
          />
        </div>
      ));
    } else if (this.state.radio === 'active') {
      return this.props.assignedBoxes.map((box) => {
        if (box.checked) {
          return (
            <div
              key={box.id}
              className={`box-manipulator__checkbox ${
                box.id.toString().indexOf(this.state.filter) < 0 ? 'box-manipulator__checkbox--not-visible' : ''
              }`}
            >
              <BananaCheckbox
                checked={box.checked}
                value={box.id}
                checkedFunc={this.openBoxFunc}
                uncheckedFunc={this.props.closeBoxFunc}
              />
            </div>
          );
        }
        return null;
      });
    }
    return null;
  }

  render() {
    return (
      <div className={`block block--padded box-manipulator ${this.state.isLoading ? 'element--is-loading' : ''}`}>
        <h2 className="title title--medium">{I18n.t('boxes.assignedBoxes')}</h2>
        <div className="box-manipulator__filter">
          <BananaInput
            text={I18n.t('boxes.filterBox')}
            onChangeAction={this.filterBoxes}
            filter="numeric"
            onRefAdd={(ref) => {
              TabFocus.getInstance().add('filterBoxes', ref);
              return () => {
                TabFocus.getInstance().remove('filterBoxes');
              };
            }}
          />
          <div className="box-manipulator__radiobox">
            <BananaRadiobutton
              checked={this.state.radio === 'both'}
              name="boxes-filter"
              value="both"
              onChange={() => {
                this.switchRadioState('both');
              }}
            >
              All
            </BananaRadiobutton>
            <BananaRadiobutton
              checked={this.state.radio === 'active'}
              name="boxes-filter"
              value="active"
              onChange={() => {
                this.switchRadioState('active');
              }}
            >
              Active
            </BananaRadiobutton>
          </div>
        </div>
        <div>{this.renderAvailableBoxes()}</div>

        <div className="box-manipulator__select_all">
          { this.props.assignedBoxes.length > 1 &&
            <BananaButton onClick={this.selectAllBoxes}>
              Select All
            </BananaButton>
          }
        </div>

      </div>
    );
  }
}

BoxesManipulator.propTypes = {
  assignedBoxes: PropTypes.array.isRequired,
  openBoxFunc: PropTypes.func.isRequired,
  closeBoxFunc: PropTypes.func.isRequired,
};
