export const headerRoutes = {
  operations: [
    {
      text: 'shipmentsOverview',
      route: '/shipments',
    },
    {
      text: 'addDeliveryShipment',
      route: '/shipment/create/delivery',
    },
    {
      text: 'addReturnShipmnent',
      route: '/shipment/create/return',
    },
    {
      text: 'goodsOrdersOverview',
      route: '/goods_orders',
    },
    {
      text: 'addGoodsOrder',
      route: '/goods_orders/new',
    },
    {
      text: 'assignShipmentsToGoodsOrder',
      route: '/goods_orders/assign_shipments',
    },
  ],
  finance: [
    {
      text: 'valoraInvoices',
      route: '/valora_invoices/',
    },
  ],
  customerSupport: [
    {
      text: 'addMockRental',
      route: '/rentals/new/mock',
    },
  ],
  analytics: [
    {
      text: 'allRentals',
      route: '/rentals',
    },
  ],
};

export const defaultHeaderRoute = 'operations';
