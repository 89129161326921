import initialState from 'app-redux/reducers/initial-state';

const NewMockRentalReducer = (state = initialState.newMockRentalData, action) => {
  switch (action.type) {
    case 'NEW_MOCK_RENTAL_SAVE_NEW_INFO':
      return {
        ...state,
        types: action.types,
        defaultLocation: action.defaultLocation,
        choosenType: action.choosenType,
      };
    case 'NEW_MOCK_RENTAL_UPDATE_DATE':
      return { ...state, rentalDate: action.rentalDate };
    case 'NEW_MOCK_RENTAL_UPDATE_TYPE':
      return { ...state, choosenType: action.choosenType };
    case 'NEW_MOCK_RENTAL_UPDATE_BATTERY':
      return { ...state, battery: action.battery };
    case 'NEW_MOCK_RENTAL_CHANGE_NEW_MOCK_LATE_FEE':
      return { ...state, lateFee: action.lateFee };
    case 'NEW_MOCK_RENTAL_RESET_NEW_MOCK_STATE':
      return { ...initialState.newMockRentalData };
    default:
      return state;
  }
};

export default NewMockRentalReducer;
