import initialState from 'app-redux/reducers/initial-state';

const newBoxDataReducer = (state = initialState.newBoxIdData, action) => {
  switch (action.type) {
    case 'SAVE_NEW_RETURN_BOX_ID':
      return {
        ...state,
        newBoxId: action.id,
      };
    case 'SAVE_NEW_RETURN_BOX_LOCATIONS':
      return {
        ...state,
        locations: {
          locations: action.locations,
        },
      };
    case 'UPDATE_NEW_RETURN_BOX_CABLES':
      return {
        ...state,
        cablesData: {
          ...state.cablesData,
          [action.cableKey]: action.cableValue,
        },
      };
    case 'ADD_NEW_RETURN_BOX_BATTERY_ID':
      return {
        ...state,
        batteryIds: [...state.batteryIds, action.newBatteryId],
      };
    case 'REMOVE_NEW_RETURN_BOX_BATTERY_ID':
      // this `[ ...stuff ]` ensures next ref is new (immutability)
      return {
        ...state,
        batteryIds: [...state.batteryIds.filter((batteryId) => action.batteryId !== batteryId)],
      };
    case 'CHANGE_NEW_RETURN_BOX_SHIPMENT_DATE':
      return {
        ...state,
        shipmentDate: action.shipmentDate,
      };
    case 'CHOOSE_NEW_RETURN_BOX_LOCATION':
      return {
        ...state,
        choosenLocationId: action.choosenLocationId,
      };
    case 'RESET_NEW_RETURN_BOX_STATE':
      return {
        ...initialState.newBoxIdData,
      };
    default:
      return state;
  }
};
export default newBoxDataReducer;
