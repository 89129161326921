import React from 'react';
import PropTypes from 'prop-types';

export default function BananaRadiobutton(props) {
  return (
    <label className="banana-radio-button" htmlFor={`${props.value}${props.name}`}>
      {props.checked && <i className="fa fa-dot-circle-o banana-radio-button__mark" aria-hidden="true" />}
      {!props.checked && <i className="fa fa-circle-o banana-radio-button__mark" aria-hidden="true" />}
      <input
        id={`${props.value}${props.name}`}
        checked={props.checked}
        type="radio"
        name={props.name}
        onChange={props.onChange}
      />
      {props.children}
    </label>
  );
}

BananaRadiobutton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

BananaRadiobutton.defaultProps = {
  checked: false,
};
