/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaEmoji } from 'banana-framework';

class AllRentalsTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: 'pickups',
      toggleText: 'returns',
    };
    this.togglePickupsReturns = this.togglePickupsReturns.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  emojiFromCountryCode(code) {
    let emoji;
    switch (code) {
      case 'CH':
        emoji = <BananaEmoji symbol="🇨🇭" />;
        break;
      case 'DE':
        emoji = <BananaEmoji symbol="🇩🇪" />;
        break;
      case 'FR':
        emoji = <BananaEmoji symbol="🇫🇷" />;
        break;
      case 'AT':
        emoji = <BananaEmoji symbol="🇦🇹" />;
        break;
      case 'IT':
        emoji = <BananaEmoji symbol="🇮🇹" />;
        break;
      default:
        emoji = <BananaEmoji symbol="❓" />;
    }
    return emoji;
  }

  // eslint-disable-next-line class-methods-use-this
  humanize(number) {
    return Number(number).toLocaleString('de-CH');
  }

  togglePickupsReturns() {
    const oldState = this.state.toggled;
    const newState = this.state.toggleText;
    this.setState({
      toggled: newState,
      toggleText: oldState,
    });
  }

  render() {
    if (!this.props.stats.countries || !this.props.stats.global) {
      return <div />;
    }
    const pickups_toggled = this.state.toggled === 'pickups' ? '' : 'hidden';
    const returns_toggled = this.state.toggled === 'returns' ? '' : 'hidden';
    const countriesToday = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.today.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.today.returns)}
        </div>
      </React.Fragment>
    ));
    const countriesYesterday = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.yesterday.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.yesterday.returns)}
        </div>
      </React.Fragment>
    ));
    const countriesThisWeek = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.thisWeek.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.thisWeek.returns)}
        </div>
      </React.Fragment>
    ));
    const countriesLastWeek = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.lastWeek.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.lastWeek.returns)}
        </div>
      </React.Fragment>
    ));
    const countriesThisMonth = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.thisMonth.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.thisMonth.returns)}
        </div>
      </React.Fragment>
    ));
    const countriesLastMonth = this.props.stats.countries.map((country) => (
      <React.Fragment key={country.code}>
        <div>{this.emojiFromCountryCode(country.code)}</div>
        <div className={`all-rentals-grid-country-totals all-rentals-pickups ${pickups_toggled}`}>
          {this.humanize(country.lastMonth.pickups)}
        </div>
        <div className={`all-rentals-grid-country-totals all-rentals-returns ${returns_toggled}`}>
          {this.humanize(country.lastMonth.returns)}
        </div>
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <div className="all-rentals-toggle-pickup-returns">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a role="button" onKeyPress={() => {}} tabIndex={0} onClick={this.togglePickupsReturns}>
            Show {this.state.toggleText} stats
          </a>
        </div>
        <div className="meta-block">
          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">Today</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.today.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.today.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesToday}</div>
            </div>
          </div>

          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">This week</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.thisWeek.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.thisWeek.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesThisWeek}</div>
            </div>
          </div>

          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">This month</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.thisMonth.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.thisMonth.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesThisMonth}</div>
            </div>
          </div>
        </div>

        <div className="meta-block">
          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">Yesterday</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.yesterday.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.yesterday.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesYesterday}</div>
            </div>
          </div>

          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">Last week</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.lastWeek.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.lastWeek.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesLastWeek}</div>
            </div>
          </div>

          <div className="block block--padded block--6">
            <div className="all-rentals-grid">
              <div className="all-rentals-grid-header">
                <div className="title">Last month</div>
              </div>
              <div className="all-rentals-grid-totals">
                <span className={`all-rentals-pickups ${pickups_toggled}`}>
                  {this.humanize(this.props.stats.global.lastMonth.pickups)}
                </span>
                <span className={`all-rentals-returns ${returns_toggled}`}>
                  {this.humanize(this.props.stats.global.lastMonth.returns)}
                </span>
              </div>
              <div className="all-rentals-grid-countries">{countriesLastMonth}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AllRentalsTotal.propTypes = {
  stats: PropTypes.object.isRequired,
};
export default AllRentalsTotal;
