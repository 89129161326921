import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const BananaV2OptionsElement = (props) => {
  const inputRef = React.createRef();
  const [isEditable, setIsActive] = useState(props.editable);

  const [isFocused, setIsFocused] = useState(props.focus);
  // const [showOptions, setShowOptions] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const openTimeOutId = React.useRef();

  const timeOutBlurId = React.useRef();

  const [shiftTab, setShiftTab] = useState(false);
  const shiftTimeOutId = React.useRef();

  const [value, setValue] = useState(props.value);
  // eslint-disable-next-line no-unused-vars
  const [currentOption, setCurrentOption] = useState(null);

  const init = async () => {
    const selectedOption = props.options ? props.options.filter((o) => o.label === value) : null;
    if (selectedOption) setCurrentOption(selectedOption);
  };

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (inputRef.current && isFocused) inputRef.current.focus();
  }, [inputRef, isFocused]);

  const start = () => {
    setIsActive(true);
    setIsFocused(true);
    setIsOpen(true);
  };

  const finish = () => {
    setIsActive(false);
    setIsFocused(false);
    setIsOpen(false);
  };

  // on mount
  useEffect(() => {
    init();

    return () => {
      if (timeOutBlurId.current) clearTimeout(timeOutBlurId.current);
      if (shiftTimeOutId.current) clearTimeout(shiftTimeOutId.current);
      if (openTimeOutId.current) clearTimeout(openTimeOutId.current);
    };
  }, []);

  useEffect(() => {
    if (shiftTab)
      shiftTimeOutId.current = setTimeout(() => {
        setShiftTab(false);
      }, 300);
  }, [shiftTab]);

  const callCallback = () => {
    setTimeout(() => {
      if (props.callback && !shiftTab) props.callback();
    });
  };

  /**
   * @implNote input blur cannot happen right away, as it will block the options click from happening
   */
  const onInputBlurHandler = () => {
    callCallback();
    setIsActive(false);
    setIsFocused(false);
    openTimeOutId.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (props.editable) start();
  }, [props.editable]);

  const handleChange = (evt) => {
    if (value !== evt.target.value) {
      setValue(evt.target.value);
      props.onChange(evt.target.value);
      setIsOpen(true);
    }
  };

  const [opt, setOpt] = useState(0);

  const moveUp = () => {
    if (props.options && props.options.length) {
      if (opt === 0) setOpt(props.options.length - 1);
      else setOpt(opt - 1);
    }
  };
  const moveDown = () => {
    if (props.options && props.options.length) {
      if (opt >= props.options.length - 1) setOpt(0);
      else setOpt(opt + 1);
    }
  };

  const setProperOption = (callback) => {
    const newOption = props.options[opt];
    if (newOption) {
      if (callback) callback(newOption);
    }
  };

  const keyPressHandler = (evt) => {
    switch (evt.key) {
      case 'Tab':
        if (evt.shiftKey) {
          setShiftTab(true);
          break;
        }
        evt.preventDefault();
        evt.stopPropagation();
        if (props.onBlur) setProperOption(props.onBlur);
        callCallback();
        finish();
        break;
      case 'Enter':
        if (props.onSubmitEditing) setProperOption(props.onSubmitEditing);
        callCallback();
        finish();
        break;
      case 'ArrowUp':
        moveUp();
        break;
      case 'ArrowDown':
        moveDown();
        break;
      case 'Escape':
        if (inputRef.current) inputRef.current.blur();
        callCallback();
        finish();
        break;
      default:
        break;
    }
  };

  const returnIfTextExists = () => (isFocused || value ? 'banana-input--text-exists' : '');

  const getInputClasses = () =>
    `banana-input ${isFocused ? 'banana-input--focused' : ''} ${returnIfTextExists()} ${props.inputClass}`;

  const chooseOption = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSubmitEditing) props.onSubmitEditing(option);
    finish();
  };

  // const onParentBlurHandler = () => {
  //   timeOutBlurId.current = setTimeout(() => {
  //     setIsOpen(false);
  //   }, 200);
  // };
  // // If a child receives focus, do not close the popover.
  // const onParentFocusHandler = () => {
  //   if (timeOutBlurId.current) clearTimeout(timeOutBlurId.current);
  // };

  const optionRef = React.createRef();
  useEffect(() => {
    if (optionRef && optionRef.current) {
      optionRef.current.scrollIntoView();
    }
  }, [opt]);

  const renderOptions = () => {
    if (isOpen && props.options.length > 0)
      return (
        <div className="banana-input__options" onBlur={finish}>
          {props.options.map((option, index) => (
            <div
              key={option.id}
              role="option"
              aria-selected
              aria-hidden
              onClick={(event) => chooseOption(event, option)}
              className={`banana-input__options__option ${opt === index ? 'active' : ''}`}
              ref={(ref) => {
                if (opt === index) optionRef.current = ref;
              }}
            >
              {`${option.label}${option.code ? ` | ${option.code}` : ''}`}
            </div>
          ))}
        </div>
      );
    return null;
  };

  return (
    <div style={props.style}>
      {!isEditable && props.pencil && (
        <React.Fragment>
          <div className="shipment-cell__child__text">{props.value}</div>
          <i className="fa fa-pencil shipment-cell__edit-button" aria-hidden="true" onClick={start} />
        </React.Fragment>
      )}

      {(isEditable || !props.pencil) && (
        <div className="box-details__cable__cell" style={{ width: '100%', display: 'block' }}>
          <div className={getInputClasses()} style={{ width: '100%' }}>
            <input
              style={{ cursor: !props.pencil && !isEditable ? 'pointer' : undefined }}
              value={value}
              focus={props.focus}
              type="text"
              onKeyDown={keyPressHandler}
              onFocus={start}
              onBlur={onInputBlurHandler}
              onChange={handleChange}
              disabled={props.pencil && !isEditable}
              ref={inputRef}
              onClick={() => {
                if (!props.pencil) start();
              }}
            />
            {renderOptions()}
            <div className="banana-input__text">{props.label}</div>
          </div>
          {props.pencil && <i className="fa fa-check shipment-cell__edit-button" aria-hidden="true" onClick={finish} />}
        </div>
      )}
    </div>
  );
};

BananaV2OptionsElement.propTypes = {
  label: PropTypes.string.isRequired,
  // onSubmit
  onSubmitEditing: PropTypes.func.isRequired,
  // optional
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputClass: PropTypes.string,
  focus: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  pencil: PropTypes.bool,
  // optional functions
  onBlur: PropTypes.func,
  callback: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      code: PropTypes.string,
    })
  ),
};
BananaV2OptionsElement.defaultProps = {
  value: '',
  inputClass: '',
  focus: false,
  style: {},
  editable: false,
  pencil: false,
  onBlur: () => {},
  callback: () => {},
  onChange: () => {},
  options: [],
};
