export class TabFocus {
  private static instance: TabFocus;

  private references = new Map<string, HTMLElement>();

  private constructor() {
    if (TabFocus.instance) throw new Error('Error - use TabFocus.getInstance()');
  }

  public static getInstance() {
    TabFocus.instance = TabFocus.instance || new TabFocus();
    return TabFocus.instance;
  }

  public focus(referenceName: string) {
    const ref = this.references.get(referenceName);
    if (ref && ref.focus) {
      ref.focus();
    } else {
      // eslint-disable-next-line no-console
      console.log('Focus: No reference with name ', referenceName);
    }
  }

  public add(referenceName: string, newRef: HTMLElement) {
    this.references.set(referenceName, newRef);
  }

  public remove(referenceName: string) {
    const ref = this.references.get(referenceName);
    if (ref) this.references.delete(referenceName);
    else {
      // eslint-disable-next-line no-console
      console.log('Remove: No reference with name ', referenceName);
    }
  }

  public debug() {
    console.log('Key: ', Array.from(this.references.keys()));
    console.log('Values: ', Array.from(this.references.values()));
  }
}
