import { useEffect } from 'react';

/**
 * Shortcuts
 * @implNote currently only allows for 1 or 2 simultaneously pressed keys.
 */
export const Shortcuts = {
  create: (shortcuts) => (event) =>
    shortcuts.forEach((shortcut) => {
      if (event.key === shortcut.key) {
        // CTRL OR META (both acepted)
        if (shortcut.ctrlOrMeta && (event.ctrlKey || event.metaKey)) shortcut.action();
        // CTRL
        if (shortcut.ctrl && event.ctrlKey) shortcut.action();
        // META
        else if (shortcut.meta && event.metaKey) shortcut.action();
        // SHIFT
        else if (shortcut.shift && event.shiftKey) shortcut.action();
        // ALT (OPT)
        else if (shortcut.alt && event.altKey) shortcut.action();
        // iff all CTRL SHIFT and OPT is false, we probably have a simple key shortcut
        else if (!shortcut.ctrl === undefined && !shortcut.shift === undefined && !shortcut.alt === undefined) shortcut.action();
      }
    }),
};

/**
 * Use Shortcuts is a hook designed to help you create shortcuts quickly
 * <code>
 *    const screenXShortcuts = APP_SHORTCUTS.screenX({ toggle: flag ? close : open });
 *    useShortcuts(Shortcuts.create(screenXShortcuts), [flag]);
 * </code>
 *
 * @param {VoidFunction} callback function to execute on event
 * @param {Props[]} deps prop array to whatch for changes (otherwise the callback doesn't update)
 */
export const useShortcuts = (callback, deps) =>
  useEffect(() => {
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, deps);
