import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';

export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.getValoraInvoicePdf = this.getValoraInvoicePdf.bind(this);
    this.getValoraInvoicePdfRMA = this.getValoraInvoicePdfRMA.bind(this);
    this.getValoraSalesReport = this.getValoraSalesReport.bind(this);
    this.getValoraTransactionReport = this.getValoraTransactionReport.bind(this);
    this.setInvoiceToSent = this.setInvoiceToSent.bind(this);
    this.setInvoiceToPrefinanced = this.setInvoiceToPrefinanced.bind(this);
    this.setInvoiceToPaid = this.setInvoiceToPaid.bind(this);
  }

  getInvoiceDate() {
    const invoiceDate = moment(this.props.endBillingPeriod).add(1, 'days').format('YYYYMMDD');
    const invoiceYear = moment(this.props.endBillingPeriod).add(1, 'days').format('YYYY');
    return { invoiceDate, invoiceYear };
  }

  getValoraInvoicePdf() {
    const { invoiceDate, invoiceYear } = this.getInvoiceDate();
    this.props.getValoraInvoicePdf(`${this.props.id}`, false, invoiceDate, invoiceYear, `${this.props.yearlyNumber}`);
  }

  getValoraInvoicePdfRMA() {
    const { invoiceDate, invoiceYear } = this.getInvoiceDate();
    this.props.getValoraInvoicePdf(`${this.props.id}`, true, invoiceDate, invoiceYear, `${this.props.yearlyNumber}`);
  }

  getValoraSalesReport() {
    const { invoiceDate } = this.getInvoiceDate();
    this.props.getValoraSalesReport(
      moment(this.props.startBillingPeriod).format('YYYY-MM-DD'),
      moment(this.props.endBillingPeriod).format('YYYY-MM-DD'),
      invoiceDate
    );
  }

  getValoraTransactionReport() {
    const { invoiceDate } = this.getInvoiceDate();
    this.props.getValoraTransactionReport(
      moment(this.props.startBillingPeriod).format('YYYY-MM-DD'),
      moment(this.props.endBillingPeriod).format('YYYY-MM-DD'),
      invoiceDate
    );
  }

  setInvoiceToSent() {
    this.setState({ isLoading: true }, () => {
      this.props.updateValoraInvoice(this.props.id, 'sent', this.props.paidBool).then(() => {
        this.setState({ isLoading: false }, () => {
          this.removePaidInvoices();
        });
      });
    });
  }

  setInvoiceToPrefinanced() {
    this.setState({ isLoading: true }, () => {
      this.props.updateValoraInvoice(this.props.id, 'prefinanced', this.props.paidBool).then(() => {
        this.setState({ isLoading: false }, () => {
          this.removePaidInvoices();
        });
      });
    });
  }

  setInvoiceToPaid() {
    this.setState({ isLoading: true }, () => {
      this.props.updateValoraInvoice(this.props.id, 'paid', this.props.paidBool).then(() => {
        this.setState({ isLoading: false }, () => {
          this.removePaidInvoices();
        });
      });
    });
  }

  removePaidInvoices() {
    if (this.props.paidBool === true || this.props.paidBool === null) {
      this.props.removePaidInvoices();
    }
  }

  render() {
    return (
      <div className={`invoice__row ${this.state.isLoading ? 'element--is-loading-nb' : ''}`}>
        <div className="invoice__cell">
          {this.props.year} / {this.props.yearlyNumber}
        </div>
        <div className="invoice__cell">
          <Translate value={`invoice.${this.props.type}`} />
        </div>
        <div className="invoice__cell">
          {moment(this.props.startBillingPeriod).format('DD.MM.YYYY')} -
          <div>{moment(this.props.endBillingPeriod).format('DD.MM.YYYY')}</div>
        </div>
        <div className="invoice__cell">{this.props.amount}</div>
        <div className="invoice__cell">{moment(this.props.invoiceDate).format('DD.MM.YYYY')}</div>
        <div className="invoice__cell">
          {moment(this.props.dueDate).format('DD.MM.YYYY')} - {this.props.tage}
        </div>
        <div className="invoice__cell">{this.props.status}</div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.getValoraInvoicePdf}>
            PDF
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.getValoraInvoicePdfRMA}>
            RMA
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.getValoraSalesReport}>
            KD-RG Report
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.getValoraTransactionReport}>
            PROV Report
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.setInvoiceToSent} title="Set to sent">
            <i className="fa fa-chevron-right" aria-hidden="true" />
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.setInvoiceToPrefinanced} title="Set to pre-financed">
            <i className="fa fa-credit-card" aria-hidden="true" />
          </button>
        </div>
        <div className="invoice__cell">
          <button className="invoice__download-button" onClick={this.setInvoiceToPaid} title="Set to paid">
            <i className="fa fa-usd" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {
  id: PropTypes.number.isRequired,
  yearlyNumber: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  startBillingPeriod: PropTypes.string.isRequired,
  endBillingPeriod: PropTypes.string.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  paidBool: PropTypes.bool,
  getValoraInvoicePdf: PropTypes.func.isRequired,
  getValoraSalesReport: PropTypes.func.isRequired,
  getValoraTransactionReport: PropTypes.func.isRequired,
  updateValoraInvoice: PropTypes.func.isRequired,
  removePaidInvoices: PropTypes.func.isRequired,
  tage: PropTypes.string,
  amount: PropTypes.string,
};

Invoice.defaultProps = {
  paidBool: null,
  tage: '',
  amount: '',
};
