export function checkLoginState() {
  if (localStorage.getItem('idToken')) return 'main';
  return 'login';
}

export function setLoginTokens(tokens) {
  localStorage.setItem('idToken', tokens.idToken);
  localStorage.setItem('refreshToken', tokens.refreshToken);
  localStorage.setItem('accessToken', tokens.accessToken);
}

export function removeLoginTokens() {
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('tokenSaveTime');
  localStorage.setItem('refreshToken');
}
