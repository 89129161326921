import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';

import { ShipmentLabel, shipmentStore } from 'components/containers/shipment/shipment-data';
import newBoxData from 'components/containers/new-box/new-box-data';
import shipmentsData from 'components/containers/shipments/shipments-data';
import newMockRentalData from 'components/containers/new-mock-rental/new-mock-rental-data';
import goodsOrdersOverviewData from 'components/containers/goods-orders-overview/goods-orders-overview-data';
import goodsOrderData from 'components/containers/new-edit-goods-order/goods-order-data';
// eslint-disable-next-line max-len
import assignShipmentsToGoodsOrderData from 'components/containers/assign-shipments-to-goods-order/assign-shipments-to-goods-order-data';
import valoraInvoicesData from 'components/containers/valora-invoices/valora-invoices-data';
import allRentalsData from 'components/containers/all-rentals/all-rentals-data';
import loginData from 'components/containers/login-container/login-container-data';
import appData from './app-data';

const rootReducer = combineReducers({
  [ShipmentLabel.STATE]: shipmentStore.getReducer(),
  // TODO: move these as Labels get moved from initial-state
  shipmentsData,
  appData,
  newBoxData,
  newMockRentalData,
  goodsOrdersOverviewData,
  goodsOrderData,
  assignShipmentsToGoodsOrderData,

  i18n: i18nReducer,
  valoraInvoicesData,
  allRentalsData,
  loginData,
});

export default rootReducer;
