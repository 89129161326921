import React from 'react';
import PropTypes from 'prop-types';
import { BananaRadiobutton } from 'banana-framework';

const BananaMultiswitcher = (props) => (
  <div className="banana-multiswitcher__wrapper">
    <div className="banana-multiswitcher__title">{props.title}</div>
    {props.switcherContent.map((content) => (
      <div className="all-rentals__date-switcher__element" key={`${content.value}`}>
        <BananaRadiobutton
          onChange={() => {
            props.onChange(content.value);
          }}
          checked={content.value === props.selectedValue}
          name={props.name}
          value={content.value}
        >
          {content.text}
        </BananaRadiobutton>
      </div>
    ))}
  </div>
);

BananaMultiswitcher.propTypes = {
  selectedValue: PropTypes.any,
  title: PropTypes.any,
  switcherContent: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

BananaMultiswitcher.defaultProps = {
  title: null,
  switcherContent: [],
  selectedValue: null,
};

export default BananaMultiswitcher;
