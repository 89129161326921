import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Datetime from 'react-datetime';

export default class BananaDatepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.props.dataChange(date);
  }

  returnTextModificator() {
    if (this.props.text) return 'banana-input--text-exists';
    return null;
  }

  renderAdditionalText() {
    if (this.props.text) return <div className="banana-input__text">{this.props.text}</div>;
    return null;
  }

  renderPicker() {
    if (this.props.withTime) {
      return (
        <Datetime
          value={this.props.startDate.format(`${this.props.dateFormat} ${this.props.timeFormat}`)}
          onChange={this.handleChange}
          dateFormat={this.props.dateFormat}
          onFocus={() => {
            this.setState({ focused: true });
          }}
          onBlur={() => {
            this.setState({ focused: false });
          }}
          className="banana-input__text-picker"
          timeFormat={this.props.timeFormat}
          utc
        />
      );
    }
    return <DatePicker dateFormat={this.props.dateFormat} selected={this.props.startDate} onChange={this.handleChange} />;
  }

  render() {
    return (
      <div className={`banana-input ${this.returnTextModificator()} ${this.state.focused ? 'banana-input--focused' : ''}`}>
        {this.renderPicker()}
        {this.renderAdditionalText()}
      </div>
    );
  }
}

BananaDatepicker.propTypes = {
  startDate: PropTypes.object.isRequired,
  dataChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateFormat: PropTypes.string,
  withTime: PropTypes.bool,
  timeFormat: PropTypes.string,
};

BananaDatepicker.defaultProps = {
  text: null,
  withTime: false,
  dateFormat: 'DD/MM/YYYY',
  timeFormat: 'HH:mm',
};
