import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const BananaV2InputElement = (props) => {
  const inputRef = React.createRef();
  const [isEditable, setIsEditable] = useState(props.editable);
  const [value, setValue] = useState(props.value);
  const [isFocused, setIsFocused] = useState(props.focus);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const cleanUpRef = React.createRef();

  useEffect(() => {
    // add ref cleanup
    return () => {
      if (cleanUpRef.current) cleanUpRef.current();
    };
  }, []);

  // on props.value change, update value
  useEffect(() => {
    if (value !== props.value) setValue(props.value);
  }, [props]);

  // Placeholder logic
  useEffect(() => {
    if (isEditable || isFocused) setShowPlaceholder(false);
    else if (value === '') setShowPlaceholder(true);
    else setShowPlaceholder(false);
  }, [value, isEditable, isFocused]);

  useEffect(() => {
    if (inputRef.current) {
      cleanUpRef.current = props.onRefAdd(inputRef.current);
      if (isFocused) inputRef.current.focus();
    } else {
      cleanUpRef.current = null;
    }
  }, [inputRef, isFocused, props.onRefAdd]);

  const start = (evt) => {
    setIsEditable(true);
    setIsFocused(true);
    if (evt && evt.currentTarget && evt.currentTarget.select) evt.currentTarget.select();
  };

  const finish = () => {
    setIsEditable(false);
    setIsFocused(false);
  };

  const onInputBlurHandler = () => {
    setIsFocused(false);
    // we need to delay blur and setIsEditable
    setTimeout(() => {
      setIsEditable(false);
    }, 100);
  };

  const callCallback = () => {
    setTimeout(() => {
      if (props.callback) props.callback();
    });
  };

  useEffect(() => {
    if (props.editable) start();
  }, [props.editable]);

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  const submit = () => {
    if (props.onSubmitEditing) props.onSubmitEditing(value);
    callCallback();
    finish();
  };

  const keyPressHandler = (evt) => {
    switch (evt.key) {
      case 'Tab':
        if (evt.shiftKey) break;
        evt.preventDefault();
        evt.stopPropagation();
        if (props.onBlur) props.onBlur(value);
        callCallback();
        finish();
        break;
      case 'Enter':
        submit();
        break;
      case 'Escape':
        if (inputRef.current) inputRef.current.blur();
        setValue(props.value);
        callCallback();
        finish();
        break;
      default:
        break;
    }
  };

  const returnIfTextExists = () => (isFocused || value ? 'banana-input--text-exists' : '');

  const getInputClasses = () =>
    `banana-input ${isFocused && 'banana-input--focused'} ${returnIfTextExists()} ${props.inputClass}`;

  return (
    <div style={props.style} className={props.baseClass}>
      <div className={`${props.baseClass}__top-text`}>{props.label}</div>

      <div className="shipment-cell__content">
        <div className="shipment-cell__child">
          {!isEditable && (
            <React.Fragment>
              {props.pencil && (
                <React.Fragment>
                  <div
                    className="shipment-cell__child__text"
                    role="button"
                    tabIndex="0"
                    onKeyPress={null}
                    onClick={start}
                    style={{ position: 'relative' }}
                  >
                    <span stayle={{ opacity: showPlaceholder ? 0 : 1 }}>{value}</span>
                    {showPlaceholder && <div className="banana-input__text">{props.placeholder || props.label}</div>}
                  </div>

                  <i className="fa fa-pencil shipment-cell__edit-button" aria-hidden="true" onClick={start} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {(isEditable || !props.pencil) && (
            <div className="box-details__cable__cell" style={{ display: 'contents' }}>
              <div className={getInputClasses()}>
                <input
                  style={{ cursor: !props.pencil && !isEditable ? 'pointer' : undefined }}
                  value={value}
                  type={props.type}
                  onKeyDown={keyPressHandler}
                  onFocus={start}
                  onBlur={onInputBlurHandler}
                  onChange={handleChange}
                  disabled={props.pencil && !isEditable}
                  ref={inputRef}
                  onClick={() => {
                    if (!props.pencil) start();
                  }}
                />
                <div className="banana-input__text">{props.placeholder || props.label}</div>
              </div>
              {props.pencil && <i className="fa fa-check shipment-cell__edit-button" aria-hidden="true" onClick={submit} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BananaV2InputElement.propTypes = {
  label: PropTypes.string.isRequired,
  onSubmitEditing: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['text', 'number']).isRequired,
  // optional
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseClass: PropTypes.string,
  inputClass: PropTypes.string,
  focus: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  pencil: PropTypes.bool,
  // optional functions
  onBlur: PropTypes.func,
  callback: PropTypes.func,
  onRefAdd: PropTypes.func,
};
BananaV2InputElement.defaultProps = {
  placeholder: null,
  value: '',
  baseClass: 'shipment-cell',
  inputClass: '',
  focus: false,
  style: { padding: 0 },
  editable: false,
  pencil: false,
  onBlur: () => {},
  callback: () => {},
  onRefAdd: () => {},
};
