import standardRequest from 'helpers/backend-requests';
import { I18n } from 'react-redux-i18n';
import {
  setErrorMessage,
  resetAppState,
  toggleLoading,
  toggleDialogWindow,
  renderNotification,
} from 'app-redux/actions/app-actions';
import moment from 'moment';

export const sharedResetAppState = resetAppState;

function saveGoodsOrderInfo(mockData) {
  return {
    type: 'GOODS_ORDER_SAVE_INFO',
    partners: mockData.partners,
    statuses: mockData.statuses,
    units: mockData.units,
    status: mockData.defaultStatus.toString(),
    chosenUnit: mockData.defaultUnit,
  };
}

function resetOrderState() {
  return {
    type: 'GOODS_ORDER_RESET_STATE',
  };
}

export function updateGoodsOrderPartner(partnerID) {
  return {
    type: 'GOODS_ORDER_UPDATE_PARTNER',
    partnerID,
  };
}

export function updateGoodsOrderPartnerOrderID(partnerOrderID) {
  return {
    type: 'GOODS_ORDER_UPDATE_PARTNER_ORDER_ID',
    partnerOrderID,
  };
}

export function updateGoodsOrderDeliveryDateTime(deliveryDateTime) {
  return {
    type: 'GOODS_ORDER_UPDATE_DELIVERY_DATE_TIME',
    deliveryDateTime,
  };
}

export function updateGoodsOrderDate(orderDate) {
  return {
    type: 'GOODS_ORDER_UPDATE_ORDER_DATE',
    orderDate,
  };
}

export function updateGoodsOrderStatus(status) {
  return {
    type: 'GOODS_ORDER_UPDATE_ORDER_STATUS',
    status,
  };
}

export function updateGoodsOrderQuantity(quantity) {
  return {
    type: 'GOODS_ORDER_UPDATE_ORDER_QUANTITY',
    quantity,
  };
}

export function updateGoodsOrderUnit(chosenUnit) {
  return {
    type: 'GOODS_ORDER_UPDATE_ORDER_UNIT',
    chosenUnit,
  };
}

export function getNewGoodsOrder() {
  const path = '/goods_orders/new';
  return (dispatch) => {
    dispatch(toggleLoading(true));
    dispatch(resetOrderState());
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveGoodsOrderInfo(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getGoodsOrder(id) {
  const path = `/goods_orders/${id}`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveGoodsOrderInfo(response.order_defaults));
          dispatch(updateGoodsOrderPartner(response.order.partnerId));
          dispatch(updateGoodsOrderPartnerOrderID(response.order.partnerOrderId));
          dispatch(updateGoodsOrderDeliveryDateTime(moment(response.order.deliveryDatetime)));
          dispatch(updateGoodsOrderDate(moment(response.order.orderedAt)));
          dispatch(
            updateGoodsOrderStatus(
              response.order_defaults.statuses.find((s) => s.status_name === response.order.status).id.toString()
            )
          );
          dispatch(updateGoodsOrderQuantity(response.order.mainQuantity));
          dispatch(updateGoodsOrderUnit(response.order.mainUnitId));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function saveNewGoodsOrder(requestData) {
  const path = '/goods_orders';
  const body = {
    partner_id: requestData.partnerID,
    partner_order_id: requestData.partnerOrderID,
    ordered_at: `${requestData.orderDate.format('YYYY-MM-DD')}`,
    delivery_datetime: `${requestData.deliveryDateTime.format('YYYY-MM-DD')}T${requestData.deliveryDateTime.format('kk:mm')}`,
    status: parseInt(requestData.status, 10),
    quantity: requestData.quantity,
    main_unit: requestData.chosenUnit,
  };
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'POST', {}, body)
      .then((response) => {
        if (response.error === 422) {
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              dialogTitle: 'Something went wrong.',
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(getNewGoodsOrder());
          dispatch(renderNotification('Order was successfully saved'));
          dispatch(resetOrderState());
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function saveUpdatedGoodsOrder(requestData, goodsOrderID) {
  const path = `/goods_orders/${goodsOrderID}`;
  const body = {
    partner_id: requestData.partnerID,
    partner_order_id: requestData.partnerOrderID,
    ordered_at: `${requestData.orderDate.format('YYYY-MM-DD')}`,
    delivery_datetime: `${requestData.deliveryDateTime.format('YYYY-MM-DD')}T${requestData.deliveryDateTime.format('kk:mm')}`,
    status: parseInt(requestData.status, 10),
    quantity: requestData.quantity,
    main_unit: requestData.chosenUnit,
  };
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'PATCH', {}, body)
      .then((response) => {
        var message = 'Something went wrong.'

        if (response.data && response.data.errors) {
          message = response.data.errors.join(' & ');
        }

        if (response.error === 422) {
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              dialogTitle: message,
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(renderNotification('Order was successfully updated'));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
