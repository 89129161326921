import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InvoicesTable, TotalAmount, NewRecordsInvoices } from 'components/presentational/valora-invoices';
import * as valoraInvoicesActions from './valora-invoices-actions';

class ValoraInvoices extends Component {
  componentDidMount() {
    this.props.actions.sharedResetAppState();
    this.props.actions.getInvoices();
  }

  render() {
    return (
      <div>
        <NewRecordsInvoices generateValoraInvoices={this.props.actions.generateValoraInvoices} paidBool={this.props.paidBool} />
        <TotalAmount
          withDeposit={this.props.invoices.total_open_without_deposit}
          fromDeposit={this.props.invoices.total_open_from_deposit}
        />
        <InvoicesTable
          invoices={this.props.invoices.valora_invoices}
          paidBool={this.props.paidBool}
          togglePaidStatus={this.props.actions.togglePaidStatusRequest}
          getValoraInvoicePdf={this.props.actions.getValoraInvoicePdf}
          getValoraSalesReport={this.props.actions.getValoraSalesReport}
          getValoraTransactionReport={this.props.actions.getValoraTransactionReport}
          updateValoraInvoice={this.props.actions.updateValoraInvoice}
          removePaidInvoices={this.props.actions.removePaidInvoices}
        />
      </div>
    );
  }
}

ValoraInvoices.propTypes = {
  actions: PropTypes.object.isRequired,
  paidBool: PropTypes.bool,
  invoices: PropTypes.object,
};

ValoraInvoices.defaultProps = {
  invoices: {},
  paidBool: null,
};

function mapStateToProps({ valoraInvoicesData }) {
  return {
    invoices: valoraInvoicesData.invoices,
    paidBool: valoraInvoicesData.paidBool,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...valoraInvoicesActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValoraInvoices);
