import React, { useState, useEffect } from 'react';

import { BananaV2OptionsElement } from './banana-options-element';

// TODO: there's an error heighlighted by TypeScripts
interface Props {
  label: string;
  // onSubmit
  onSubmitEditing: (val: ValueType) => void;
  // optional
  selected: SelectedType;
  inputClass: string;

  focus: boolean;
  style: object | null | undefined;
  editable: boolean;
  pencil: boolean;
  // optional functions
  onBlur: (val: unknown) => void;
  callback: (val: unknown) => void;
  getOptions: (val: ValueType) => OptionType[];
}

type ValueType = unknown;
type SelectedType = { label: string };
type OptionType = { id: number; label: string; code: string };

export const BananaV2InputOptions: React.FC<Props> = ({
  inputClass = '',
  focus = false,
  style = {},
  editable = false,
  pencil = false,

  selected = null,
  onBlur = () => {},
  callback = () => {},
  getOptions = () => [],
  onSubmitEditing,
  label,
}) => {
  const [value, setValue] = useState<string | number | null | undefined>('');
  const [options, setOptions] = useState<OptionType[]>([]);

  const processOptions = async (val: ValueType) => {
    if (getOptions) {
      const result = await getOptions(val);
      setOptions(result);
    }
  };

  useEffect(() => {
    processOptions(selected);
  }, [getOptions]);

  useEffect(() => {
    if (selected !== null) setValue(selected.label);
    else setValue('');
  }, [selected]);

  const getOptionsHandler = (val: unknown) => {
    setOptions(getOptions(val));
  };

  useEffect(() => {
    if (value) getOptionsHandler(value);
  }, [value]);

  const submit = (val: unknown) => {
    // We want blur to behave as submit :)
    onSubmitEditing(val);
    onBlur(val);
  };

  const onChange = (val: unknown) => {
    processOptions(val);
  };

  return (
    <BananaV2OptionsElement
      label={label}
      value={value}
      onChange={onChange}
      onSubmitEditing={submit}
      onBlur={submit}
      inputClass={inputClass}
      focus={focus}
      style={style}
      editable={editable}
      pencil={pencil}
      callback={callback}
      options={options}
    />
  );
};
