import React from 'react';
import PropTypes from 'prop-types';
import { BananaPopup } from 'banana-framework';
import { I18n } from 'react-redux-i18n';

const DialogWindow = (props) => (
  <BananaPopup
    dialogTitle={props.sharedProps.dialogTitle ? props.sharedProps.dialogTitle : I18n.t('dialog.dialogTitle')}
    applyText={props.sharedProps.applyText}
    cancelText={props.sharedProps.cancelText}
    toggleDialogWindow={props.sharedActions.toggleDialogWindow}
    applyFunc={props.sharedProps.applyFunc}
    customContent={props.sharedProps.customContent}
  />
);

DialogWindow.propTypes = {
  sharedProps: PropTypes.object.isRequired,
  sharedActions: PropTypes.object.isRequired,
};

export default DialogWindow;
