import initialState from 'app-redux/reducers/initial-state';

const LoginReducer = (state = initialState.loginData, action) => {
  switch (action.type) {
    case 'LOGIN_ACTION_CHANGE_EMAIL': {
      return { ...state, email: action.email };
    }
    case 'LOGIN_ACTION_CHANGE_PASSWORD': {
      return { ...state, password: action.password };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
