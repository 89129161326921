import React from 'react';
import PropTypes from 'prop-types';

const BananaEmojiComponent = (props) => (
  <span className="emoji" role="img" aria-label={props.label ? props.label : ''} aria-hidden={props.label ? 'false' : 'true'}>
    {props.symbol}
  </span>
);

BananaEmojiComponent.propTypes = {
  label: PropTypes.string,
  symbol: PropTypes.string.isRequired,
};

BananaEmojiComponent.defaultProps = {
  label: undefined,
};

export default BananaEmojiComponent;
