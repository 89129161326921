import standardRequest from 'helpers/backend-requests';
import { setErrorMessage, resetAppState, toggleLoading } from 'app-redux/actions/app-actions';
import { I18n } from 'react-redux-i18n';

export const sharedResetAppState = resetAppState;

function allRentalsSaveRentals(rentals) {
  return {
    type: 'ALL_RENTALS_SAVE_RENTALS',
    rentalsData: {
      rentals: rentals.rentals,
      totalPages: rentals.meta.total_pages,
      activeCountries: rentals.meta.active_countries,
    },
  };
}

function allRentalsSaveRentalsStats(stats) {
  return {
    type: 'ALL_RENTALS_SAVE_RENTALS_STATS',
    rentalsStats: {
      stats: stats.stats,
    },
  };
}

export function allRentalsChangeSearchText(search) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_CHANGE_SEARCH_TEXT',
      search,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function allRentalsChangeSearchLocationText(searchLocation) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_CHANGE_SEARCH_LOCATION_TEXT',
      searchLocation,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function getAllRentals(requestData) {
  let status = '';
  let user = '';
  let rentalType = '';

  const search = `?search=${requestData.search}`;
  const searchLocation = `&search_location=${requestData.searchLocation}`;
  const dateRange = `&date_range=${requestData.dateRange}`;
  const page = `&page=${requestData.page}`;
  const country = `&country=${requestData.country}`;

  requestData.status.map((currentStatus) => {
    status = `${status}&status[]=${currentStatus}`;
    return null;
  });

  requestData.user.map((currentUser) => {
    user = `${user}&user[]=${currentUser}`;
    return null;
  });

  requestData.rentalType.map((currentType) => {
    rentalType = `${rentalType}&rental_type[]=${currentType}`;
    return null;
  });

  const path = `/rentals${search}${searchLocation}${page}${dateRange}${status}${user}${country}${rentalType}`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(allRentalsSaveRentals(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getRentalsStats() {
  const path = `/rentals/stats`;
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(allRentalsSaveRentalsStats(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function allRentalsChangeDateRange(dateRange) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_CHANGE_DATE_RANGE',
      dateRange,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function updateFiltringArray(array, value) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_UPDATE_FILTERING_ARRAY',
      array,
      value: [value],
      page: 1,
    });
    return Promise.resolve();
  };
}

export function filterFiltringArray(array, value) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_FILTER_FILTERING_ARRAY',
      array,
      value,
      page: 1,
    });
    return Promise.resolve();
  };
}

export function allRentalsSetActiveFilterPage(page) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_SET_ACTIVE_FILTER_PAGE',
      page,
    });
    return Promise.resolve();
  };
}

export function allRentalsSetCountry(country) {
  return (dispatch) => {
    dispatch({
      type: 'ALL_RENTALS_SET_COUNTRY',
      country,
    });
    return Promise.resolve();
  };
}
