import initialState from 'app-redux/reducers/initial-state';

const GoodsOrderReducer = (state = initialState.goodsOrderData, action) => {
  switch (action.type) {
    case 'GOODS_ORDER_SAVE_INFO':
      return {
        ...state,
        partners: action.partners,
        statuses: action.statuses,
        status: action.status,
        units: action.units,
        chosenUnit: action.chosenUnit,
      };
    case 'GOODS_ORDER_UPDATE_PARTNER':
      return { ...state, partnerID: action.partnerID };
    case 'GOODS_ORDER_UPDATE_PARTNER_ORDER_ID':
      return { ...state, partnerOrderID: action.partnerOrderID };
    case 'GOODS_ORDER_UPDATE_DELIVERY_DATE_TIME':
      return { ...state, deliveryDateTime: action.deliveryDateTime };
    case 'GOODS_ORDER_UPDATE_ORDER_DATE':
      return { ...state, orderDate: action.orderDate };
    case 'GOODS_ORDER_UPDATE_ORDER_STATUS':
      return { ...state, status: action.status };
    case 'GOODS_ORDER_UPDATE_ORDER_QUANTITY':
      return { ...state, quantity: action.quantity };
    case 'GOODS_ORDER_UPDATE_ORDER_UNIT':
      return { ...state, chosenUnit: action.chosenUnit };
    case 'GOODS_ORDER_RESET_STATE':
      return { ...initialState.goodsOrderData };
    default:
      return state;
  }
};

export default GoodsOrderReducer;
