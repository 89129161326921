import React from 'react';
import PropTypes from 'prop-types';
import './styles/notification.scss';

export default function Notification({ sharedProps, sharedActions }) {
  return (
    <div type="notification">
      <div className="notification">
        <button
          className="notification__close-button"
          onClick={() => {
            sharedActions.renderNotification('');
          }}
        >
          x
        </button>
        {sharedProps.notification}
      </div>
    </div>
  );
}

Notification.propTypes = {
  sharedProps: PropTypes.object.isRequired,
  sharedActions: PropTypes.object.isRequired,
};
