import React from 'react';
import PropTypes from 'prop-types';
import { BananaInput, BananaDatepicker, BananaInputButton } from 'banana-framework';
import { shipmentsFilter } from 'constants/default-settings';
import { I18n } from 'react-redux-i18n';

function returnProperLocationOptions() {
  return shipmentsFilter.map((filter) => ({
    text: I18n.t(filter.text),
    variant: filter.variant,
  }));
}

export default function ShipmentsFilter(props) {
  return (
    <div className="banana-filter">
      <div className="banana-filter__row">
        <div className="banana-filter__element banana-filter__element--input">
          <BananaInputButton
            text={I18n.t('bananaFilter.findLocation')}
            value={props.location}
            saveFunc={props.changeLocationFunction}
            initialValue
            saveButtonText="Search"
          />
        </div>
        <div className="banana-filter__element">
          <BananaInput
            text={I18n.t('bananaFilter.shipmentType')}
            editable={false}
            options={returnProperLocationOptions()}
            value={props.shipmentType}
            onChangeAction={props.changeShipmentType}
            initialValue
          />
        </div>
        <div className="banana-filter__element">
          <BananaDatepicker text={I18n.t('bananaFilter.from')} startDate={props.fromDate} dataChange={props.changeFromDate} />
        </div>
        <div className="banana-filter__element">
          <BananaDatepicker text={I18n.t('bananaFilter.to')} startDate={props.toDate} dataChange={props.changeToDate} />
        </div>
        <button className="banana-filter__element banana-filter__element--reset" onClick={props.resetRequestState}>
          <i className="fa fa-times-circle-o banana-filter__reset-button" aria-hidden="true" />
        </button>
      </div>
      <div className="banana-filter__row">
        <div className="banana-filter__element banana-filter__element--input">
          <BananaInputButton
            text={I18n.t('bananaFilter.findBoxId')}
            value={props.boxExternalId}
            saveFunc={props.updateBoxExternalId}
            filter="numeric"
            initialValue
            saveButtonText="Sort"
          />
        </div>
        <div className="banana-filter__element banana-filter__element--input">
          <BananaInputButton
            text={I18n.t('bananaFilter.findBatteryId')}
            value={props.batteryExternalId}
            saveFunc={props.updateBatteryExternalId}
            filter="numeric"
            initialValue
            saveButtonText="Sort"
          />
        </div>
      </div>
    </div>
  );
}

ShipmentsFilter.propTypes = {
  location: PropTypes.string.isRequired,
  changeLocationFunction: PropTypes.func.isRequired,
  shipmentType: PropTypes.string.isRequired,
  changeShipmentType: PropTypes.func.isRequired,
  fromDate: PropTypes.object.isRequired,
  changeFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.object.isRequired,
  changeToDate: PropTypes.func.isRequired,
  resetRequestState: PropTypes.func.isRequired,
  updateBoxExternalId: PropTypes.func.isRequired,
  boxExternalId: PropTypes.string.isRequired,
  batteryExternalId: PropTypes.string.isRequired,
  updateBatteryExternalId: PropTypes.func.isRequired,
};
