import { StoreModule } from 'redux-auto-actions';

export enum ShipmentLabel {
  STATE = 'shipmentData',
}

export type DirectionType = 'to' | 'from';

export type ShipmentTypeType = 'delivery' | 'return';
export type AssignmentBoxType = {
  id: number;
  checked: boolean;
};
export type ShipmentType = {
  id: number;
  boxes: BoxType[];
  shipmentType: ShipmentTypeType;
  shipmentSummary: ShipmentSummaryType | null;
  availableLocationsTo: LocationType[];
  availableLocationsFrom: LocationType[];
  assignedBoxes: AssignmentBoxType[];
};
export type ShipmentSummaryType = {
  expiration_date: string;
  from_location_name: string;
  from_location_pos_number: string;
  to_location_name: string;
  to_location_pos_number: string;
  logistics_slip_number_of_boxes: string;
  logistics_slip_code: string;
  shipment_date: string;
  number_of_batteries: number;
  number_of_boxes: number;
  number_of_cables: number;
};

type CableDataKeys = string; // TODO: constrain this more
export type CableDataType = Record<CableDataKeys, number>;
export type BoxType = {
  id: number;
  box_external_id: number;

  expiration_date: string;
  cable_data: CableDataType;
  battery_ids: unknown;
  logistics_partner_thingable_identifier: string;
  before_hq_location: string;
  before_hq_shipment_detail_id: string;

  logista_box_identifier: string;
  // created: boolean;
};
export type LocationType = {
  id: string;
  location_name: string;
  pos_number: string;
};
export type ShipmentStateType = {
  id: null | number;
  shipmentType: null | ShipmentTypeType;
  boxes: BoxType[];
  shipmentSummary: null | ShipmentSummaryType;
  availableLocationsTo: LocationType[];
  availableLocationsFrom: LocationType[];
  assignedBoxes: AssignmentBoxType[];
};

export const shipmentDataInitialState: ShipmentStateType = {
  id: null,
  shipmentType: null,
  shipmentSummary: null,
  boxes: [],
  availableLocationsTo: [],
  availableLocationsFrom: [],
  assignedBoxes: [],
};

export enum ActionType {
  SET_LOGISTICS_SLIP_CODE = 'SHIPMENT/SET_LOGISTICS_SLIP_CODE',
  SET_LOGISTICS_SLIP_NR_BOXES = 'SHIPMENT/SET_LOGISTICS_SLIP_NR_BOXES',
  // TODO
  SAVE_SHIPMENT = 'SHIPMENT/SAVE_SHIPMENT',
  SET_SHIPMENT_TYPE = 'SHIPMENT/SET_SHIPMENT_TYPE',
  REMOVE_SHIPMENT_BOX = 'SHIPMENT/REMOVE_SHIPMENT_BOX',
  REMOVE_ALL_SHIPMENT_BOXES = 'SHIPMENT/REMOVE_ALL_SHIPMENT_BOXES',
  REMOVE_ASSIGNED_BOX = 'SHIPMENT/REMOVE_ASSIGNED_BOX',

  UPDATE_SHIPMENT_SUMMARY = 'SHIPMENT/UPDATE_SHIPMENT_SUMMARY',
  UPDATE_SHIPMENT_SUMMARY_BOXES = 'SHIPMENT/UPDATE_SHIPMENT_SUMMARY_BOXES',
  SAVE_TO_AVAILABLE_LOCATIONS = 'SHIPMENT/SAVE_TO_AVAILABLE_LOCATIONS',
  SAVE_FROM_AVAILABLE_LOCATIONS = 'SHIPMENT/SAVE_FROM_AVAILABLE_LOCATIONS',
  RESET_SHIPMENT = 'SHIPMENT/RESET_SHIPMENT',
  TOGGLE_ASSIGNED_BOX_STATE = 'SHIPMENT/TOGGLE_ASSIGNED_BOX_STATE',
  CREATE_NEW_BOX = 'SHIPMENT/CREATE_NEW_BOX',
  PUSH_SHIPMENT_BOX = 'SHIPMENT/PUSH_SHIPMENT_BOX',
  SAVE_BOX = 'SHIPMENT/SAVE_BOX',
  UPDATE_BOXES_EXPIRATION_DATE = 'SHIPMENT/UPDATE_BOXES_EXPIRATION_DATE',

  // TODO: this seems not needed
  REMOVE_ASSIGNED_BOX_INFO = 'REMOVE_ASSIGNED_BOX_INFO',
}

export const shipmentStore = new StoreModule<ActionType, ShipmentStateType>(ShipmentLabel.STATE, shipmentDataInitialState);
