import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BananaCheckbox } from 'banana-framework';

export default class ShipmentCompactTableBody extends Component {
  returnBody() {
    if (this.props.data) {
      return this.props.data.map((element) => {
        const shippingDate = element.shipment_date ? element.shipment_date : 'not set';
        const expirationDate = element.expiration_date ? element.expiration_date : 'not set';
        let daysBeforeExpiration;
        if (element.shipment_date && element.expiration_date) {
          daysBeforeExpiration = (Date.parse(expirationDate) - Date.parse(shippingDate)) / 1000 / 3600 / 24;
        } else {
          daysBeforeExpiration = '-';
        }

        return (
          <div className="table__element" key={element.id}>
            <div className="table__element__cell">{element.id}</div>
            <div className="table__element__cell">{shippingDate}</div>
            <div className="table__element__cell">{expirationDate}</div>
            <div className="table__element__cell">{daysBeforeExpiration}</div>
            <div className="table__element__cell">{element.number_of_boxes}</div>
            <div className="table__element__cell">{element.number_of_batteries}</div>
            {this.props && !this.props.isOrderConsolidated && (
              <div className="table__element__cell">
                <div className="table__element__actions-box">
                  <Link to={`/shipment/edit/${element.id}`} className="table__element__button">
                    <i className="fa fa-pencil" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            )}
            {this.props && !this.props.isOrderConsolidated && (
              <div className="table__element__cell">
                <BananaCheckbox
                  checked={!!this.props.checkedShipments.includes(element.id)}
                  checkedFunc={() => {
                    this.props.shipmentChecked(element.id);
                  }}
                  uncheckedFunc={() => {
                    this.props.shipmentUnchecked(element.id);
                  }}
                />
              </div>
            )}
          </div>
        );
      });
    }
    return null;
  }

  render() {
    return <div className="table__body">{this.returnBody()}</div>;
  }
}

ShipmentCompactTableBody.propTypes = {
  data: PropTypes.array,
  isOrderConsolidated: PropTypes.bool,
  shipmentChecked: PropTypes.func,
  shipmentUnchecked: PropTypes.func,
  checkedShipments: PropTypes.object,
};

ShipmentCompactTableBody.defaultProps = {
  data: [],
  isOrderConsolidated: false,
  shipmentChecked: () => {},
  shipmentUnchecked: () => {},
  checkedShipments: [],
};
