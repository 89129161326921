import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BananaV2OptionsElement } from '.';

export const BananaV2LocationOptionsElement = (props) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  const processOptions = async (val) => {
    if (props.getOptions) {
      const result = await props.getOptions(val);
      setOptions(result);
    }
  };

  useEffect(() => {
    processOptions(props.location.location_name);
  }, [props.getOptions]);

  useEffect(() => {
    if (props.location.location_name !== null && props.location.pos_number !== null)
      setValue(`${props.location.location_name} | ${props.location.pos_number}`);
    else setValue('');
  }, [props.location]);

  const getOptions = (val) => {
    const newOptions = props.getOptions(val);
    if (newOptions && newOptions.length) setOptions(newOptions.map((loc) => ({ label: loc.location_name, id: loc.pos_number })));
  };

  useEffect(() => {
    getOptions(value);
  }, [value]);

  const submit = (val) => {
    const selectedLocation = { id: val.id, location_name: val.label, pos_number: val.code };
    // We want blur to behave as submit :)
    props.onSubmitEditing(selectedLocation);
    props.onBlur(selectedLocation);
  };

  const onChange = (val) => {
    const locationName = val.split('|')[0];
    processOptions(locationName);
  };

  return (
    <BananaV2OptionsElement
      label={props.label}
      value={value}
      onChange={onChange}
      onSubmitEditing={submit}
      onBlur={submit}
      inputClass={props.inputClass}
      focus={props.focus}
      style={props.style}
      editable={props.editable}
      pencil={props.pencil}
      callback={props.callback}
      options={options.map((o) => ({ id: o.id, label: o.location_name, code: o.pos_number }))}
    />
  );
};

BananaV2LocationOptionsElement.propTypes = {
  label: PropTypes.string.isRequired,
  // onSubmit
  onSubmitEditing: PropTypes.func.isRequired,
  // optional
  location: PropTypes.shape({
    location_name: PropTypes.string,
    pos_number: PropTypes.string,
  }),
  inputClass: PropTypes.string,
  focus: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  pencil: PropTypes.bool,
  // optional functions
  onBlur: PropTypes.func,
  callback: PropTypes.func,
  getOptions: PropTypes.func,
  onChange: PropTypes.func,
};
BananaV2LocationOptionsElement.defaultProps = {
  location: { location_name: '', pos_number: '' },
  inputClass: '',
  focus: false,
  style: {},
  editable: false,
  pencil: false,
  onBlur: () => {},
  callback: () => {},
  getOptions: () => [],
};
