import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { BananaInputButton, BananaTag } from 'banana-framework';
import { TabFocus } from 'banana-framework-v2';

import './box-batteries.scss';

export default class BoxBatteries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successfulResponse: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.batteries.length > this.props.batteries.length) {
      this.setState({ successfulResponse: true });
    } else if (nextProps.error) {
      this.setState({ successfulResponse: true });
    }
  }

  componentWillUnmount() {
    TabFocus.getInstance().remove(`addBox_${this.props.boxId}`);
  }

  renderBoxTags() {
    return this.props.batteries.map((battery) => (
      <BananaTag
        key={battery}
        deleteAction={() => {
          this.props.deleteBattery(battery);
        }}
      >
        {battery}
      </BananaTag>
    ));
  }

  render() {
    return (
      <div className={`box-batteries ${this.props.isLoading ? 'element--is-loading' : ''}`}>
        <h3 className="box-batteries__title">
          {I18n.t('batteries.batteries')} ({this.props.batteries.length})
        </h3>
        <div className="box-batteries__input">
          <BananaInputButton
            text={I18n.t('batteries.scanId')}
            saveFunc={this.props.addNewBattere}
            error={this.props.error}
            successfulResponse={this.state.successfulResponse}
            setFocus={this.props.focused}
            onBlur={this.props.resetFocus}
            onRefAdd={(ref) => {
              TabFocus.getInstance().add(`addBox_${this.props.boxId}`, ref);
              return () => {
                TabFocus.getInstance().remove(`addBox_${this.props.boxId}`);
              };
            }}
          />
        </div>
        <div className="box-batteries__box-list">{this.renderBoxTags()}</div>
      </div>
    );
  }
}

BoxBatteries.propTypes = {
  boxId: PropTypes.string.isRequired,
  batteries: PropTypes.array,
  addNewBattere: PropTypes.func.isRequired,
  deleteBattery: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  focused: PropTypes.bool,
  resetFocus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

BoxBatteries.defaultProps = {
  error: '',
  batteries: [],
  focused: false,
};
