import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BananaPaginatorElement extends Component {
  returnActiveClassName() {
    if (this.props.isActive) return ' banana-paginator__element--is-active';
    return '';
  }

  render() {
    return (
      <li className={`banana-paginator__element ${this.returnActiveClassName()}`}>
        <button onClick={this.props.event} className="banana-paginator__element__button">
          {this.props.content}
        </button>
      </li>
    );
  }
}

BananaPaginatorElement.propTypes = {
  isActive: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  event: PropTypes.func.isRequired,
};

BananaPaginatorElement.defaultProps = {
  isActive: false,
  content: 1,
};
