/* eslint react/no-did-update-set-state: ["off"] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BananaPaginatorElement from './banana-paginator-element';

export default class BananaPaginator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstComponent: 1,
      lastComponent: this.props.maxElements,
    };
    this.nextButtonEvent = this.nextButtonEvent.bind(this);
    this.prevButtonEvent = this.prevButtonEvent.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.maxElements !== prevProps.maxElements || this.props.activeComponent === 1) &&
      this.state.firstComponent !== 1
    ) {
      this.setState(() => ({
        firstComponent: 1,
        lastComponent: this.props.maxElements,
      }));
    }
  }

  prevButtonEvent() {
    if (this.state.firstComponent > 1 && this.props.activeComponent > 1) {
      this.setState(
        {
          firstComponent: this.state.firstComponent - 1,
          lastComponent: this.state.lastComponent - 1,
        },
        () => {
          this.props.setActiveElement(this.props.activeComponent - 1);
        }
      );
    } else if (this.props.activeComponent > 1) {
      this.props.setActiveElement(this.props.activeComponent - 1);
    }
  }

  nextButtonEvent() {
    if (this.state.lastComponent < this.props.elementAmount) {
      this.setState(
        {
          firstComponent: this.state.firstComponent + 1,
          lastComponent: this.state.lastComponent + 1,
        },
        () => {
          this.props.setActiveElement(this.props.activeComponent + 1);
        }
      );
    } else if (this.props.activeComponent < this.props.elementAmount) {
      this.props.setActiveElement(this.props.activeComponent + 1);
    }
  }

  renderElements() {
    if (this.props.elementAmount !== 0) {
      const elements = [];
      for (let i = this.state.firstComponent; i <= this.state.lastComponent && i <= this.props.elementAmount; i += 1) {
        elements.push(
          <BananaPaginatorElement
            key={i}
            content={i}
            isActive={i === this.props.activeComponent}
            event={() => {
              this.props.setActiveElement(i);
            }}
          />
        );
      }
      return elements;
    }
    return null;
  }

  renderPrevButton() {
    if (this.props.maxElements < this.props.elementAmount) {
      return (
        <BananaPaginatorElement
          content={<i className="fa fa-angle-double-left" aria-hidden="true" />}
          event={this.prevButtonEvent}
        />
      );
    }
    return null;
  }

  renderNextButton() {
    if (this.props.maxElements < this.props.elementAmount) {
      return (
        <BananaPaginatorElement
          content={<i className="fa fa-angle-double-right" aria-hidden="true" />}
          event={this.nextButtonEvent}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="banana-paginator">
        <ul className="banana-paginator__wrapper">
          {this.renderPrevButton()}
          {this.renderElements()}
          {this.renderNextButton()}
        </ul>
      </div>
    );
  }
}

BananaPaginator.propTypes = {
  maxElements: PropTypes.number,
  activeComponent: PropTypes.number,
  elementAmount: PropTypes.number,
  setActiveElement: PropTypes.func.isRequired,
};

BananaPaginator.defaultProps = {
  maxElements: 0,
  activeComponent: 0,
  elementAmount: 0,
};
