import initialState from 'app-redux/reducers/initial-state';

const AssignShipmentsToGoodsOrderReducer = (state = initialState.assignShipmentsToGoodsOrderData, action) => {
  switch (action.type) {
    case 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_NOT_READY_ORDERS':
      return { ...state, orders: action.orders };
    case 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_SELECTED_ORDER':
      return { ...state, selectedOrder: action.selectedOrder };
    case 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_SHIPMENTS_ASSIGNED_TO_SELECTED_ORDER':
      return { ...state, shipmentsAssignedToSelectedOrder: action.shipmentsAssignedToSelectedOrder };
    case 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_UNASSIGNED_SHIPMENTS':
      return { ...state, unassignedShipments: action.unassignedShipments };
    case 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_RESET_STATE':
      return { ...initialState.assignShipmentsToGoodsOrderData };
    default:
      return state;
  }
};

export default AssignShipmentsToGoodsOrderReducer;
