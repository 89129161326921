import standardRequest from 'helpers/backend-requests';
import { I18n } from 'react-redux-i18n';

import {
  setErrorMessage,
  resetAppState,
  toggleLoading,
  toggleDialogWindow,
  renderNotification,
} from 'app-redux/actions/app-actions';

export const sharedResetAppState = resetAppState;

function saveNewMockRentalInfo(mockData) {
  return {
    type: 'NEW_MOCK_RENTAL_SAVE_NEW_INFO',
    types: mockData.types,
    defaultLocation: mockData.default_location,
    choosenType: mockData.types[0].id,
  };
}

function resetNewMockState() {
  return {
    type: 'NEW_MOCK_RENTAL_RESET_NEW_MOCK_STATE',
  };
}

export function getNewMockRental() {
  const path = '/rentals/new/mock';
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveNewMockRentalInfo(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function updateNewMockRentalDate(rentalDate) {
  return {
    type: 'NEW_MOCK_RENTAL_UPDATE_DATE',
    rentalDate,
  };
}

export function updateNewMockType(choosenType) {
  return {
    type: 'NEW_MOCK_RENTAL_UPDATE_TYPE',
    choosenType,
  };
}

export function updateNewMockBattery(battery) {
  return {
    type: 'NEW_MOCK_RENTAL_UPDATE_BATTERY',
    battery,
  };
}

export function changeNewMockLateFee(lateFee) {
  return {
    type: 'NEW_MOCK_RENTAL_CHANGE_NEW_MOCK_LATE_FEE',
    lateFee,
  };
}

export function saveNewMockRental(requestData) {
  const path = '/rentals/';
  const body = {
    rental_date: `${requestData.rentalDate.format('YYYY-MM-DD')}T${requestData.rentalDate.format('kk:mm')}`,
    battery_id: requestData.battery,
    rental_type: requestData.rentalType,
    location: requestData.location,
    no_late_fee: requestData.noLateFee,
  };
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'POST', {}, body)
      .then((response) => {
        const responseData = response.data;
        if (response.error === 422) {
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              customContent: responseData.errors,
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(getNewMockRental());
          dispatch(renderNotification('Rental was successfully saved'));
          dispatch(resetNewMockState());
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
