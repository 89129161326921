import initialState from 'app-redux/reducers/initial-state';

const ShipmentsReducer = (state = initialState.shipmentsData, action) => {
  switch (action.type) {
    case 'SHIPMENTS_ACTION_SAVE_SHIPMENTS':
      return { ...state, shipments: action.shipments };
    case 'SHIPMENTS_ACTION_CHANGE_LOCATION_PATTERN': {
      return { ...state, location_pattern: action.location_pattern, page: action.page };
    }
    case 'SHIPMENTS_ACTION_CHANGE_SHIPMENT_TYPE': {
      return { ...state, shipment_type: action.shipment_type, page: action.page };
    }
    case 'SHIPMENTS_ACTION_CHANGE_TO_DATE': {
      return { ...state, to_date: action.to_date, page: action.page };
    }
    case 'SHIPMENTS_ACTION_CHANGE_FROM_DATE': {
      return { ...state, from_date: action.from_date, page: action.page };
    }
    case 'SHIPMENTS_ACTION_RESET_REQUEST_STATE': {
      return { ...initialState.shipmentsData, pageReturn: state.pageReturn, sortingReturn: state.sortingReturn };
    }
    case 'SHIPMENTS_ACTION_SET_ACTIVE_FILTER_PAGINATOR_ELEMENT': {
      return { ...state, page: action.page };
    }
    case 'SHIPMENTS_ACTION_SET_SORTING_TYPE': {
      return { ...state, sorting: action.sorting, page: action.page };
    }
    case 'SHIPMENTS_ACTION_SET_RETURN_SORTING_TYPE': {
      return { ...state, sortingReturn: action.sorting, pageReturn: action.page };
    }
    case 'SHIPMENTS_ACTION_SET_ACTIVE_FILTER_PAGINATOR_ELEMENT_RETURN': {
      return { ...state, pageReturn: action.page };
    }
    case 'SHIPMENTS_ACTIONS_UPDATE_BOX_EXTERNAL_ID': {
      return { ...state, box_external_id: action.box_external_id };
    }
    case 'SHIPMENTS_ACTIONS_UPDATE_BATTERY_EXTERNAL_ID': {
      return { ...state, battery_external_id: action.box_external_id };
    }
    default:
      return state;
  }
};

export default ShipmentsReducer;
