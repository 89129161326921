import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import * as appActions from 'app-redux/actions/app-actions';

const CreateShipment = (props) => {
  const history = useHistory();
  const { id, shipmentType } = useParams();

  const onSuccess = (newId) => history.replace(`/shipment/edit/${newId}`);

  useEffect(() => {
    // shipmentType is constrained in route
    if (id === undefined) props.createShipment(shipmentType, onSuccess);
  }, [id, shipmentType]);

  return <React.Fragment />;
};
CreateShipment.propTypes = { createShipment: PropTypes.func.isRequired };
const mapDispatchToProps = { createShipment: appActions.createShipment };
export default connect(null, mapDispatchToProps)(CreateShipment);
