import initialState from 'app-redux/reducers/initial-state';

const RentalsReducer = (state = initialState.allRentalsData, action) => {
  switch (action.type) {
    case 'ALL_RENTALS_CHANGE_SEARCH_TEXT':
      return { ...state, search: action.search, page: action.page };
    case 'ALL_RENTALS_CHANGE_SEARCH_LOCATION_TEXT':
      return { ...state, searchLocation: action.searchLocation, page: action.page };
    case 'ALL_RENTALS_CHANGE_DATE_RANGE':
      return { ...state, dateRange: action.dateRange, page: action.page };
    case 'ALL_RENTALS_UPDATE_FILTERING_ARRAY':
      return { ...state, [action.array]: action.value.concat(state[action.array]), page: action.page };
    case 'ALL_RENTALS_FILTER_FILTERING_ARRAY':
      return { ...state, [action.array]: state[action.array].filter((value) => value !== action.value), page: action.page };
    case 'ALL_RENTALS_SAVE_RENTALS':
      return { ...state, rentalsData: action.rentalsData };
    case 'ALL_RENTALS_SAVE_RENTALS_STATS':
      return { ...state, rentalsStats: action.rentalsStats.stats };
    case 'ALL_RENTALS_SET_ACTIVE_FILTER_PAGE':
      return { ...state, page: action.page };
    case 'ALL_RENTALS_SET_COUNTRY':
      return { ...state, country: action.country };
    default:
      return state;
  }
};

export default RentalsReducer;
