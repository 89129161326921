import initialState from 'app-redux/reducers/initial-state';

const GoodsOrdersOverview = (state = initialState.goodsOrdersOverviewData, action) => {
  switch (action.type) {
    case 'GOODS_ORDERS_OVERVIEW_SAVE_ORDERS':
      return { ...state, orders: action.orders };
    case 'GOODS_ORDERS_OVERVIEW_SAVE_SELECTED_ORDER':
      return { ...state, selectedOrder: action.selectedOrder };
    case 'GOODS_ORDERS_OVERVIEW_SAVE_SHIPMENTS':
      return { ...state, shipments: action.shipments };
    case 'GOODS_ORDERS_OVERVIEW_DELETE_ORDER':
      return { ...state, orders: state.orders.filter((order) => order.orderId !== action.id) };
    case 'GOODS_ORDERS_OVERVIEW_SAVE_EXECUTED_ORDERS_ENABLED':
      return { ...state, executedOrdersEnabled: action.includeExecutedOrders };
    case 'GOODS_ORDERS_OVERVIEW_RESET_STATE':
      return { ...initialState.goodsOrderOverviewData };
    case 'GOODS_ORDERS_OVERVIEW_ADD_CHECKED_SHIPMENT':
      return { ...state, checkedShipments: [...state.checkedShipments, action.id] };
    case 'GOODS_ORDERS_OVERVIEW_REMOVE_CHECKED_SHIPMENT':
      return { ...state, checkedShipments: state.checkedShipments.filter((id) => id !== action.id) };
    case 'GOODS_ORDERS_OVERVIEW_RESET_CHECKED_SHIPMENTS':
      return { ...state, checkedShipments: initialState.goodsOrdersOverviewData.checkedShipments };
    default:
      return state;
  }
};

export default GoodsOrdersOverview;
