/* eslint array-callback-return: 0 */
/* eslint consistent-return: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

class BananaInput extends Component {
  constructor(props) {
    super(props);
    let value;
    if (this.props.initialValue) {
      value = this.props.value || this.props.value === 0 ? this.getProperValue() : '';
    } else {
      value = '';
    }
    this.state = {
      isFocused: false,
      showOptions: false,
      value,
    };
    this.setFocusState = this.setFocusState.bind(this);
    this.removeFocusState = this.removeFocusState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.chooseOption = this.chooseOption.bind(this);
    this.removeFocusFromOptions = this.removeFocusFromOptions.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (this.props.updatedOuside && this.props.value !== nextProps.value) ||
      JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options)
    ) {
      if (this.props.options) {
        if (JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options)) {
          nextProps.options.map((option) => {
            if (nextProps.value === option.variant) {
              this.setState({ value: option.text });
            }
          });
        } else {
          this.props.options.map((option) => {
            if (nextProps.value === option.variant) {
              this.setState({ value: option.text });
            }
          });
        }
      } else {
        this.setState({ value: nextProps.value });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      if (this.props.options) {
        if (!this.props.editable) {
          this.props.options.map((option) => {
            if (this.state.value === option.text) {
              this.props.onChangeAction(option.variant);
            }
          });
        } else {
          this.props.onChangeAction(this.state.value);
        }
      } else {
        this.props.onChangeAction(this.state.value);
      }
    }
  }

  getProperValue() {
    let currentText = '';
    if (this.props.options) {
      this.props.options.map((option) => {
        if (option.variant === this.props.value) currentText = option.text;
      });
      return currentText;
    }
    return this.props.value;
  }

  setFocusState() {
    if (this.props.options) {
      this.setState({ isFocused: true, showOptions: true });
    } else {
      this.setState({ isFocused: true });
    }
  }

  handleClickOutside(event) {
    let newValue = '';
    if (this.props.options && event.target.className !== 'banana-input__options__option') {
      if (this.props.editable) {
        this.props.options.map((option) => {
          if (option.text === this.state.value) newValue = this.state.value;
        });
        this.setState({
          showOptions: false,
          isFocused: false,
          value: newValue,
        });
      } else {
        this.setState({
          showOptions: false,
          isFocused: false,
        });
      }
    }
  }

  removeFocusState() {
    this.props.onBlur();
    if (!this.props.options) {
      this.setState({
        isFocused: false,
      });
    }
  }

  returnStateClass() {
    if (this.state.isFocused) return ' banana-input--focused';
    return '';
  }

  returnIfTextExists() {
    if (this.state.isFocused || this.state.value || this.props.textEnabledByDefault) return ' banana-input--text-exists';
    return '';
  }

  handleChange(event) {
    if (this.props.editable) {
      if (this.props.filter === 'numeric') {
        if (!Number.isNaN(event.target.value)) {
          this.setState({ value: event.target.value });
        }
      } else {
        this.setState({ value: event.target.value });
      }
    }
  }

  chooseOption(event, option, text) {
    event.stopPropagation();
    let actualValue = option;
    Object.keys(this.props.options).map((availableOption) => {
      if (`${this.props.options[availableOption].variant}${this.props.options[availableOption].text}` === `${option}${text}`) {
        actualValue = this.props.options[availableOption].text;
      }
    });
    this.setState({
      value: actualValue,
      showOptions: false,
      isFocused: false,
    });
  }

  removeFocusFromOptions() {
    this.setState({
      showOptions: false,
      isFocused: false,
    });
  }

  keyPressHandler(evt) {
    if (evt.key === 'Tab') {
      this.setState({ isFocused: false, showOptions: false });
    } else if (evt.key === 'Enter' && this.props.onSubmitEditing) this.props.onSubmitEditing();
  }

  renderText() {
    if (this.props.text) {
      return <div className={`banana-input__text ${this.props.textClass}`}>{this.props.text}</div>;
    }
    return null;
  }

  renderSingleOption(option, key) {
    return (
      <div
        role="option"
        aria-selected
        aria-hidden
        key={key}
        onClick={(event) => {
          this.chooseOption(event, this.props.options[option].variant, this.props.options[option].text);
        }}
        className="banana-input__options__option"
      >
        {this.props.options[option].text} {this.props.options[option].additionalText}
      </div>
    );
  }

  renderOptions() {
    let optionsArray = [];
    if (this.props.options && this.state.showOptions) {
      optionsArray = Object.keys(this.props.options).map((option, key) => {
        if (this.props.options[option].text) {
          if (this.props.editable) {
            if (this.props.options[option].text.toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0) {
              return this.renderSingleOption(option, key);
            }
          } else {
            return this.renderSingleOption(option, key);
          }
        }
      });
      return (
        <div className="banana-input__options" onBlur={this.removeFocusFromOptions}>
          {optionsArray}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={`banana-input ${this.returnStateClass()} ${this.returnIfTextExists()} ${this.props.inputClass}`}>
        <input
          value={this.state.value}
          type={this.props.type}
          onKeyDown={this.keyPressHandler}
          onFocus={this.setFocusState}
          onBlur={this.removeFocusState}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          ref={(input) => {
            if (input) {
              if (this.props.setFocus) input.focus();
              if (this.props.onRefAdd) this.props.onRefAdd(input);
            }
          }}
        />
        {this.renderText()}
        {this.renderOptions()}
      </div>
    );
  }
}

BananaInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  onChangeAction: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  disabled: PropTypes.bool,
  initialValue: PropTypes.bool,
  filter: PropTypes.string,
  updatedOuside: PropTypes.bool,
  setFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  inputClass: PropTypes.string,
  textClass: PropTypes.string,
  type: PropTypes.string,
  textEnabledByDefault: PropTypes.bool,
  onSubmitEditing: PropTypes.func,
  onRefAdd: PropTypes.func,
};

BananaInput.defaultProps = {
  value: '',
  text: '',
  editable: true,
  options: null,
  disabled: false,
  initialValue: false,
  filter: null,
  updatedOuside: true,
  textEnabledByDefault: false,
  setFocus: false,
  onBlur: () => {},
  onChangeAction: () => {},
  inputClass: '',
  textClass: '',
  type: '',
  onSubmitEditing: () => {},
  onRefAdd: () => {},
};

export default onClickOutside(BananaInput);
