import { loginWithAuth0 } from 'helpers/auth0';
import { I18n } from 'react-redux-i18n';

import { setErrorMessage, toggleLoading, setLoginState } from 'app-redux/actions/app-actions';

export function loginActionChangeEmail(email) {
  return {
    type: 'LOGIN_ACTION_CHANGE_EMAIL',
    email,
  };
}

export function loginActionChangePassword(password) {
  return {
    type: 'LOGIN_ACTION_CHANGE_PASSWORD',
    password,
  };
}

export function loginActionSignIn(email, password) {
  return async (dispatch) => {
    try {
      dispatch(toggleLoading(true));
      dispatch(setErrorMessage());
      const response = await loginWithAuth0(email, password);
      const responseJSON = await response.json();
      if (response.status !== 200) {
        if (responseJSON.error_description) {
          dispatch(setErrorMessage(responseJSON.error_description));
        } else if (responseJSON.error) {
          dispatch(setErrorMessage(responseJSON.error_description));
        } else {
          dispatch(setErrorMessage(I18n.t('errorCode.404')));
        }
      } else {
        dispatch(loginActionChangeEmail(''));
        dispatch(loginActionChangePassword(''));
        dispatch(
          setLoginState('main', {
            idToken: responseJSON.id_token,
            refreshToken: responseJSON.refresh_token,
            accessToken: responseJSON.access_token,
          })
        );
        dispatch(setErrorMessage());
      }
    } catch (error) {
      dispatch(setErrorMessage(I18n.t('errorCode.404')));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
}
