import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaCheckbox } from 'banana-framework';
import { Translate } from 'react-redux-i18n';

export default class checkboxFilter extends Component {
  checkIfChecked(value) {
    return this.props.enabledArray.some((currentValue) => currentValue === value);
  }

  render() {
    return (
      <div className="all-rentals__checkbox-cont">
        <div className="all-rentals__title">{this.props.title}</div>
        {this.props.filterArray.map((filter) => (
          <div className="all-rentals__checkbox" key={filter}>
            <BananaCheckbox
              checked={this.checkIfChecked(filter)}
              value={<Translate value={`allRentals.${filter}`} />}
              checkedFunc={() => {
                this.props.checkedFunc(filter);
              }}
              uncheckedFunc={() => {
                this.props.uncheckedFunc(filter);
              }}
            />
          </div>
        ))}
      </div>
    );
  }
}

checkboxFilter.propTypes = {
  enabledArray: PropTypes.array.isRequired,
  filterArray: PropTypes.array.isRequired,
  checkedFunc: PropTypes.func.isRequired,
  uncheckedFunc: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
};
