import React from 'react';
import { Provider } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import 'font-awesome/css/font-awesome.min.css';

import './App.scss';

import configureStore from 'app-redux/configure-store';
import EntryComponent from 'components/containers/entry-component';

export const App: React.FC = () => (
  <Provider store={configureStore()}>
    <EntryComponent />
  </Provider>
);
