import standardRequest from 'helpers/backend-requests';
import { I18n } from 'react-redux-i18n';

import {
  setErrorMessage,
  resetAppState,
  toggleLoading,
  toggleDialogWindow,
  renderNotification,
} from 'app-redux/actions/app-actions';

export const sharedResetAppState = resetAppState;

function saveNotReadyOrders(mockData) {
  return {
    type: 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_NOT_READY_ORDERS',
    orders: mockData.orders,
  };
}

function saveShipmentsAssignedToSelectedOrder(mockData) {
  return {
    type: 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_SHIPMENTS_ASSIGNED_TO_SELECTED_ORDER',
    shipmentsAssignedToSelectedOrder: mockData.shipments,
  };
}

function saveUnassignedShipments(mockData) {
  return {
    type: 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_UNASSIGNED_SHIPMENTS',
    unassignedShipments: mockData.shipments,
  };
}

function saveSelectedOrder(orderID) {
  return {
    type: 'ASSIGN_SHIPMENTS_TO_GOODS_ORDER_SAVE_SELECTED_ORDER',
    selectedOrder: orderID,
  };
}

export function getNotReadyOrders() {
  const path = '/goods_orders/not_ready';
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(saveNotReadyOrders(response));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}

export function getShipmentsAssignedToOrder(orderID) {
  const path = `/goods_orders/${orderID}/assigned_shipments`;
  return (dispatch) => {
    standardRequest(path, 'GET').then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(saveShipmentsAssignedToSelectedOrder(response));
      }
    });
  };
}

export function getUnassignedShipments() {
  const path = '/shipment_details/without_assigned_order';
  return (dispatch) => {
    standardRequest(path, 'GET').then((response) => {
      if (response.error) {
        dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
      } else {
        dispatch(saveUnassignedShipments(response));
      }
    });
  };
}

export function updateAssignShipmentsToGoodsOrderSelectedOrder(orderID) {
  return (dispatch) => {
    dispatch(toggleLoading(true));
    dispatch(saveSelectedOrder(orderID));
    dispatch(getShipmentsAssignedToOrder(orderID));
    dispatch(toggleLoading(false));
  };
}

export function assignShipmentToGoodsOrder(shipment, order, selectedOrder) {
  let path;
  if (order) {
    path = `/shipment_details/${shipment}/assign_to_order/${order}`;
  } else {
    path = `/shipment_details/${shipment}/unassign_from_order`;
  }
  return (dispatch) => {
    dispatch(toggleLoading(true));
    standardRequest(path, 'POST', {})
      .then((response) => {
        if (response.error === 422) {
          dispatch(
            toggleDialogWindow({
              isVisibleDialogWindow: true,
              applyFunc: null,
              dialogTitle: 'Something went wrong.',
              applyText: 'OK',
            })
          );
        } else if (response.error) {
          dispatch(setErrorMessage(I18n.t(`errorCode.${response.error}`)));
        } else {
          dispatch(getShipmentsAssignedToOrder(selectedOrder));
          dispatch(getUnassignedShipments());
          dispatch(renderNotification('The shipment was successfully assigned to the order'));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
