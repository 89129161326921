import { setLocale } from 'react-redux-i18n';
import { checkLoginState, setLoginTokens, removeLoginTokens } from 'helpers/login';
import standardRequest from 'helpers/backend-requests';
import { auth0Logout } from 'helpers/auth0';

export function getLoginState() {
  return {
    type: 'CHANGE_APP_STATE',
    appState: checkLoginState(),
  };
}

export function setLoginState(state, tokens) {
  setLoginTokens(tokens);
  return {
    type: 'CHANGE_APP_STATE',
    appState: 'main',
  };
}

export function removeLoginState() {
  auth0Logout();
  removeLoginTokens();
  return {
    type: 'CHANGE_APP_STATE',
    appState: 'login',
  };
}

export function setActiveHeaderComponent(component) {
  return {
    type: 'CHANGE_ACTIVE_HEADER_COMPONENT',
    activeHeaderComponent: component,
  };
}

export function toggleRouteMenuState(routeMenuBool) {
  return {
    type: 'TOGGLE_ROUTE_MENU_STATE',
    isRouteMenuOpened: routeMenuBool,
  };
}

export function setErrorMessage(error = '') {
  return {
    type: 'SET_ERROR_MESSAGE',
    error,
  };
}

export function toggleDialogWindow(dialogOptions) {
  const { isVisibleDialogWindow, applyFunc, dialogTitle, applyText, cancelText, customContent } = dialogOptions;
  return {
    type: 'TOGGLE_DIALOG_WINDOW',
    isVisibleDialogWindow,
    applyFunc,
    dialogTitle,
    applyText,
    cancelText,
    customContent,
  };
}

export const resetAppState = () => ({ type: 'RESET_APP_STATE' });

export function changeLocale(locale) {
  return function setLocaleFunc(dispatch) {
    dispatch(setLocale(locale));
  };
}

export function renderNotification(notification) {
  return {
    type: 'RENDER_NOTIFICATION',
    notification,
  };
}

export function toggleLoading(isLoading) {
  return {
    type: 'TOGGLE_LOADING',
    isLoading,
  };
}

export function createShipment(type, succFunc) {
  const path = `/shipment_details/create/${type}`;
  return function createShipmentRequest(dispatch) {
    dispatch(toggleLoading(true));
    standardRequest(path, 'GET')
      .then((response) => {
        if (response.error) {
          dispatch(setErrorMessage(response.error));
        } else if (response.id) {
          succFunc(response.id);
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };
}
