import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { appSettings } from 'constants/default-settings';

import './tool-tip.scss';

const ToolTip = (props) => {
  const [active, setActive] = useState(props.active);
  const timeoutRef = createRef();

  const clean = () => timeoutRef.current && clearTimeout(timeoutRef.current);

  // on mount
  useEffect(() => {
    return clean;
  }, []);

  const hide = () => {
    clean();
    setActive(false);
  };
  const show = () => {
    clean();
    setActive(true);
  };

  // on active change
  useEffect(() => {
    if (active) {
      clean();
      timeoutRef.current = setTimeout(hide, appSettings.HIDE_TOOLTIP);
    }
  }, [active]);

  return (
    <div className="toolTipWrapper">
      <div className={`tooltip ${props.classname}`} style={props.style} onMouseEnter={show} onMouseLeave={hide}>
        {props.children}
        {active && (
          <span className={`tooltiptext ${props.placement}`} style={{}}>
            {props.text}
          </span>
        )}
      </div>
    </div>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  active: PropTypes.bool.isRequired,
  classname: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

ToolTip.defaultProps = {
  classname: '',
  children: null,
  style: {},
};

export { ToolTip };
