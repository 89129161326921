import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BananaSwitcher extends Component {
  constructor(props) {
    super(props);
    this.clickFunc = this.clickFunc.bind(this);
  }

  clickFunc() {
    if (this.props.isActive) {
      this.props.finishFunc();
    } else {
      this.props.editFunc();
    }
  }

  renderProperIcon() {
    if (this.props.isActive) {
      return <i className="fa fa-check" aria-hidden="true" />;
    }
    return <i className="fa fa-pencil" aria-hidden="true" />;
  }

  render() {
    return (
      <button onClick={this.clickFunc} className="banana-switcher">
        {this.renderProperIcon()}
      </button>
    );
  }
}

BananaSwitcher.propTypes = {
  isActive: PropTypes.bool,
  editFunc: PropTypes.func.isRequired,
  finishFunc: PropTypes.func.isRequired,
};

BananaSwitcher.defaultProps = {
  isActive: false,
};
