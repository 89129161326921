import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaDatepicker, BananaInput, BananaCheckbox, BananaButton } from 'banana-framework';
import { Translate } from 'react-redux-i18n';
import './new-mock-form.scss';

export default class NewMockForm extends Component {
  constructor(props) {
    super(props);
    this.saveNewMock = this.saveNewMock.bind(this);
  }

  prepareLocations() {
    return this.props.types.map((type) => ({
      text: type.rental_type,
      variant: type.id,
    }));
  }

  saveNewMock() {
    this.props.saveNewMock({
      rentalDate: this.props.rentalDate,
      battery: this.props.battery,
      rentalType: this.props.choosenType,
      location: this.props.defaultLocation.id,
      noLateFee: this.props.lateFee,
    });
  }

  render() {
    return (
      <div>
        <div className="new-mock-form block block--padded">
          <h2 className="title title--medium title--center">
            <Translate value="addNewMockRental.addmockRental" />
          </h2>
          <div className="new-mock-form__element-text new-mock-form__element-text--first">
            <BananaDatepicker
              withTime
              startDate={this.props.rentalDate}
              dataChange={this.props.updateNewMockRentalDate}
              text={<Translate value="addNewMockRental.rentalDate" />}
              initialValue
            />
          </div>
          <div className="new-mock-form__element-text">
            <BananaInput
              filter="numeric"
              value={this.props.battery}
              onChangeAction={this.props.updateNewMockBattery}
              text={<Translate value="addNewMockRental.battery" />}
              initialValue
            />
          </div>
          <div className="new-mock-form__element-text">
            <BananaInput
              value={this.props.choosenType}
              options={this.prepareLocations()}
              editable={false}
              onChangeAction={this.props.updateNewMockType}
              text={<Translate value="addNewMockRental.type" />}
              initialValue
            />
          </div>
          <div className="new-mock-form__element-text">
            <BananaInput
              value={this.props.defaultLocation.location_name}
              editable={false}
              text={<Translate value="addNewMockRental.location" />}
              initialValue
            />
          </div>
          <div className="new-mock-form__element-text">
            <BananaCheckbox
              value={<Translate value="addNewMockRental.lateFee" />}
              checked={this.props.lateFee}
              checkedFunc={() => {
                this.props.changeNewMockLateFee(true);
              }}
              uncheckedFunc={() => {
                this.props.changeNewMockLateFee(false);
              }}
            />
          </div>
          <div className="new-mock-form__save-button">
            <BananaButton
              disabled={!(this.props.battery >= 0 && this.props.battery !== '' && this.props.battery !== null)}
              onClick={this.saveNewMock}
            />
          </div>
        </div>
      </div>
    );
  }
}

NewMockForm.propTypes = {
  rentalDate: PropTypes.object.isRequired,
  updateNewMockRentalDate: PropTypes.func.isRequired,
  updateNewMockBattery: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  updateNewMockType: PropTypes.func.isRequired,
  choosenType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  battery: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultLocation: PropTypes.object.isRequired,
  lateFee: PropTypes.bool.isRequired,
  changeNewMockLateFee: PropTypes.func.isRequired,
  saveNewMock: PropTypes.func.isRequired,
};

NewMockForm.defaultProps = {
  choosenType: 0,
  battery: null,
};
