import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaInput, BananaCheckbox } from 'banana-framework';
import { Translate } from 'react-redux-i18n';
import { objectifyDate } from 'helpers/global-methods';
import './assign-shipments-to-goods-order-form.scss';

export default class AssignShipmentsToGoodsOrderForm extends Component {
  constructor(props) {
    super(props);

    this.shipmentSelected = this.shipmentSelected.bind(this);
    this.shipmentUnselected = this.shipmentUnselected.bind(this);
  }

  prepareOrders() {
    return this.props.orders.map((order) => {
      const deliveryDate = order.deliveryDatetime ? objectifyDate(order.deliveryDatetime).mmddyy : '?';
      return {
        text: `Order ${order.partnerOrderId} (${order.partnerSlug}, ${order.quantityBoxes} boxes, delivery on ${deliveryDate})`,
        variant: order.orderId,
      };
    });
  }

  shipmentSelected(id) {
    this.props.assignShipmentToGoodsOrder(id, this.props.selectedOrder, this.props.selectedOrder);
  }

  shipmentUnselected(id) {
    this.props.assignShipmentToGoodsOrder(id, null, this.props.selectedOrder);
  }

  render() {
    const shipmentCheckboxes = this.props.shipments.map((shipment) => {
      const { id } = shipment;
      const boxes = shipment.number_of_boxes;
      const batteries = shipment.number_of_batteries;
      const createdAt = objectifyDate(shipment.created_at).mmddyy;
      return (
        <div key={shipment.id}>
          <BananaCheckbox
            key={shipment.id}
            value={`Shipment #${id} (${boxes} boxes, ${batteries} batteries, created on ${createdAt})`}
            disabled={this.props.selectedOrder === 0}
            checked={shipment.goods_order_id !== null}
            checkedFunc={() => this.shipmentSelected(shipment.id)}
            uncheckedFunc={() => this.shipmentUnselected(shipment.id)}
          />
          <br />
        </div>
      );
    });

    return (
      <div>
        <div className="assign-shipments-to-goods-order-form block block--padded">
          <h2 className="title title--medium title--center">
            <Translate value="assignShipmentsToGoodsOrder.title" />
          </h2>
          {/* eslint-disable-next-line max-len */}
          <div className="assign-shipments-to-goods-order-form__element-text assign-shipments-to-goods-order-form__element-text--first">
            <BananaInput
              editable={false}
              value={this.props.selectedOrder}
              initialValue
              options={this.prepareOrders()}
              text="Order"
              onChangeAction={this.props.updateAssignShipmentsToGoodsOrderSelectedOrder}
            />
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className="assign-shipments-to-goods-order-form__element-text assign-shipments-to-goods-order-form__element-text--first">
            {shipmentCheckboxes}
          </div>
        </div>
      </div>
    );
  }
}

AssignShipmentsToGoodsOrderForm.propTypes = {
  orders: PropTypes.array.isRequired,
  selectedOrder: PropTypes.number.isRequired,
  shipments: PropTypes.array.isRequired,

  assignShipmentToGoodsOrder: PropTypes.func.isRequired,
  updateAssignShipmentsToGoodsOrderSelectedOrder: PropTypes.func.isRequired,
};

AssignShipmentsToGoodsOrderForm.defaultProps = {};
