import React from 'react';
import PropTypes from 'prop-types';

/**
 * Banana Table
 * @param {children} props AnyChildren
 */
export const BananaTableV2 = (props) => (
  <div className={`${props.className}`} style={{ display: 'flex' }}>
    {props.children}
  </div>
);
BananaTableV2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};
BananaTableV2.defaultProps = {
  children: null,
  className: '',
};

/**
 * Banana Table Cell
 * @param {children} props AnyChildren
 */
export const BananaTableCellV2 = (props) => (
  <div className={`${props.className}`} style={{ flex: 1 }}>
    {props.children}
  </div>
);
BananaTableCellV2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
BananaTableCellV2.defaultProps = {
  children: null,
  className: '',
};
