import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { BananaSwitcher, BananaInputButton } from 'banana-framework';
import {
  BananaV2InputElement,
  BananaV2LocationOptionsElement,
  BananaTableV2,
  BananaTableCellV2,
  TabFocus,
} from 'banana-framework-v2';
import { BoxType, CableDataType, ShipmentType } from 'components/containers/shipment/shipment-data';
import { usePrevious } from 'helpers/hooks/use-previous';

import BoxCable from './box-cable';
import BoxBatteries from './box-batteries';

import { Utils } from '../utils';

import './box-details.scss';

type ResErrorType = {
  error: unknown;
  data: { errors: string };
};

interface Props {
  box: BoxType;
  updateBoxId: (id: number, shipmentId: number, newBoxIdentifier: string, externalId: number) => Promise<unknown>;
  updateBoxExpirationDate: (id: number, shipmentId: number, newExpirationDate: string) => Promise<boolean>;
  updateLogistaBoxIdentifier: (boxId: number, shipmentId: number, identifier: string) => Promise<unknown>;
  updateCableDataRequest: (box: BoxType, shipmentId: number, cableData: CableDataType) => Promise<boolean>;
  addNewBattereRequest: (boxId: number, shipmentId: number, battereId: string) => Promise<unknown>;
  deleteBatteryRequest: (boxId: number, shipmentId: number, battereId: string) => Promise<unknown>;
  deleteBoxRequest: (boxId: number, shipmentId: number, externalId: number) => Promise<unknown>;
  resetFocus: () => void;
  toggleDialogWindow: (opts: { isVisibleDialogWindow: boolean; applyFunc: VoidFunction }) => void;

  setNewFocusedBox?: (boxId: number) => void;

  shipment: ShipmentType;
  setBeforeHQShipmentDetailId?: (newShipmentDetailId: string) => any;
  setExternalBoxCode?: (newCode: string) => void;
  getAvailableLocations?: () => void;

  focused?: boolean;
  focusedBoxId?: string;
}

export const BoxDetails: React.FC<Props> = ({
  box,
  shipment,

  // functions
  updateBoxId,
  deleteBoxRequest,
  toggleDialogWindow,
  addNewBattereRequest,
  deleteBatteryRequest,
  updateCableDataRequest,
  updateLogistaBoxIdentifier,

  // optionals
  focused = false,
  resetFocus = () => {},
  setNewFocusedBox = () => {},
  setExternalBoxCode = () => {},
  getAvailableLocations = () => {},
  setBeforeHQShipmentDetailId = () => {},
}) => {
  /**
   * State
   */
  const [cablesActive, setCablesActive] = useState(false);
  const [boxIdActive, setBoxIdActive] = useState(false);
  const [cableData, setCableData] = useState(box.cable_data);

  // loading
  const [isLoading, setIsLoading] = useState(false);
  const [cablesLoading, setCablesLoading] = useState(false);
  const [batteriesLoading, setBatteriesLoading] = useState(false);

  // ready
  const [ready, setRedy] = useState(false);

  // errors
  const [boxIdError, setBoxIdError] = useState<JSX.Element>();
  const [logistaBoxIdentifierError, setLogistaBoxIdentifierError] = useState<JSX.Element>();
  const [batteryError, setBatteryError] = useState<JSX.Element>();

  // Previous box
  const prevBox = usePrevious(box);

  useEffect(() => {
    if (box.id !== prevBox?.id) setBoxIdActive(false);

    // if (prevBox?.created && !ready) { // TODO
    if (false && !ready) {
      setTimeout(() => setRedy(true), 200);
    } else {
      setRedy(true);
    }
  }, [box]);

  const setExternalBoxCodeHandler = (newCode: string) => {
    if (!ready) return;
    setExternalBoxCode(newCode);
  };

  const setBeforeHQShipmentDetailIdHandler = (newBeforeHQShipmentDetailId: string) => {
    if (!ready) return;
    setBeforeHQShipmentDetailId(newBeforeHQShipmentDetailId);
  };

  const updateError = (key: 'boxIdError' | 'logistaBoxIdentifierError' | 'batteryError', error: string, barcode = '') => {
    let setterFunc: (el: JSX.Element) => void | undefined;
    switch (key) {
      case 'batteryError':
        setterFunc = setBatteryError;
        break;
      case 'boxIdError':
        setterFunc = setBoxIdError;
        break;
      case 'logistaBoxIdentifierError':
        setterFunc = setLogistaBoxIdentifierError;
    }
    if (setterFunc) setterFunc(<Translate value={error} barcode={barcode} />);
  };

  const switchBoxStatus = () => {
    if (boxIdActive) updateError('boxIdError', '');
    setBoxIdActive(!boxIdActive);
  };

  const switchCablesStatus = async () => {
    if (cablesActive) {
      setCablesLoading(true);
      try {
        await updateCableDataRequest(box, shipment.id, cableData);
      } catch (e) {
        console.error('BoxDetails::switchCablesStatus updateCableDataRequest errorer: ', e);
      }
      setCablesLoading(false);
    }
    setCablesActive(!cablesActive);
  };

  const updateCableData = <K extends keyof CableDataType>(dataId: K, newValue: CableDataType[K]) => {
    const newCableData = JSON.parse(JSON.stringify(cableData));
    newCableData[dataId] = newValue;
    setCableData(newCableData);
  };

  const addNewBattere = async (battereId: string) => {
    updateError('batteryError', '');
    setBatteriesLoading(true);

    try {
      let response = (await addNewBattereRequest(box.id, shipment.id, battereId)) as ResErrorType;
      if (response.error) updateError('batteryError', response.data.errors);
    } catch (e) {
      console.error('BoxDetails::addNewBattere addNewBattereRequest errorer: ', e);
    }
    setBatteriesLoading(false);
  };

  const deleteBattery = (batteryId: string) => {
    const deleteFunc = () => {
      deleteBatteryRequest(box.id, shipment.id, batteryId).then(() => {
        setNewFocusedBox(box.box_external_id);
      });
    };
    toggleDialogWindow({
      isVisibleDialogWindow: true,
      applyFunc: deleteFunc,
    });
  };

  const deleteBox = () => {
    const deleteFunc = async () => {
      setIsLoading(true);
      try {
        await deleteBoxRequest(box.id, shipment.id, box.box_external_id);
      } catch (e) {
        console.error('BoxDetails::deleteBox deleteFunc errorer: ', e);
      }
      setIsLoading(false);
    };
    toggleDialogWindow({
      isVisibleDialogWindow: true,
      applyFunc: deleteFunc,
    });
  };

  // was updateBoxId()
  const updateBoxIdHandler = async (boxId: string) => {
    updateError('boxIdError', '');
    const response = (await updateBoxId(box.id, shipment.id, boxId, box.box_external_id)) as ResErrorType;
    if (response.error) updateError('boxIdError', response.data.errors);
  };

  const updateLogistaBoxIdentifierHandler = async (identifier: string) => {
    updateError('logistaBoxIdentifierError', '');
    try {
      const response = (await updateLogistaBoxIdentifier(box.id, shipment.id, identifier)) as ResErrorType;
      if (response.error) updateError('logistaBoxIdentifierError', response.data.errors);
      else {
        // Focus next
        TabFocus.getInstance().focus(`addBox_${box.box_external_id}`);
      }
    } catch (e) {
      console.error('BoxDetails::updateLogistaBoxIdentifierHandler updateLogistaBoxIdentifier errorer: ', e);
    }
  };

  const changeLogistaBoxIdentifierResponse = undefined; // was not found

  return (
    <div
      className={`box-details ${isLoading ? 'element--is-loading' : ''}`}
      ref={(el) => {
        if (el && focused) {
          el.scrollIntoView(true);
        }
      }}
    >
      <div className="box-details__title" style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          #{box.id} ({box.box_external_id})
          <div className="box-details__edit-button">
            <BananaSwitcher editFunc={switchBoxStatus} finishFunc={switchBoxStatus} isActive={boxIdActive} />
          </div>
        </div>

        {shipment.shipmentType === 'return' && (
          <BananaV2InputElement
            label={`${I18n.t('newBox.7days')} ${I18n.t('global.ifAvailable')}`}
            value={box.logistics_partner_thingable_identifier || ''}
            type="text"
            onSubmitEditing={setExternalBoxCodeHandler}
            onBlur={setExternalBoxCodeHandler}
            pencil
            baseClass="logisticsCode"
          />
        )}
        <button className="box-details__delete-box" onClick={deleteBox}>
          <i className="fa fa-trash-o" aria-hidden="true" />
        </button>
      </div>

      {boxIdActive && (
        <div className="box-details__id-editor">
          <BananaInputButton text={I18n.t('shipmentBox.newBattereId')} saveFunc={updateBoxIdHandler} error={boxIdError} />
        </div>
      )}

      {Utils.isLogista(shipment.shipmentSummary) && (
        <div className="box-details__logista">
          <h3 className="box-details__logista__title">Logista Box identifier</h3>
          <BananaInputButton
            value={box.logista_box_identifier || ''}
            text="Enter Logista Box identifier"
            initialValue
            saveFunc={updateLogistaBoxIdentifierHandler}
            error={logistaBoxIdentifierError}
            successfulResponse={changeLogistaBoxIdentifierResponse}
            onRefAdd={(ref: HTMLInputElement) => {
              TabFocus.getInstance().add(`addLogista_${box.box_external_id}`, ref);
              return () => {
                TabFocus.getInstance().remove(`addLogista_${box.box_external_id}`);
              };
            }}
          />
        </div>
      )}

      <BananaTableV2>
        <BananaTableCellV2 className="box-details__cables__title" style={{ paddingBottom: 10 }}>
          <div className="box-details__cables" style={{ paddingBottom: 20 }}>
            {shipment.shipmentType === 'delivery' && (
              <React.Fragment>
                <div className="box-details__cables__title">
                  <Translate value="cables.cables" />
                  <div className="box-details__edit-button">
                    <BananaSwitcher editFunc={switchCablesStatus} finishFunc={switchCablesStatus} isActive={cablesActive} />
                  </div>
                </div>
                <BoxCable
                  cable={box.cable_data}
                  shipmentType={shipment.shipmentType}
                  isActive={cablesActive}
                  isLoading={cablesLoading}
                  updateCableData={updateCableData}
                />
              </React.Fragment>
            )}
          </div>
        </BananaTableCellV2>
        <BananaTableCellV2 className="box-details__cables__title">
          {shipment.shipmentType === 'return' && (
            <div className="box-details__cables">
              <BananaV2LocationOptionsElement
                label={I18n.t('newBox.location')}
                onChange={setBeforeHQShipmentDetailIdHandler}
                getOptions={getAvailableLocations}
                location={box.before_hq_location}
                onSubmitEditing={setBeforeHQShipmentDetailIdHandler}
                onBlur={setBeforeHQShipmentDetailIdHandler}
                style={{ paddingBottom: 20 }}
              />
            </div>
          )}
        </BananaTableCellV2>
      </BananaTableV2>

      <BoxBatteries
        boxId={box.box_external_id}
        addNewBattere={addNewBattere}
        deleteBattery={deleteBattery}
        batteries={box.battery_ids}
        error={batteryError}
        resetFocus={resetFocus}
        isLoading={batteriesLoading}
      />
    </div>
  );
};
