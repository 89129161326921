import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AllRentalsTotal, AllRentalsFilter, AllRentalsContent, AllRentalsList } from 'components/presentational/all-rentals';
import * as allRentalsActions from './all-rentals-actions';

class AllRentals extends Component {
  constructor(props) {
    super(props);
    this.handleChanges = this.handleChanges.bind(this);
    this.getRentals = this.getRentals.bind(this);
  }

  componentDidMount() {
    this.props.actions.sharedResetAppState();
    this.props.actions.getRentalsStats();
    this.getRentals();
  }

  getRentals() {
    this.props.actions.getAllRentals({
      search: this.props.search,
      searchLocation: this.props.searchLocation,
      status: this.props.status,
      rentalType: this.props.rentalType,
      user: this.props.user,
      dateRange: this.props.dateRange,
      page: this.props.page,
      country: this.props.country,
    });
  }

  handleChanges(changeType) {
    const dispatchGetRentals = (promise) => {
      promise.then(() => {
        this.getRentals();
      });
    };

    switch (changeType) {
      case 'search':
        return (search) => {
          dispatchGetRentals(this.props.actions.allRentalsChangeSearchText(search));
        };
      case 'searchLocation':
        return (searchLocation) => {
          dispatchGetRentals(this.props.actions.allRentalsChangeSearchLocationText(searchLocation));
        };
      case 'dateRange':
        return (dateRange) => {
          dispatchGetRentals(this.props.actions.allRentalsChangeDateRange(dateRange));
        };
      case 'addRentalsType':
        return (rentalType) => {
          dispatchGetRentals(this.props.actions.updateFiltringArray('rentalType', rentalType));
        };
      case 'removeRentalsType':
        return (rentalType) => {
          dispatchGetRentals(this.props.actions.filterFiltringArray('rentalType', rentalType));
        };
      case 'addUser':
        return (user) => {
          dispatchGetRentals(this.props.actions.updateFiltringArray('user', user));
        };
      case 'removeUser':
        return (user) => {
          dispatchGetRentals(this.props.actions.filterFiltringArray('user', user));
        };
      case 'addStatus':
        return (status) => {
          dispatchGetRentals(this.props.actions.updateFiltringArray('status', status));
        };
      case 'removeStatus':
        return (status) => {
          dispatchGetRentals(this.props.actions.filterFiltringArray('status', status));
        };
      case 'changePage':
        return (page) => {
          dispatchGetRentals(this.props.actions.allRentalsSetActiveFilterPage(page));
        };
      case 'changeCountry':
        return (country) => {
          dispatchGetRentals(this.props.actions.allRentalsSetCountry(country));
        };
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        <AllRentalsTotal stats={this.props.rentalsStats} />
        <AllRentalsContent>
          <AllRentalsList
            rentals={this.props.rentalsData.rentals}
            page={this.props.page}
            totalPages={this.props.rentalsData.totalPages}
            setActiveElement={this.handleChanges('changePage')}
          />
          <AllRentalsFilter
            search={this.props.search}
            searchLocation={this.props.searchLocation}
            dateRange={this.props.dateRange}
            handleChanges={this.handleChanges}
            user={this.props.user}
            status={this.props.status}
            rentalType={this.props.rentalType}
            country={this.props.country}
            activeCountries={this.props.rentalsData.activeCountries}
          />
        </AllRentalsContent>
      </div>
    );
  }
}

AllRentals.propTypes = {
  search: PropTypes.string.isRequired,
  searchLocation: PropTypes.string.isRequired,
  rentalType: PropTypes.array.isRequired,
  user: PropTypes.array.isRequired,
  status: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  dateRange: PropTypes.string.isRequired,
  rentalsData: PropTypes.object.isRequired,
  rentalsStats: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
};

function mapStateToProps({ allRentalsData }) {
  return {
    search: allRentalsData.search,
    searchLocation: allRentalsData.searchLocation,
    rentalType: allRentalsData.rentalType,
    user: allRentalsData.user,
    status: allRentalsData.status,
    dateRange: allRentalsData.dateRange,
    rentalsData: allRentalsData.rentalsData,
    rentalsStats: allRentalsData.rentalsStats,
    page: allRentalsData.page,
    country: allRentalsData.country,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(allRentalsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRentals);
