import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { objectifyDate } from 'helpers/global-methods';

export default class GoodsOrderTableBody extends Component {
  returnBody() {
    if (this.props.data) {
      return this.props.data.map((element) => {
        return (
          <div className="table__element" key={element.orderId}>
            <div className="table__element__cell table-element__cell--numeric">{element.orderId}</div>
            <div className="table__element__cell">{element.partnerSlug}</div>
            <div className="table__element__cell">{element.partnerOrderId}</div>
            <div className="table__element__cell">{`${objectifyDate(element.deliveryDatetime).dateTime}`}</div>
            <div className="table__element__cell">{element.status}</div>
            <div className="table__element__cell">
              {element.mainQuantity} {element.mainUnit}
            </div>
            <div className="table__element__cell">{`${(element.progress * 100).toFixed(0).toString()}%`}</div>

            <div className="table__element__cell">
              <div className="table__element__actions-box">
                {/* <button
                  className="table__element__button"
                  onClick={() => { this.props.itemActions.markOrderAsPlanned(element.orderId); }}
                >
                  <i className="fa fa-hourglass-half" aria-hidden="true" />
                </button>
                <button
                  className="table__element__button"
                  onClick={() => { this.props.itemActions.markOrderAsReady(element.orderId); }}
                >
                  <i className="fa fa-check" aria-hidden="true" />
                </button>
                <button
                  className="table__element__button"
                  onClick={() => { this.props.itemActions.markOrderAsExecuted(element.orderId); }}
                >
                  <i className="fa fa-paper-plane" aria-hidden="true" />
                </button> */}
                <button
                  className="table__element__button"
                  onClick={() => {
                    this.props.viewAction(element.orderId);
                  }}
                >
                  <i className="fa fa-eye" aria-hidden="true" />
                </button>
                <Link to={`/goods_orders/${element.orderId}/edit`} className="table__element__button">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </Link>
                <button
                  className="table__element__button"
                  onClick={() => {
                    this.props.showDeletePopup(element.orderId);
                  }}
                >
                  <i className="fa fa-trash-o" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  }

  render() {
    return <div className="table__body">{this.returnBody()}</div>;
  }
}

GoodsOrderTableBody.propTypes = {
  data: PropTypes.array,
  showDeletePopup: PropTypes.func.isRequired,
  viewAction: PropTypes.func,
};

GoodsOrderTableBody.defaultProps = {
  data: [],
  viewAction: () => {},
};
