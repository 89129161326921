import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BananaTipButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };

    this.isButtonHovered = this.isButtonHovered.bind(this);
  }

  isButtonHovered(bool) {
    this.setState({ isHovered: bool });
  }

  renderAdditionalButtons() {
    if (this.state.isHovered) {
      return this.props.buttonContent.map((button) => (
        <button
          key={button.icon}
          className={`banana-tip-button__content
            banana-tip-button__content--minor
            banana-tip-button__content--${button.color}`}
          onClick={button.buttonFunc}
          title={button.title}
        >
          <i className={`fa ${button.icon}`} aria-hidden="true" />
        </button>
      ));
    }
    return null;
  }

  render() {
    return (
      <div
        onMouseOver={() => {
          this.isButtonHovered(true);
        }}
        onMouseLeave={() => {
          this.isButtonHovered(false);
        }}
        onFocus={() => {}}
        className="banana-tip-button"
      >
        <button className="banana-tip-button__content" onClick={this.props.firstAction}>
          <i className="fa fa-plus" aria-hidden="true" />
        </button>
        {this.renderAdditionalButtons()}
      </div>
    );
  }
}

BananaTipButton.propTypes = {
  buttonContent: PropTypes.array,
  firstAction: PropTypes.func,
};

BananaTipButton.defaultProps = {
  buttonContent: [],
  firstAction: () => {},
};
