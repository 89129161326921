import initialState from 'app-redux/reducers/initial-state';

const ValoraInvoicesReducer = (state = initialState.valoraInvoicesData, action) => {
  switch (action.type) {
    case 'VALORA_INVOICES_ACTIONS_SAVE_INVOICES':
      return { ...state, invoices: action.invoices };
    case 'VALORA_INVOICES_TOGGLE_PAID_STATUS':
      return { ...state, paidBool: action.paidBool };
    case 'VALORA_INVOICES_UPDATE_INVOICE':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          valora_invoices: state.invoices.valora_invoices.map((invoice) => {
            if (invoice.id === action.id) return { ...invoice, status: action.status };
            return invoice;
          }),
        },
      };
    case 'VALORA_INVOICES_REMOVE_PAID_INVOICES':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          valora_invoices: state.invoices.valora_invoices.filter((invoice) => invoice.status !== 'paid'),
        },
      };
    default:
      return state;
  }
};

export default ValoraInvoicesReducer;
