import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compareObjects } from 'helpers/global-methods';

// eslint-disable-next-line max-len
import AssignShipmentsToGoodsOrderForm from 'components/presentational/assign-shipments-to-goods-order/assign-shipments-to-goods-order-form';
import * as assignShipmentsToGoodsOrderActions from './assign-shipments-to-goods-order-actions';

class AssignShipmentsToGoodsOrder extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.match.params.id) {
      this.props.actions.updateAssignShipmentsToGoodsOrderSelectedOrder(parseInt(this.props.match.params.id, 10));
    }
  }

  componentDidMount() {
    this.props.actions.sharedResetAppState();

    if (!this.props.orders.length) {
      this.props.actions.getNotReadyOrders();
    }

    if (!this.props.unassignedShipments.length) {
      this.props.actions.getUnassignedShipments();
    }
  }

  render() {
    let allShipments = this.props.shipmentsAssignedToSelectedOrder.concat(this.props.unassignedShipments);
    allShipments = allShipments.sort(compareObjects('created_at')).reverse();

    return (
      <div>
        <AssignShipmentsToGoodsOrderForm
          orders={this.props.orders}
          selectedOrder={this.props.selectedOrder}
          shipments={allShipments}
          assignShipmentToGoodsOrder={this.props.actions.assignShipmentToGoodsOrder}
          updateAssignShipmentsToGoodsOrderSelectedOrder={this.props.actions.updateAssignShipmentsToGoodsOrderSelectedOrder}
        />
      </div>
    );
  }
}

AssignShipmentsToGoodsOrder.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,

  orders: PropTypes.array.isRequired,
  selectedOrder: PropTypes.number,
  unassignedShipments: PropTypes.array.isRequired,
  shipmentsAssignedToSelectedOrder: PropTypes.array.isRequired,
};

AssignShipmentsToGoodsOrder.defaultProps = {
  selectedOrder: 0,
};

function mapStateToProps({ assignShipmentsToGoodsOrderData }) {
  return {
    orders: assignShipmentsToGoodsOrderData.orders,
    selectedOrder: assignShipmentsToGoodsOrderData.selectedOrder,
    unassignedShipments: assignShipmentsToGoodsOrderData.unassignedShipments,
    shipmentsAssignedToSelectedOrder: assignShipmentsToGoodsOrderData.shipmentsAssignedToSelectedOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(assignShipmentsToGoodsOrderActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignShipmentsToGoodsOrder);
