/* eslint react/no-did-mount-set-state: "off" */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BananaPopup } from 'banana-framework';
import { LoginContent } from 'components/presentational/login-container/index';
import { RenderedErrorMessage, RenderedLoadingSpinner } from 'routes/components-registration';
import * as actions from './login-container-actions';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loginRendered: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({ loginRendered: true }));
    }, 0);
  }

  render() {
    return (
      <div>
        {!!this.props.error && <RenderedErrorMessage />}
        {!!this.props.isLoading && <RenderedLoadingSpinner />}
        <BananaPopup
          dialogTitle={null}
          popupClass={`login-container ${this.state.loginRendered ? 'login-container--rendered' : ''}`}
          customContent={
            <LoginContent
              loginActionChangeEmail={this.props.actions.loginActionChangeEmail}
              loginActionChangePassword={this.props.actions.loginActionChangePassword}
              loginActionSignIn={this.props.actions.loginActionSignIn}
              email={this.props.email}
              password={this.props.password}
            />
          }
        />
      </div>
    );
  }
}

LoginContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ loginData }) => ({
  email: loginData.email,
  password: loginData.password,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
