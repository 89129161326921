import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class ShipmentTableBody extends Component {
  returnBody() {
    if (this.props.data) {
      return this.props.data.map((element) => {
        const shippingDate = element.shipping_date ? element.shipping_date : '';
        return (
          <div className="table__element" key={element.id}>
            <div className="table__element__cell table-element__cell--numeric">{element.id}</div>
            <div className="table__element__cell">
              {element.to_location} {element.to_pos && <span>| {element.to_pos}</span>}
            </div>
            <div className="table__element__cell">
              {element.from_location} {element.from_pos && <span>| {element.from_pos}</span>}
            </div>
            <div className="table__element__cell table-element__cell--numeric">{shippingDate}</div>
            <div className="table__element__cell table-element__cell--numeric">{element.boxes}</div>
            <div className="table__element__cell table-element__cell--numeric">{element.batteries}</div>
            <div className="table__element__cell table-element__cell--numeric">{element.cables}</div>
            <div className="table__element__cell">
              <div className="table__element__actions-box">
                <Link to={`/shipment/edit/${element.id}`} className="table__element__button">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  }

  render() {
    return <div className="table__body">{this.returnBody()}</div>;
  }
}

ShipmentTableBody.propTypes = {
  data: PropTypes.array,
};

ShipmentTableBody.defaultProps = {
  data: [],
};
