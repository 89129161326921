import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { headerRoutes, defaultHeaderRoute } from 'constants/routes';
import './styles/battere-header.scss';

export default class MainHeader extends Component {
  moveHeader() {
    if (this.props.sharedProps.isRouteMenuOpened) return 'battere-header--opened';
    return '';
  }

  renderNavMenu() {
    return Object.keys(headerRoutes).map((route) => (
      <li
        className={`battere-nav__link
            ${route === this.props.sharedProps.activeHeaderComponent ? 'battere-nav__link--active' : ''}`}
        key={route}
      >
        <button
          className="battere-nav__link__button"
          onClick={() => {
            this.props.sharedActions.setActiveHeaderComponent(route);
          }}
        >
          {I18n.t(`mainHeader.${route}`)}
        </button>
      </li>
    ));
  }
  render() {
    return (
      <header className={`battere-header ${this.moveHeader()}`}>
        <button
          className="battere-header__nav-button"
          onClick={() => {
            this.props.sharedActions.toggleRouteMenuState(!this.props.sharedProps.isRouteMenuOpened);
          }}
        >
          <i className="fa fa-bars fa-1" aria-hidden="true" />
        </button>
        <nav className="battere-nav">
          <ul className="battere-nav__list">{this.renderNavMenu()}</ul>
        </nav>
        <button
          onClick={() => {
            this.props.sharedActions.setActiveHeaderComponent(defaultHeaderRoute);
          }}
          className="battere-header__logo"
        >
          Chimpy Banana{' '}
          <span aria-label="banana" role="img">
            🍌
          </span>
        </button>
      </header>
    );
  }
}

MainHeader.propTypes = {
  sharedActions: PropTypes.object.isRequired,
  sharedProps: PropTypes.object.isRequired,
};
