// /* eslint consistent-return: 0*/
// import Auth0Lock from 'auth0-lock';
// import { Auth0Settings } from '../constants/default-settings';

import moment from 'moment';
import auth0 from 'auth0-js';

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_CLIENT_ID,
});

const TOKEN_EXPIRATION_TIME = process.env.TOKEN_EXPIRATION_TIME || 35600;

export async function updateTokens() {
  try {
    const refreshToken = await localStorage.getItem('refreshToken');
    const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }),
    });
    const responseJson = await response.json();
    localStorage.setItem('accessToken', responseJson.access_token);
    localStorage.setItem('idToken', responseJson.id_token);
    localStorage.setItem('tokenSaveTime', moment().format('YYYY:MM:DD, hh:mm:ss a'));
    return {
      status: response.status,
      tokens: responseJson,
    };
  } catch (error) {
    return {
      status: 401,
      error,
    };
  }
  // try {
  //   const refreshToken = localStorage.getItem('refreshToken');
  //   const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/delegation`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       client_id: process.env.REACT_APP_CLIENT_ID,
  //       grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
  //       token_type: 'Bearer',
  //       refresh_token: refreshToken,
  //     }),
  //   });
  //   const responseJson = await response.json();
  //   localStorage.setItem('idToken', responseJson.id_token);
  //   return response;
  // } catch (error) {
  //   return {
  //     status: 401,
  //     error,
  //   };
  // }
}

export async function auth0Logout() {
  try {
    webAuth.logout({
      returnTo: `${window.location.href}`,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
  } catch (error) {
    throw error;
  }
}

export async function getAccessToken() {
  try {
    const currentAccessToken = localStorage.getItem('accessToken');
    const currentTokenSaveTime = localStorage.getItem('tokenSaveTime');
    if (
      !currentAccessToken ||
      !currentTokenSaveTime ||
      moment().diff(moment(currentTokenSaveTime, 'YYYY:MM:DD, hh:mm:ss a'), 'seconds') > TOKEN_EXPIRATION_TIME
    ) {
      const newIdTokenRequest = await updateTokens();
      return newIdTokenRequest.tokens.access_token;
    }
    return currentAccessToken;
  } catch (err) {
    throw err;
  }
}

export async function loginWithAuth0(username, password) {
  try {
    // await revokeRefreshToken();
    const auth0Domain = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;
    const response = await fetch(auth0Domain, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        connection: 'Username-Password-Authentication',
        grant_type: 'password',
        scope: 'openid offline_access',
        device: 'mydevice',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        username,
        password,
      }),
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
  // try {
  //   const auth0Domain = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;
  //   const response = await fetch(auth0Domain, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       client_id: process.env.REACT_APP_CLIENT_ID,
  //       connection: 'Username-Password-Authentication',
  //       grant_type: 'password',
  //       scope: 'openid offline_access',
  //       username,
  //       password,
  //     }),
  //   });
  //   return response;
  // } catch (error) {
  //   throw new Error(error);
  // }
}
