import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// @ts-ignore
import DialogWindow from 'components/presentational/shared/dialog-window';
// @ts-ignore
import ErrorMessage from 'components/presentational/shared/error-message';
// @ts-ignore
import Notification from 'components/presentational/shared/notification';
// @ts-ignore
import LoadingSpinner from 'components/presentational/shared/loading-spinner';

// @ts-ignore
import MainHeader from 'components/presentational/single/main-header';
// @ts-ignore
import RouteMenu from 'components/presentational/single/route-menu';
// @ts-ignore
import GreetingComponent from 'components/presentational/single/greeting-component';

// @ts-ignore
import ShipmentsOverview from 'components/containers/shipments/shipments-overview';
// @ts-ignore
import NewMockRental from 'components/containers/new-mock-rental/new-mock-rental';
// @ts-ignore
import GoodsOrdersOverview from 'components/containers/goods-orders-overview/goods-orders-overview';
// @ts-ignore
import NewGoodsOrder from 'components/containers/new-edit-goods-order/new-goods-order';
// @ts-ignore
import EditGoodsOrder from 'components/containers/new-edit-goods-order/edit-goods-order';
// @ts-ignore
import AssignShipmentsToGoodsOrder from 'components/containers/assign-shipments-to-goods-order/assign-shipments-to-goods-order';
// @ts-ignore
import ValoraInvoices from 'components/containers/valora-invoices/valora-invoices';
// @ts-ignore
import AllRentals from 'components/containers/all-rentals/all-rentals';
// @ts-ignore
import CreateShipment from 'components/containers/shipment/create-shipment';
import { EditShipment } from 'components/containers/shipment/shipment';

// @ts-ignore
import WrapperCreator from 'components/composite/actions-wrapper';

const requiredSharedData = [
  'login',
  'error',
  'locale',
  'header',
  'route-menu',
  'dialog',
  'shipment_creator',
  'loading',
  'notification',
];

const MainHeaderComponent = WrapperCreator(MainHeader, ['header', 'route-menu']);
const MainRouteMenuComponent = WrapperCreator(RouteMenu, ['header', 'route-menu', 'dialog', 'login', 'shipment_creator']);
const RenderedGreeting = WrapperCreator(GreetingComponent, [], true);
export const RenderedDialogWindow = WrapperCreator(DialogWindow, ['dialog']);
export const RenderedErrorMessage = WrapperCreator(ErrorMessage, ['error']);
export const RenderedLoadingSpinner = WrapperCreator(LoadingSpinner, ['loading']);
export const RenderedNotification = WrapperCreator(Notification, ['notification']);

interface Props {
  sharedProps: {
    isVisibleDialogWindow: unknown;
    error: unknown;
    isLoading: unknown;
    notification: unknown;
  };
}
const MainApp: React.FC<Props> = (props) => {
  return (
    <Router>
      <div>
        <MainHeaderComponent />
        <MainRouteMenuComponent />

        {!!props.sharedProps.isVisibleDialogWindow && <RenderedDialogWindow />}
        {!!props.sharedProps.error && <RenderedErrorMessage />}
        {!!props.sharedProps.isLoading && <RenderedLoadingSpinner />}
        {!!props.sharedProps.notification && <RenderedNotification />}

        <main className="battere-content">
          <Route exact path="/" component={RenderedGreeting} />
          <Route path="/shipments" component={ShipmentsOverview} />
          <Route exact path="/shipment/create/:shipmentType(delivery|return)" component={CreateShipment} />
          <Route exact path="/shipment/edit/:id" component={EditShipment} />
          <Route exact path="/rentals/new/mock" component={NewMockRental} />
          <Route exact path="/goods_orders" component={GoodsOrdersOverview} />
          <Route exact path="/goods_orders/new" component={NewGoodsOrder} />
          <Route exact path="/goods_orders/:id/edit" component={EditGoodsOrder} />
          <Route exact path="/goods_orders/:id/assign_shipments" component={AssignShipmentsToGoodsOrder} />
          <Route exact path="/goods_orders/assign_shipments" component={AssignShipmentsToGoodsOrder} />
          <Route exact path="/valora_invoices" component={ValoraInvoices} />
          <Route exact path="/rentals" component={AllRentals} />
        </main>
      </div>
    </Router>
  );
};

export default WrapperCreator(MainApp, requiredSharedData);
