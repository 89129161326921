import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BananaInputButton, BananaMultiswitcher } from 'banana-framework';
import { Translate } from 'react-redux-i18n';

import DateSwitcher from './all-rentals-filter/date-switcher';
import CheckboxFilter from './all-rentals-filter/checkbox-filter';

export default class AllRentalsFilter extends Component {
  constructor(props) {
    super(props);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.changeSearchLocationText = this.changeSearchLocationText.bind(this);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.addRentalsType = this.addRentalsType.bind(this);
    this.removeRentalsType = this.removeRentalsType.bind(this);
    this.addStatus = this.addStatus.bind(this);
    this.removeStatus = this.removeStatus.bind(this);
    this.addUser = this.addUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
    this.countryNameFromCode = this.countryNameFromCode.bind(this);
  }

  changeSearchText(value) {
    this.props.handleChanges('search')(value);
  }

  changeSearchLocationText(value) {
    this.props.handleChanges('searchLocation')(value);
  }

  changeDateRange(dateRange) {
    this.props.handleChanges('dateRange')(dateRange);
  }

  addRentalsType(type) {
    this.props.handleChanges('addRentalsType')(type);
  }

  removeRentalsType(type) {
    this.props.handleChanges('removeRentalsType')(type);
  }

  addUser(user) {
    this.props.handleChanges('addUser')(user);
  }

  removeUser(user) {
    this.props.handleChanges('removeUser')(user);
  }

  addStatus(status) {
    this.props.handleChanges('addStatus')(status);
  }

  removeStatus(status) {
    this.props.handleChanges('removeStatus')(status);
  }

  changeCountry(country) {
    this.props.handleChanges('changeCountry')(country);
  }

  // eslint-disable-next-line class-methods-use-this
  countryNameFromCode(code) {
    let name;
    switch (code) {
      case 'CH':
        name = 'Switzerland';
        break;
      case 'DE':
        name = 'Germany';
        break;
      case 'FR':
        name = 'France';
        break;
      case 'AT':
        name = 'Austria';
        break;
      case 'IT':
        name = 'Italy';
        break;
      default:
        name = 'Unknown';
    }

    return name;
  }

  render() {
    const switcherContent = [{ value: 'all', text: 'All' }];
    this.props.activeCountries.map((country) =>
      switcherContent.push({ value: country, text: this.countryNameFromCode(country) })
    );
    return (
      <div>
        <BananaInputButton
          value={this.props.search}
          saveFunc={this.changeSearchText}
          saveButtonText={<Translate value="global.searchButtonText" />}
          text="Battery identifier"
        />

        <BananaInputButton
          value={this.props.searchLocation}
          saveFunc={this.changeSearchLocationText}
          saveButtonText={<Translate value="global.searchButtonText" />}
          text="City or PoS name"
        />

        <BananaMultiswitcher
          onChange={this.changeCountry}
          switcherContent={switcherContent}
          name="country"
          selectedValue={this.props.country}
          title="Country"
        />

        <CheckboxFilter
          enabledArray={this.props.status}
          filterArray={['succeeded', 'attempted', 'canceled', 'mock']}
          checkedFunc={this.addStatus}
          uncheckedFunc={this.removeStatus}
          title={<Translate value="allRentals.status" />}
        />

        <CheckboxFilter
          enabledArray={this.props.rentalType}
          filterArray={['pickup', 'return']}
          checkedFunc={this.addRentalsType}
          uncheckedFunc={this.removeRentalsType}
          title={<Translate value="allRentals.type" />}
        />

        <CheckboxFilter
          enabledArray={this.props.user}
          filterArray={['anonymous', 'registered']}
          checkedFunc={this.addUser}
          uncheckedFunc={this.removeUser}
          title={<Translate value="allRentals.user" />}
        />

        <DateSwitcher
          changeDateRange={this.changeDateRange}
          dateRange={this.props.dateRange}
          title={<Translate value="allRentals.date" />}
        />

        <BananaInputButton
          value={this.props.searchLocation}
          saveFunc={this.changeSearchLocationText}
          saveButtonText={<Translate value="global.searchButtonText" />}
          text="City or PoS name"
        />
      </div>
    );
  }
}

AllRentalsFilter.propTypes = {
  search: PropTypes.string.isRequired,
  searchLocation: PropTypes.string.isRequired,
  handleChanges: PropTypes.func.isRequired,
  dateRange: PropTypes.string.isRequired,
  user: PropTypes.array.isRequired,
  status: PropTypes.array.isRequired,
  rentalType: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  activeCountries: PropTypes.array.isRequired,
};
