import React, { useEffect } from 'react';

import MainApp from 'routes/components-registration';
// @ts-ignore
import LoginContainer from 'components/containers/login-container/login-container';
import WrapperCreator from '../composite/actions-wrapper';

interface Props {
  sharedActions: {
    // stuff
    getLoginState: VoidFunction;
  };
  sharedProps: {
    // stuff
    appState: 'login' | 'main';
    error: string;
    isLoading: boolean;
  };
}

const EntryComponent: React.FC<Props> = (props) => {
  useEffect(() => {
    props.sharedActions.getLoginState();
    // auth0Lock.on('authenticated', (authResult) => {
    //   props.sharedActions.setLoginState('main', authResult);
    // });
  }, []);

  useEffect(() => {
    if (props.sharedProps.appState === 'login') {
      //
    }
  }, [props.sharedProps.appState]);

  return (
    <>
      {props.sharedProps.appState === 'login' && (
        <LoginContainer error={props.sharedProps.error} isLoading={props.sharedProps.isLoading} />
      )}
      {props.sharedProps.appState === 'main' && <MainApp />}
    </>
  );
};

export default WrapperCreator(EntryComponent, ['login', 'locale', 'error', 'loading']);
