export const LOGISTA_KEY = 'to_location_pos_number';
export const LOGISTA_VAL = 'logista_es_1';

export const Auth0Settings = {
  auth: {
    redirect: false,
    responseType: 'token',
    params: {
      scope: 'openid email offline_access', // Learn about scopes: https://auth0.com/docs/scopes
    },
  },
  autoclose: true,
  allowSignUp: false,
  allowedConnections: ['Username-Password-Authentication'],
};

export const shipmentsFilter = [
  {
    text: 'bananaFilter.delivery',
    variant: 'delivery',
  },
  {
    text: 'bananaFilter.returnType',
    variant: 'return',
  },
  {
    text: 'bananaFilter.deliveryReturn',
    variant: ' ',
  },
  {
    text: '',
    variant: '',
  },
];

export const getAdditionalFilterButtons = (createShipmentReturn, createShipmentDelivery) => [
  {
    icon: 'fa-caret-square-o-left',
    color: 'violet',
    buttonFunc: createShipmentReturn,
    title: 'Add return shipment',
  },
  {
    icon: 'fa-caret-square-o-right',
    color: 'violet',
    buttonFunc: createShipmentDelivery,
    title: 'Add delivery shipment',
  },
];

export const appSettings = {
  HIDE_TOOLTIP: 2000,
};
