import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BananaInput, BananaDatepicker } from 'banana-framework';

export default class ShipmentCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanging: false,
    };

    this.startEditing = this.startEditing.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  addModificator() {
    if (this.props.modificator) return `shipment-cell--width-${this.props.modificator}`;
    return '';
  }

  changeValue(value) {
    this.setState({ newValue: value }, () => {
      if (this.props.type === 'editableList') {
        this.props.startFunction(value);
      }
    });
  }

  transformChild() {
    if (this.props.children === null) return '';
    return this.props.children;
  }

  startEditing() {
    this.setState({ isChanging: true }, () => {
      if (this.props.startFunction) {
        this.props.startFunction();
      }
    });
  }

  finishEditing() {
    let currentOption;
    if (this.props.type === 'date') {
      this.setState({ isChanging: false }, () => {
        if (this.state.newValue) {
          this.props.changeFunction(this.props.id, this.state.newValue.format('YYYY-MM-DD'));
          this.setState({ newValue: '' });
        } else if (this.props.children && this.props.children.format) {
          this.props.changeFunction(this.props.id, this.props.children.format('YYYY-MM-DD'));
        } else if (this.props.children && Object.prototype.toString.call(this.props.children) !== '[object Object]') {
          this.props.changeFunction(this.props.id, this.props.children);
        } else {
          this.props.changeFunction(this.props.id, moment().format('YYYY-MM-DD'));
        }
      });
    } else if (this.props.type === 'editableList') {
      if (this.props.options.length) {
        this.setState({ isChanging: false }, () => {
          this.props.options.map((option) => {
            if (option.text === this.state.newValue) {
              currentOption = option.variant;
            }
            return null;
          });
          if (this.props.children !== currentOption && currentOption) {
            this.props.changeFunction(this.props.id, currentOption, this.state.newValue);
          }
        });
      }
    } else if (this.props.type === 'text') {
      // stuff...
      this.setState({ isChanging: false });
      this.props.changeFunction(this.props.id, this.state.newValue);
    }
  }

  renderTopText() {
    if (
      this.props.topText &&
      (this.props.children === null || this.props.children === 0 || this.props.children === '' || this.props.children)
    ) {
      return <div className="shipment-cell__top-text">{this.props.topText}</div>;
    }
    return null;
  }

  renderEditButton() {
    if (this.props.changeFunction && !this.state.isChanging) {
      return <i className="fa fa-pencil shipment-cell__edit-button" aria-hidden="true" onClick={this.startEditing} />;
    } else if (this.props.changeFunction && this.state.isChanging) {
      return <i className="fa fa-check shipment-cell__edit-button" aria-hidden="true" onClick={this.finishEditing} />;
    }
    return null;
  }

  renderEditBody() {
    if (this.props.changeFunction && this.state.isChanging) {
      if (this.props.type === 'editableList') {
        return (
          <div className="shipment-cell__manipulator">
            <BananaInput
              text="New location"
              editable
              options={this.props.options}
              value={this.props.children}
              onChangeAction={this.changeValue}
            />
          </div>
        );
      } else if (this.props.type === 'date') {
        let startDate;
        if (this.state.newValue) {
          startDate = moment(this.state.newValue, 'YYYY-MM-DD');
        } else if (this.props.children && this.props.children.length === 10) {
          startDate = moment(this.props.children, 'YYYY-MM-DD');
        } else {
          startDate = moment();
        }
        return (
          <div className="shipment-cell__manipulator">
            <BananaDatepicker startDate={startDate} dataChange={this.changeValue} dateFormat="YYYY-MM-DD" />
          </div>
        );
      } else if (this.props.type === 'text') {
        return (
          <div className="box-details__cable__cell">
            <BananaInput
              value={this.props.children}
              editable
              // filter="numeric"
              onChangeAction={this.changeValue}
              initialValue
            />
          </div>
        );
      }
      return null;
    }
    return null;
  }

  render() {
    return (
      <div className={`shipment-cell ${this.addModificator()} ${this.props.isLoading ? 'element--is-loading' : ''}`}>
        {this.renderTopText()}
        <div className="shipment-cell__content">
          <div className="shipment-cell__child">
            <div className="shipment-cell__child__text">{this.transformChild()}</div>
            {this.renderEditButton()}
          </div>
          {this.renderEditBody()}
        </div>
      </div>
    );
  }
}

ShipmentCell.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
  topText: PropTypes.string,
  modificator: PropTypes.string,
  changeFunction: PropTypes.func,
  startFunction: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
};

ShipmentCell.defaultProps = {
  id: null,
  children: null,
  topText: null,
  modificator: '',
  changeFunction: null,
  startFunction: null,
  type: null,
  options: null,
  isLoading: false,
};
