import React, { useState } from 'react';

import { Translate } from 'react-redux-i18n';

import { ShipmentView } from './shipment-view';

import './shipment-details.scss';
import { DirectionType, LocationType, ShipmentType, ShipmentTypeType } from 'components/containers/shipment/shipment-data';

interface Props {
  shipment: ShipmentType | null;
  id?: number;
  shipmentType: ShipmentTypeType | null;
  updateLocationFunction: (
    locationType: DirectionType,
    id: string,
    location: LocationType,
    locationName: string
  ) => Promise<boolean>;
  getAvailableLocations: (direction: DirectionType, location?: string | undefined) => void;
  updateShipmentDate: (id: string, date: string) => Promise<boolean>;
  updateExpirationDate: (id: string, date: string) => Promise<boolean>;

  availableLocationsTo: LocationType[];
  availableLocationsFrom: LocationType[];
  boxesNum: number;

  setLogisticsSlipNrBoxes?: (nrBoxesLogisticsSlip: string) => Promise<unknown>;
  setLogisticsSlipCode?: (logisticsSlipCode: string) => Promise<unknown>;
  // CSV
  getBoxesCSV?: VoidFunction;
  getBatteriesCSV?: VoidFunction;
}

export const ShipmentDetails: React.FC<Props> = (props) => {
  const [toLocationLoading, setToLocationLoading] = useState(false);
  const [fromLocationLoading, setFromLocationLoading] = useState(false);
  const [shipmentDateLoading, setShipmentDateLoading] = useState(false);
  const [expirationDateLoading, setExpirationDateLoading] = useState(false);

  const updateToLocation = (id: string, location: LocationType, locationName: string) => {
    const finish = () => setToLocationLoading(false);
    setToLocationLoading(true);
    props.updateLocationFunction('to', id, location, locationName).then(finish).catch(finish);
  };

  const updateFromLocation = (id: string, location: LocationType, locationName: string) => {
    const finish = () => setFromLocationLoading(false);
    setFromLocationLoading(true);
    props.updateLocationFunction('from', id, location, locationName).then(finish).catch(finish);
  };

  const updateShipmentDate = (id: string, date: string) => {
    const finish = () => setShipmentDateLoading(false);
    setShipmentDateLoading(true);
    props.updateShipmentDate(id, date).finally(finish).catch(finish);
  };

  const updateExpirationDate = (id: string, date: string) => {
    const finish = () => setExpirationDateLoading(false);
    setExpirationDateLoading(true);
    props.updateExpirationDate(id, date).then(finish).catch(finish);
  };

  const prepareLocations = (direction: DirectionType) => {
    let selectedLocations: LocationType[] = [];
    switch (direction) {
      case 'from':
        selectedLocations = props.availableLocationsFrom;
        break;
      case 'to':
        selectedLocations = props.availableLocationsTo;
        break;
    }

    return selectedLocations.map((location) => ({
      text: `${location.location_name}${location.pos_number ? ` | ${location.pos_number}` : ''}`,
      variant: location.id,
    }));
  };

  const prepareExpirationDate = (): JSX.Element => {
    if (props.shipment && props.shipment.shipmentSummary && props.shipment.shipmentSummary.expiration_date)
      return <>{props.shipment.shipmentSummary.expiration_date}</>;
    return <Translate value="shipmentBox.expirationDateNotSet" />;
  };

  return (
    <>
      {props.shipment && props.shipment.shipmentSummary && (
        <ShipmentView
          id={props.id}
          shipmentDateLoading={shipmentDateLoading}
          shipmentType={props.shipmentType}
          shipment={props.shipment.shipmentSummary}
          updateShipmentDate={updateShipmentDate}
          toLocationLoading={toLocationLoading}
          updateToLocation={updateToLocation}
          updateFromLocation={updateFromLocation}
          getAvailableLocations={props.getAvailableLocations}
          prepareLocations={prepareLocations}
          fromLocationLoading={fromLocationLoading}
          updateExpirationDate={updateExpirationDate}
          expirationDateLoading={expirationDateLoading}
          prepareExpirationDate={prepareExpirationDate}
          setLogisticsSlipNrBoxes={props.setLogisticsSlipNrBoxes}
          setLogisticsSlipCode={props.setLogisticsSlipCode}
          getBoxesCSV={props.getBoxesCSV}
          getBatteriesCSV={props.getBatteriesCSV}
        />
      )}
      {!props.shipment && <div>loading...</div>}
    </>
  );
};
